export default {
  delivery_type: 'takeaway',
  delivery_date: new Date().toISOString().slice(0, 10),
  delivery_time: '',
  delivery_time_start: '',
  delivery_time_end: '',
  cart_id: null,
  payment_method: 0,
  customer_address_id: null,
  customer_billing_address_id: null, // only if billing in enabled
  order_notes: '',
  customer: {
    email: '',
    name: '',
    surname: '',
    tos: false,
    privacy: false,
    mobile: '',
  },
  customer_address: {
    address: '',
    city: '',
    zipcode: '',
    floor: '',
    province: '',
  },
  isDeliveryAddressValid: true,
  isBillingAddressValid: true,
  isCustomerDataValid: true,
};
