<template>
  <div class="c-accordion">
    <slot />
  </div>
</template>

<script setup>
import { reactive, provide } from 'vue';

const accordion = reactive({
  active: false,
});

provide('accordion', accordion);

</script>

<style lang="scss" scoped>
  @use "../../../assets/styles/abstracts/mixins" as mx;
  @use "../../../assets/styles/abstracts/variables" as v;

  .c-accordion {
    border: 2px solid var(--#{ v.$variable-prefix}tertiarySoft);
    border-radius: 3px;
  }
</style>

