<template>
  <label
    class="c-checkbox"
  >
    <input
      class="c-checkbox__input"
      type="checkbox"
      v-bind="$attrs"
      @change="selectedOption"
    >
    <span class="c-checkbox__span">
      <Icon
        :data="require(`@icons/check.svg`)"
        width="10"
        color="#D80646"
        class="c-checkbox__checkmark"
      />
    </span>
    <Typography
      v-if="props.text"
      class="-foldBold h-m-0"
      v-text="props.text"
      variant="body2"
    />
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
/* eslint-disable import/first */
import { useAttrs } from 'vue';

const attrs = useAttrs();
const props = defineProps({
  text: { type: String, default: '' },
  modelValue: {
    type: [ Boolean, Number ],
    default: 0,
  },
});
const emit = defineEmits([ 'update:modelValue' ]);

const selectedOption = event => {
  emit('update:modelValue', attrs['true-value']
    ? attrs[`${event.target.checked}-value`]
    : event.target.checked);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-checkbox {
  position: relative;

  display: block;

  margin-bottom: 1rem;
  padding-left: 30px;

  cursor: pointer;
  user-select: none;

  font-size: 22px;

  &__span {
    @include mx.d-flex(center, center);

    position: absolute;
    top: 0;
    left: 0;

    width: 12px;
    height: 12px;

    border: 2px solid;
    border-color: var(--#{ v.$variable-prefix}grayWarm);

    .c-checkbox__checkmark {
      display: none;
    }
  }

  &__input {
    @include mx.hide-el-the-accessible-way();

    &:disabled ~ .c-checkbox__span,
    &:disabled ~ p {
      cursor: not-allowed;

      opacity: 0.5;
    }
  }

  &:hover .c-checkbox__input ~ .c-checkbox__span {
    border-color: var(--#{ v.$variable-prefix}muted);
  }

  .c-checkbox__input:checked ~ .c-checkbox__span {
    border-color: var(--#{ v.$variable-prefix}grayWarm);
    background-color: var(--#{ v.$variable-prefix}grayWarm);

    .c-checkbox__checkmark {
      display: block;
    }
  }
}
</style>
