export default {
  address: '',
  billing_private: 0,
  company: null,
  coord: '',
  city: '',
  country: null,
  default_billing: 0,
  default_delivery: 0,
  doorphone: null,
  floor: null,
  id: null,
  name: '',
  pec: null,
  province: '',
  sdi: null,
  selectedForBilling: false,
  selectedForDelivery: false,
  taxcode: null,
  vat: null,
  zipcode: '',
};
