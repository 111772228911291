/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import axios from 'axios';

import ApiRoutes from '@/api/routes';
import { authFactory } from '@/mixins/utils';
import { toast } from '@/toast-notification';

const token = localStorage.getItem('accessToken');
const baseConfig = {
  // withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    common: {
      'Accept-Language': 'it',
    },
    'Content-Type': 'application/json',
    Authorization: token && `Bearer ${token}`,
  },
};

class AxiosClient {
  static _instance = null

  static key = null

  constructor() {
    AxiosClient._instance = (AxiosClient.key && AxiosClient._instance) || axios.create(baseConfig);
  }

  async setSharedKey() {
    AxiosClient.key = localStorage.getItem('shared-key');

    let response = null;

    if (!AxiosClient.key) {
      try {
        response = await AxiosClient._instance.get(ApiRoutes.SHARED_KEY);
      } catch (error) {
        response = null;
      }
    }
    // AxiosClient.key = (response && response.data)
    // || 'a38780df375533e7319aeeb083c74198'; // TODO hardcoded key only for development
    if (response && response.data) {
      AxiosClient.key = response.data;
      localStorage.setItem('shared-key', AxiosClient.key);
    }

    AxiosClient._instance.defaults.headers['shared-key'] = AxiosClient.key;
  }

  get instance() {
    return AxiosClient._instance;
  }
}

export const client = new AxiosClient();

const axiosInstance = client.instance;

axiosInstance.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) return config;
  const { Authorization } = axiosInstance.defaults.headers;

  if (Authorization) {
    if (!authFactory(accessToken)) {
      // TODO: try to redirect the user first and then dispatch the toast
      toast.warning('Your session has expired, you will be redirect to the home page in 3 seconds', {
        timeout: 3000,
        onClose: () => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('vuex');
          window.location.replace(window.location.origin);
        },
      });

      return false;
    }
  }

  return config;
},
error => Promise.reject(error));

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.isAxiosError) {
      const { status, code, title } = error.response.data.errors[0];

      if (status === 500 && code === 404 && title === 'Missing Shared-Key') {
        // window.location.href = `${window.location.origin}/error.html`;
        localStorage.clear();
      }
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  },
);

export const setAxiosAcceptLang = lang => {
  axiosInstance.defaults.headers.common['Accept-Language'] = lang;
};

export default axiosInstance;
