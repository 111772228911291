const ApiRoutes = {
  HOME: '/home',
  COLORS: '/home-colors',
  USER_SETTINGS: 'setting/list',
  SHARED_KEY: '/sharedkey',

  // auth
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  SIGNUP: '/auth/signup',
  CHECK_EMAIL: '/auth/check-email',

  // user
  GET_USER: '/customer',
  UPDATE_USER: '/customer/save',
  CUSTOMER_ADDRESS: '/customer-address',
  CUSTOMER_NOTIFICATIONS: '/customer/associate-notifications',
  RECOVER_PASSWORD: '/customer/recover-password',
  RECOVER_PASSWORD_CHANGE: '/customer/recover-password-token',
  CUSTOMER_AVAILABLE_DISCOUNTS: '/customer/available-discounts',
  CUSTOMER_DISCOUNTS: '/customer/discounts',
  CUSTOMER_INVITATIONS_LIST: '/customer/invitation-list',

  // settings
  GET_TERMS_AND_POLICY: '/setting/terms-condition',
  LANGUAGES: '/setting/lang',
  COUNTRIES: '/customer-address/countries',

  // house-specialities product
  GET_HOUSE_SPECIALITIES_PRODUCTS: 'product/house-specialities',
  GET_RESTAURANT_CATEGORIES: 'category/list',

  // autocomplete address
  GET_AUTO_COMPLETE_ADDRESS: '/utility/maps/autocomplete',
  GET_ADDRESS_GEO_CODING: '/utility/maps/geocoding',

  // restaurant
  GET_RESTAURANT_LIST: '/shop/location',
  GET_DEFAULT_RESTAURANT_LIST: '/shop/list',
  GET_RESTAURANT_TIMES_LIST: '/shop/times',

  // order
  GET_ORDER: '/order',
  GET_ORDER_HISTORY: '/order/list',
  RECORD_ORDER: '/order/reorder',
  GET_ORDER_DATA: 'order/save',
  GET_TEMP_ORDER_DATA: 'order/guest-save',
  CHECK_ADDRESS: 'order/check-address',

  // cart
  ADD_TO_CART: 'cart/add-to-cart',
  ADD_TO_TEMP_CART: 'cart/add-to-temp-cart',
  GET_CART_DATA: 'cart',
  GET_TEMP_CART_DATA: 'cart/temp',
  UPDATE_CART_DATA: 'cart/update-cart',
  UPDATE_TEMP_CART_DATA: 'cart/update-temp-cart',
  REMOVE_CART_DATA: 'cart/remove-from-cart',
  REMOVE_TEMP_CART_DATA: 'cart/remove-from-temp-cart',
  APPLY_CREDIT: 'cart/apply-credit',

  // payment
  GET_PAYMENT_METHODS: '/payment-method',

  // coupon
  ADD_COUPON: '/cart/apply-discount',
  REMOVE_COUPON: '/cart/remove-discount',
  ADD_GUEST_COUPON: '/cart/apply-discount-guest',
  REMOVE_GUEST_COUPON: '/cart/remove-discount-guest',
};

export default ApiRoutes;
