<template>
  <BaseContainer
    class="l-category c-restaurant"
    bg-color="02"
  >
    <div class="c-restaurant__box">
      <div class="c-restaurant__headCaption">
        <div class="-width">
          <Typography
            variant="subtitle2"
            v-text="$t('widgets.ourRestaurants.headText')"
          />
          <Typography
            variant="body2"
            v-html="$t(`widgets.ourRestaurants.${isAddressSelected ?
              'subText' : 'addressSubText'}`)"
            class="-fontBook"
          />
        </div>
        <div
          v-if="isAddressSelected"
          class="-width"
        >
          <AddressBox
            @selected="getSelectedAddress"
            :label="$t('widgets.ourRestaurants.inputLabel')"
            label-color="secondary"
            id="restaurant-address"
            :placeholder="$t('widgets.ourRestaurants.inputPlaceholder')"
            v-model="address"
          />
          <template v-if="!isAuthenticated">
            <span
              class="-navLink"
              @click="openLoginModal"
            >{{ $t('widgets.register.signInText') }}</span>
            <span>{{ $t('widgets.ourRestaurants.inputFooter') }}</span>
          </template>
        </div>
      </div>
      <div class="h-pv-10 c-restaurant__selectedAddress">
        <Typography
          v-if="isAddressSelected"
          variant="body1"
          class="-fontMedium"
          v-text="$t('widgets.ourRestaurants.itemCaption')"
        />
        <template v-else>
          <span class="-address">
            <Icon
              :data="require('@icons/location.svg')"
              width="14"
              height="18"
            />
            <Typography
              class="h-pl-5 -fontBold"
              v-text="address"
              variant="body2"
            />
          </span>
          <BaseButton
            :btn-label="$t('widgets.ourRestaurants.btnText')"
            size="small"
            color="primary"
            @click="changeAddress"
          />
        </template>
      </div>
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        v-if="restaurantsList.length !== 0"
      >
        <Slide
          v-for="restaurant in restaurantsList"
          :key="restaurant.id"
        >
          <Card
            :images="restaurant.images"
            image-max-height="134px"
            :title="restaurant.name"
            :subtitle="restaurant.address || restaurant?.location?.address"
            bg-color="02"
            class="c-restaurant__carouselItem"
            @click="handleClick(restaurant)"
          >
            <span
              class="c-restaurant__carouselItemDistance"
              v-if="restaurant.distance"
            >
              <Icon
                :data="require('@icons/location.svg')"
                width="18"
                height="18"
              />
              <Typography
                class="h-pl-5 -fontBold"
                v-text="getDistance(restaurant.distance)"
                variant="body2"
              />
            </span>
          </Card>
        </Slide>
        <template #addons="{ slidesCount }">
          <CarouselNavigation
            class="c-restaurant__navigation"
            v-if="slidesCount > 1"
          />
        </template>
      </Carousel>
    </div>
    <BaseModal
      size="small"
      :show-modal="openModal"
    >
      <template #body>
        <ActiveAuthForm />
      </template>
    </BaseModal>
  </BaseContainer>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Carousel, Slide } from 'vue3-carousel';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import AddressBox from '@/components/common/AddressBox.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import Card from '@/components/common/Card/Card.vue';
import CarouselNavigation from '@/components/common/CarouselNavigation.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import ActiveAuthForm from '@/components/user/ActiveAuthForm.vue';
import Trans from '@/mixins/translation';

const router = useRouter();
const store = useStore();
const address = ref('');
const restaurantsList = ref([]);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

const settings = {
  itemsToShow: 1,
  snapAlign: 'center',
};
const breakpoints = {
  640: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  768: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 4,
    snapAlign: 'start',
  },
  1280: {
    itemsToShow: 5,
    snapAlign: 'start',
  },
};

apiServices.getDefaultRestaurantsList().then(res => { restaurantsList.value = res; });

const openModal = computed(() => store.state.overlays.openModal === 'LoginForm');
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const isAddressSelected = ref(true);
const getDistance = (distance = 0) => `${((distance / 1000).toFixed(2))} km`;

const changeAddress = () => {
  address.value = '';
  isAddressSelected.value = true;
};

const handleClick = async restaurant => {
  store.dispatch('restaurant/setSelectedRestaurant', restaurant)
    .then(() => {
      if (cartId.value) {
        const tempCartId = sessionStorage.getItem('temp_cart_id');

        const params = {
          delivery_type: deliveryType.value,
          shop_id: selectedRestaurantId.value,
          ...((cartId.value || tempCartId) && { cart_id: cartId.value || tempCartId }),
        };

        store.dispatch(`cart/${isAuthenticated.value ? 'updateCartItems' : 'updateTempCartItems'}`, params);
      }
      router.push(Trans.i18nRoutePath(`/dashboard/${restaurant.id}`));
    });
};

const openLoginModal = () => {
  store.dispatch('overlays/setModal', 'LoginForm');
  store.dispatch('forms/setActiveForm', 'LoginForm');
};

const getSelectedAddress = addr => {
  address.value = addr;
  store.dispatch('auth/setSelectedAddress', addr);
  isAddressSelected.value = false;
  apiServices.getAddressGeoCoding(address.value)
    .then(({ latitude, longitude }) => apiServices.getNearestRestaurants({ latitude, longitude }))
    .then(res => {
      restaurantsList.value = res;
    });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/variables" as v;
@use "src/assets/styles/abstracts/mixins" as mx;

.c-restaurant {
  padding: 40px 0 40px 0;

  &__box {
    @include mx.container-box(v.$max-width, $padding: 30px, $padding-md: 0);

    box-sizing: border-box;
  }

  &__headCaption {
    @include mx.d-flex(center, center, true);

    @include mx.bp('tablet-wide') {
      flex-direction: row;
      justify-content: space-between;
    }

    .-width {
      width: 100%;

      @include mx.bp('tablet-wide') {
        width: auto;
      }

      &:last-child {
        margin-left: 0;

        @include mx.bp('tablet-wide') {
          margin-left: 1rem;
        }

        @include mx.bp('desktop-wide') {
          margin-left: 8px;
        }
      }
    }

    .-navLink {
      @include mx.use-color('primary');

      width: 100%;
      padding-right: 4px;

      cursor: pointer;

      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &::v-deep(.c-addressBox) {
    margin-bottom: 0.5rem;

    .c-addressBox__block {
      align-items: flex-start;

      margin-top: 0.8rem;

      @include mx.bp('tablet-wide') {
        margin-top: 0;
      }
    }
  }

  &__selectedAddress {
    min-height: 40px;

    .-address {
      @include mx.d-flex(false, 'center');
      @include mx.use-color('bodyColorLt');
      @include mx.bp('desktop-wide') {
        margin-left: 8px;
      }
    }
  }

  &__carouselItem {
    width: 100%;

    cursor: pointer;
  }

  &::v-deep(.carousel__prev),
  &::v-deep(.carousel__next) {
    top: 77px; // The image height with paddings
  }

  &__carouselItemDistance {
    @include mx.d-flex($align-items: 'center');
  }
}

</style>
