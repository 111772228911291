import jwt from 'jsonwebtoken';

import { toast } from '@/toast-notification';

const defaultOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

/**
 * Get the formatted date
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param date str - The Date to be formatted.
 * @param useOptions boolean - Set it to use options
 * @param locale str - Locale value
 * @return {String} A formatted date
 */
export const dateFormat = (date, useOptions = false, locale = 'it-IT') => new Intl.DateTimeFormat(locale, useOptions ? defaultOptions : {}).format(date);

/**
 * Get the formatted number
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param number int - The value to be formatted.
 * @param locale str - Locale value
 * @return {String} A formatted date
 */
export const numberFormat = (number, locale = 'it-IT') => new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);

/**
 * Create a sentence cased sentence from a string
 *
 * @param {String} str - The String to be replaced.
 * @return {String} A new String as a sentence case
 */
export const sentenceCase = str => str.replace(/[a-z]/i, letter => letter.toUpperCase()).trim();

export const generateKey = str => str.replaceAll(' ', '-').toLowerCase();

export const convertToNumber = (value, locale = 'it-IT') => `+ ${numberFormat(Number(value || 0), locale)} €`;

export const convertTotal = (value, locale = 'it-IT') => `${numberFormat(Number(value || 0), locale)} €`;

export const handleErrors = response => {
  const { errors } = response;

  if (errors?.length) {
    const error = errors[0];

    if (error.status === 422) {
      const { email } = error?.source?.errors ?? {};

      toast.error(email ? email[0] : error.title);
    } else if (
      error.status === 500
      && error.code === 404
      && error.title === 'Missing Shared-Key'
    ) {
      toast.error('Sorry, an error occurred. Kindly refresh your browser');
    } else {
      toast.error(error?.title || 'Not Found');
    }
    // else if (error.status === 500) {
    //   toast.error('Sorry, an error occurred. Please, try again later.');
    // }
  } else {
    toast.error('Something went wrong, Please try again later');
  }
};

export const capitalizeText = text => {
  if (!text) return '';
  const trimmedText = text.trim();

  if (!trimmedText) return '';
  const words = trimmedText.split(' ');

  let resultText = '';

  words.forEach(word => {
    if (word) {
      if (resultText) resultText += ' ';
      resultText += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  return resultText;
};

/**
 * Get day difference between two dates.
 * @param {Date} a - e.g new Date('29-01-2021')
 * @param {Date} b - e.g new Date('27-01-2021')
 * @return {Number} - The day difference
 */
export const dateDiffInDays = (a, b) => {
  const perDay = 1000 * 60 * 60 * 24; // day to milliseconds

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / perDay);
};

export function authFactory(token) {
  if (!token) return false;
  const now = new Date().getTime() / 1000;
  const { exp } = jwt.decode(token);

  if (!now) return false;

  return now <= exp;
  // return (exp - now > 3585.8849999904633); // FOR TEST
}
