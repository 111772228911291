<template>
  <div :class="['c-card', `bg-${bgColor}`, `h-text${textAlign}`, rounded && classes.ROUNDED]">
    <div :class="[`-${display}`]">
      <div :class="[orderPosition]">
        <ResponsiveImage
          :images="images"
          :max-height="imageMaxHeight"
          :min-width="imageMinWidth"
          rounded
        />
      </div>
      <div>
        <small
          class="-title"
          v-if="headerText"
        >{{ headerText }}</small>
        <Typography
          class="-fontBold"
          v-text="title"
          variant="body1"
        />
        <Typography
          class="-fontMedium -muted"
          v-text="subtitle"
          variant="body2"
        />
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

import ResponsiveImage from '@/components/common/ResponsiveImage.vue';
import { ALIGNMENTS, CONTAINER, DISPLAY } from '@/constants';

const props = defineProps({
  images: {
    type: Object,
    required: true,
  },
  imageMaxHeight: {
    type: String,
    default: null,
  },
  imageMinWidth: {
    type: String,
    default: null,
  },
  badgeText: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  textAlign: {
    type: String,
    default: 'Left',
    validator: value => ALIGNMENTS.includes(value),
  },
  display: {
    type: String,
    default: 'block',
    validator: value => DISPLAY.includes(value),
  },
  imagePosition: {
    type: String,
    default: 'Right',
    validator: value => ALIGNMENTS.includes(value),
  },
  bgColor: {
    type: String,
    default: '01',
    validator: value => CONTAINER.BG_COLOR.includes(value),
  },
  rounded: {
    type: Boolean,
  },
  headerText: {
    type: String,
    default: '',
  },
});

const classes = inject('classes');

const orderPosition = `-${props.display}${props.imagePosition}`;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-card {
  width: 100%;

  @each $key, $color in map-get(v.$palettes, backgrounds) {
    &.#{ $key } {
      @include mx.use-bg-color(#{ $key });
    }
  }

  .-block {
    display: block;
  }

  .-flex {
    @include mx.d-flex("space-between");

    padding: 10px;

    &Right {
      flex: 0 0 30%;
      order: 2;
    }

    &Left {
      width: 30%;
      order: 1;
    }
  }

  &__badge {
    @include mx.use-color('lightColor');
    @include mx.use-bg-color('badge');

    position: absolute;
    top: 8px;
    right: 8px;

    padding: 10px;

    letter-spacing: 0.24px;
    text-transform: uppercase;

    border-radius: 0 4px 0 16px;

    font-weight: 700;

    ::v-deep(span) {
      font-size: 1.5em;
    }
  }

  &.-rounded {
    border-radius: v.$base-border-radius;
  }

  .-title {
    @include mx.use-color('bodyColorLtLight');
    @include mx.font-size(11px);

    margin-right: 10px;

    opacity: 0.7;
  }
}
</style>
