<template>
  <div class="c-fieldLoader">
    <div class="bounce1" />
    <div class="bounce2" />
    <div class="bounce3" />
  </div>
</template>

<script>
export default {
  name: 'FieldLoader',
};
</script>

<style lang="scss" scoped>
  @use "src/assets/styles/abstracts/mixins" as mx;
  @use "src/assets/styles/abstracts/variables" as v;

  .c-fieldLoader {
    width: 55px;

    text-align: center;

    >div {
      display: inline-block;

      width: 8px;
      height: 8px;

      animation: bounce-delay 1.4s infinite ease-in-out both;

      border-radius: 100%;
      background-color: transparentize(v.$body-color-light, 0.4);
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes bounce-delay {
    0%,
    80%,
    100% { -webkit-transform: scale(0); }
    40% { -webkit-transform: scale(1); }
  }

  @keyframes bounce-delay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
