<template>
  <footer
    class="c-footer"
  >
    <SectionContainer
      bg-color="inherit"
      size="md"
    >
      <div class="c-footer__sectionInfo">
        <div>
          <div class="-flex">
            <div class="c-footer__coLogo -pb">
              <img
                class="c-footer__logoImg"
                :src="$t('footer.logoUrl')"
                alt="logo"
              >
              <span
                class="c-footer__span"
                v-text="$t('footer.phones')"
              />
            </div>
          </div>
          <div class="-flex">
            <address class="c-footer__address -pb">
              <span
                v-html="$t('footer.address')"
              /> <br>
              <b>{{ $t('footer.email') }}</b>
            </address>
          </div>
        </div>
        <div class="c-footer__share -pb">
          <a
            v-for="({link, icon, color}) in socials"
            :key="`footer-socials-${icon}`"
            class="c-footer__socialAnchor"
            :href="link"
            target="_blank"
            :aria-label="`visit us at ${icon}`"
            rel="noreferrer"
          >
            <Icon
              :data="require(`@icons/${icon}.svg`)"
              :color="color"
              width="20"
            />
          </a>
        </div>
      </div>
      <div class="c-footer__sectionInfo -pt">
        <div class="c-footer__navLinks -flex">
          <p class="c-footer__copyright">
            {{ $t('footer.copyright') }}
          </p>

          <nav class="c-footer__nav">
            <router-link
              v-for="({link}, i) in links"
              :key="`footer-link-${i}`"
              class="c-footer__link"
              :to="Trans.i18nRoutePath(`/${link}`)"
              v-text="$t(`footer.links.${i}.title`)"
            />
          </nav>
        </div>
        <div class="c-footer__powered">
          {{ $t('footer.powered') }}
        </div>
      </div>
    </SectionContainer>
  </footer>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line import/no-cycle
import Trans from '@/mixins/translation';

const { locale, getLocaleMessage } = useI18n();
const { socials, links } = getLocaleMessage(locale.value).footer;

</script>

<style lang="scss" scoped>
  @use "src/assets/styles/abstracts/mixins" as mx;
  @use "src/assets/styles/abstracts/variables" as v;

  .c-footer {
    @include mx.use-color('bodyColorDt');
    @include mx.use-bg-color('secondary');

    padding: 30px 0 20px 0;

    &__sectionInfo {
      @include mx.d-flex(center, center, true);

      flex-wrap: wrap;

      @include mx.bp('desktop') {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
      }

      .-flex {
        @include mx.d-flex(center, center, true);

        @include mx.bp('desktop') {
          flex-flow: row;
          justify-content: flex-start;
          align-items: flex-start;
        }

        flex: 50%;
      }

      .-pb {
        padding-bottom: 1.5rem;
      }

      &.-pt {
        padding-top: 1rem;
      }

      .-pr {
        padding-right: 40px;
      }
    }

    &__coLogo {
      @include mx.d-flex(center, center, true);
      @include mx.bp('desktop') {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &__logoImg {
      width: 200px;
      height: auto;
      padding-bottom: 10px;
    }

    &__span {
      opacity: 0.7;
    }

    &__share {
      @include mx.d-flex();

      padding-top: 0.5rem;

      cursor: pointer;
      gap: 20px;

      @include mx.bp('desktop') {
        padding-top: 0;
      }
    }

    &__share,
    &__navLinks {
      order: 1;
      @include mx.bp('desktop') {
        order: unset;
      }
    }

    &__link {
      &:after {
        padding: 0 10px;

        content: '|';
      }

      &:last-child {
        &:after {
          content: '';
        }
      }
    }

    &__address {
      text-align: center;

      font-weight: 300;
      @include mx.bp('desktop') {
        text-align: left;
      }
    }

    &__powered {
      order: 2;
      @include mx.bp('desktop') {
        order: 1;
      }
    }

    &__navLinks {
      @include mx.d-flex(center, center, true);

      padding-bottom: 1.5rem;
      @include mx.bp('desktop') {
        flex-flow: row;

        padding-bottom: 0;
      }
    }

    &__copyright {
      margin: 0;
      @include mx.bp('desktop') {
        margin-right: 2.5rem;
      }
    }

    &__nav {
      @include mx.d-flex(center, center, true);

      flex-flow: wrap;

      padding-top: 20px;

      @include mx.bp('desktop') {
        flex-flow: row;

        padding: 0 15px;
      }

      a {
        @include mx.use-color('bodyColorDt');
      }
    }
  }
</style>
