import { createRouter, createWebHistory } from 'vue-router';

import AppFooter from '@/components/common/AppFooter.vue';
import AppHeader from '@/components/common/AppHeader.vue';
import ParentWrapper from '@/layouts/ParentWrapper.vue';
// eslint-disable-next-line import/no-cycle
import Trans from '@/mixins/translation';
import { authFactory } from '@/mixins/utils';
import { NAMES, PATHS } from '@/router/constants';
import Home from '@/views/Home.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard');
const DesignSystem = () => import(/* webpackChunkName: "design-system" */ '@/views/DesignSystem.vue');
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue');
const ConfirmRegister = () => import(/* webpackChunkName: "confirm-register" */ '@/views/ConfirmRegister.vue');
const ItemDetails = () => import(/* webpackChunkName: "item-details" */ '@/views/Food/ItemDetails.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue');
const ProfileAddress = () => import(/* webpackChunkName: "profile-address" */ '@/views/ProfileAddress.vue');
const ProfileNotification = () => import(/* webpackChunkName: "profile-notification" */ '@/views/ProfileNotification.vue');
const ProfileLoyalty = () => import(/* webpackChunkName: "profile-loyalty" */ '@/views/ProfileLoyalty.vue');
const ProfilePreferences = () => import(/* webpackChunkName: "profile-preferences" */ '@/views/Preferences.vue');
const ProfileInviteFriend = () => import(/* webpackChunkName: "profile-preferences" */ '@/views/ProfileInviteFriend.vue');
const OrderHistory = () => import(/* webpackChunkName: "order-history" */ '@/views/OrderHistory.vue');

const Checkout = () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue');
const Payment = () => import(/* webpackChunkName: "payment" */ '@/views/Payment.vue');
const ThankYou = () => import(/* webpackChunkName: "thank-you" */ '@/views/ThankYou.vue');
const TermsOfService = () => import(/* webpackChunkName: "terms-of-service" */ '@/views/TermsOfService.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue');

function authRedirect(_to, _from, next) {
  const token = localStorage.getItem('accessToken');

  return authFactory(token) ? next() : next(Trans.getUserSupportedLocale());
}

const routes = [
  {
    path: '/:pathMatch(.*)',
    redirect: () => Trans.availableLanguage[0] ?? Trans.defaultLocale,
  },
  {
    path: '/:locale',
    components: {
      default: ParentWrapper,
      header: AppHeader,
      footer: AppFooter,
    },
    beforeEnter: (to, from, next) => Trans.routeMiddleware(to, from, next),
    children: [
      {
        path: '',
        name: NAMES.HOME,
        components: { default: Home, header: AppHeader, footer: AppFooter },
        props: {
          header: { hasAppBanner: true },
        },
      },
      {
        path: PATHS.DASHBOARD,
        name: NAMES.DASHBOARD,
        components: {
          default: Dashboard,
          header: AppHeader,
          footer: AppFooter,
        },
      },
      {
        path: PATHS.PROFILE,
        redirect() {
          return Trans.i18nRoutePath(PATHS.PROFILE_OVERVIEW);
        },
        name: NAMES.PROFILE,
        beforeEnter: authRedirect,
        components: {
          default: ParentWrapper,
          header: AppHeader,
          footer: AppFooter,
        },
        props: {
          header: { lightOnMount: true },
        },
        children: [
          {
            path: 'overview',
            name: NAMES.PROFILE_OVERVIEW,
            component: Profile,
          },
          {
            path: 'address',
            name: NAMES.PROFILE_ADDRESS,
            component: ProfileAddress,
          },
          {
            path: 'notification',
            name: NAMES.PROFILE_NOTIFICATION,
            component: ProfileNotification,
          },
          {
            path: 'order-history',
            name: NAMES.ORDER_HISTORY,
            component: OrderHistory,
          },
          {
            path: 'loyalty',
            name: NAMES.PROFILE_LOYALTY,
            component: ProfileLoyalty,
          },
          {
            path: 'preferences',
            name: NAMES.PROFILE_PREFERENCES,
            component: ProfilePreferences,
          },
          {
            path: 'invite-a-friend',
            name: NAMES.PROFILE_INVITE_FRIEND,
            component: ProfileInviteFriend,
          },
        ],
      },
      {
        path: PATHS.RESET_PASSWORD,
        name: NAMES.RESET_PASSWORD,
        components: {
          default: ResetPassword,
          header: AppHeader,
          footer: AppFooter,
        },
      },
      {
        path: PATHS.CONFIRM_REGISTER,
        name: NAMES.CONFIRM_REGISTER,
        components: {
          default: ConfirmRegister,
          header: AppHeader,
          footer: AppFooter,
        },
      },
      {
        path: PATHS.ITEM_DETAILS,
        name: NAMES.ITEM_DETAILS,
        components: { default: ItemDetails, header: AppHeader },
      },
      {
        path: PATHS.CHECKOUT,
        name: NAMES.CHECKOUT,
        components: { default: Checkout, header: AppHeader, footer: AppFooter },
      },
      {
        path: PATHS.PAYMENT,
        name: NAMES.PAYMENT,
        components: { default: Payment, header: AppHeader, footer: AppFooter },
      },
      {
        path: PATHS.THANK_YOU,
        name: NAMES.THANK_YOU,
        components: { default: ThankYou, header: AppHeader, footer: AppFooter },
      },
      {
        path: PATHS.DESIGN_SYSTEM,
        name: NAMES.DESIGN_SYSTEM,
        components: {
          default: DesignSystem,
          header: AppHeader,
          footer: AppFooter,
        },
        props: {
          header: { lightOnMount: true },
        },
      },
      {
        path: PATHS.TERMS_OF_SERVICE,
        name: NAMES.TERMS_OF_SERVICE,
        components: {
          default: TermsOfService,
          header: AppHeader,
          footer: AppFooter,
        },
      },
      {
        path: PATHS.POLICY,
        name: NAMES.POLICY,
        components: {
          default: PrivacyPolicy,
          header: AppHeader,
          footer: AppFooter,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: '-isActive',
  linkExactActiveClass: '-isExactActive',
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return new Promise(resolve => {
        resolve({
          el: to.hash,
          top: 180,
          behavior: 'smooth',
        });
      });
    }

    return savedPosition ?? { top: 0 };
  },
  routes,
});

export default router;
