<template>
  <div
    :class="['c-recentOrder', props.homePage && '-home']"
    v-if="orderHistory.length && isAuthenticated"
  >
    <div
      class="c-recentOrder__text"
    >
      <Typography
        :is-dark-theme="!props.homePage"
        v-text="$t('widgets.dashboard.banner.subTextLeft')"
        :variant="isMobile ? 'body1':'h3'"
        class="-fontBook"
      />
      <BaseButton
        :to="$i18nRoute({name: ORDER_HISTORY})"
        :btn-label="$t('widgets.dashboard.banner.subTextRight')"
        :color="!props.homePage ? 'lightColor' : 'secondary'"
      />
    </div>
    <div
      class="c-recentOrder__slide"
    >
      <CardOrder
        v-for="order in orderHistory"
        :key="order.id"
        :date="new Date(order.utcDate)"
        :price="convertTotal(order.total, locale)"
        :id="order.id"
        :products="order.products"
        @showDetails="handleModal(order)"
        :home-page="props.homePage"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import CardOrder from '@/components/common/Card/CardOrder.vue';
import Typography from '@/components/common/Typography.vue';
import { convertTotal } from '@/mixins/utils';

const { ORDER_HISTORY } = inject('routeNames');
const isMobile = inject('isMobile');

const store = useStore();
const { locale } = useI18n();
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const orderHistory = computed(() => store.state.order.orderHistory);

const props = defineProps({
  homePage: { type: Boolean },
});

const emit = defineEmits([ 'update:selectedOrder' ]);

const handleModal = val => {
  emit('update:selectedOrder', val);
  store.dispatch('overlays/setModal', 'OrderDetailsModal');
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.c-recentOrder {
  &__text {
    @include mx.d-flex("space-between", "center");

    flex-wrap: wrap;
  }

  &__slide {
    display: grid;
    overflow: scroll;
    grid-template-columns: repeat(3, minmax(260px, 1fr));
    grid-gap: 16px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &.-home {
    padding: 40px 0 40px 0;
  }
}
</style>
