<template>
  <div class="c-accordionItem">
    <div
      class="c-accordionItem__trigger"
    >
      <div
        :class="['c-accordionItem__header', `-${headerDisplay}`]"
        @click="open"
      >
        <Icon
          class="c-accordionItem__icon"
          :data="require(`@icons/${icon}.svg`)"
          :width="iconWidth"
          height="auto"
          :color="iconColor"
        />
        <Typography
          class="-fontBold"
          v-text="title"
          variant="body1"
        />
      </div>
      <div>
        <BaseButton
          v-if="!keepOpen && accordion.active"
          icon="close"
          icon-color="muted"
          @click="close"
        />
      </div>
    </div>

    <transition
      name="accordion"
    >
      <div
        class="c-accordionItem__content"
        v-if="accordion.active"
      >
        <slot name="accordion-content" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { inject, onMounted, watchEffect } from 'vue';

import BaseButton from '@/components/common/BaseButton.vue';
import { BUTTONS, DISPLAY } from '@/constants';

const props = defineProps({
  headerDisplay: {
    type: String,
    default: 'flex',
    validator: value => DISPLAY.includes(value),
  },
  icon: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: 'title',
  },
  iconColor: {
    type: String,
    default: 'secondary',
    validator: value => BUTTONS.COLORS.includes(value),
  },
  iconWidth: {
    type: String,
    default: '30',
  },
  keepOpen: {
    type: Boolean,
  },
});

const accordion = inject('accordion');

onMounted(() => {
  accordion.active = props.keepOpen;
});
watchEffect(() => {
  accordion.active = props.keepOpen;
});

function open() {
  accordion.active = true;
}

function close() {
  accordion.active = false;
}

const iconColor = `var(--st-${props.iconColor})`;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-accordionItem {
  padding: 1.5rem;

  &__icon {
    padding-right: 1.5rem;
  }

  &__header {
    width: 100%;

    &.-flex {
      @include mx.d-flex(flex-start, center, false);
    }

    &.-block {
      display: block;
    }
  }

  &__trigger {
    @include mx.d-flex(center, center, false);

    cursor: pointer;
  }

  &__content {
    padding-top: 1.5rem;
  }

  .accordion {
    &-enter-active,
    &-leave-active {
      transition: height 0.3s ease, opacity 0.3s ease;
    }

    &-enter-from,
    &-leave-to {
      height: 0;

      opacity: 0;
    }
  }
}

</style>
