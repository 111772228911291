import { createI18n } from 'vue-i18n';

export const defaultLanguage = 'en';

const i18n = createI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
});

export default i18n;
