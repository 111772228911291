import { OVERLAYS as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    isSideMenuOpen: false,
    openModal: '',
  },
  mutations: {
    [M.SET_MODAL](state, value) {
      state.openModal = value;
    },
    [M.SET_SIDE_MENU_VISIBILITY](state, value) {
      state.isSideMenuOpen = value;
    },
  },
  actions: {
    setModal({ commit }, payload) {
      commit(M.SET_MODAL, payload);
    },
    setSideMenuVisibility({ commit }, payload) {
      commit(M.SET_SIDE_MENU_VISIBILITY, payload);
    },
  },
};
