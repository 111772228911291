<template>
  <component
    :is="is"
    :class="[className, isDarkTheme && classes.DARK_THEME ]"
  />
</template>

<script setup>
import { inject } from 'vue';

import { TYPOGRAPHY } from '@/constants';

const classes = inject('classes');
/* NOTE: cannot use injected inside validators */
// const typography = inject('typography');
const props = defineProps({
  component: {
    type: String,
    default: undefined,
    validator: value => TYPOGRAPHY.COMPONENTS.includes(value),
  },
  variant: {
    type: String,
    required: true,
    validator: value => TYPOGRAPHY.VARIANTS.includes(value),
  },
  isDarkTheme: {
    type: Boolean,
  },
});

const setComponent = val => {
  switch (val) {
    case 'h1':
    case 'h2':
    case 'h3':
      return props.component || props.variant;
    case 'body1':
    case 'body2':
      return 'p';
    case 'span':
      return 'span';
    case 'small':
      return 'small';
    default:
      return 'div';
  }
};
const setComponentClassName = () => ([ 'span', 'small' ].includes(props.component)
  ? undefined : `text-${props.variant}`);
const setVariantClassName = () => ([ 'h1', 'h2', 'h3', 'span', 'small' ].includes(props.variant)
  ? undefined : props.variant);

const is = setComponent(props.component || props.variant);
const className = [ 'h1', 'h2', 'h3', 'span', 'small' ].includes(props.component)
  ? setComponentClassName() : setVariantClassName();

</script>
