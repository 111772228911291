<template>
  <div class="c-order">
    <Tabs
      group-name="order-meal-group"
      :initial-tab="deliveryType"
      @change="handleSelectedTab"
    >
      <Tab
        :name="$t('widgets.orderModal.delivery.text')"
        v-if="defaultDeliveryType"
      >
        <Delivery />
      </Tab>
      <Tab
        :name="$t('widgets.orderModal.takeAway.text')"
        v-if="defaultTakeawayType"
      >
        <TakeAway />
      </Tab>
      <Tab
        :name="$t('widgets.orderModal.catering.text')"
        v-if="defaultCateringType"
      >
        <Catering />
      </Tab>
    </Tabs>
  </div>
</template>

<script setup>
import {
  ref, computed, onMounted, onUnmounted, provide,
} from 'vue';
import { useStore } from 'vuex';

import Catering from '@/components/Order/Catering.vue';
import Delivery from '@/components/Order/Delivery.vue';
import TakeAway from '@/components/Order/TakeAway.vue';
import Tab from '@/components/common/Tabs/Tab.vue';
import Tabs from '@/components/common/Tabs/Tabs.vue';
import { SelectedRestaurant as defaultSelectedRestaurant } from '@/interface/model';
import { ORDER } from '@/store/types';

const props = defineProps({
  isConfirm: {
    type: Boolean,
  },
});

const store = useStore();
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const selectedAddress = computed(() => store.state.auth.selectedAddress);
const restaurantList = computed(() => store.state.order.restaurantList);
const defaultDeliveryType = computed(() => store.getters['reusable/getSetting']('delivery_type_delivery'));
const defaultTakeawayType = computed(() => store.getters['reusable/getSetting']('delivery_type_takeaway'));
const defaultCateringType = computed(() => store.getters['reusable/getSetting']('delivery_type_shop'));
const selectedRestaurant = computed(
  () => store.state.restaurant.selectedRestaurant,
);

const address = ref(selectedAddress.value);
const loadingList = ref(false);

const getAddressGeoCoding = async () => {
  const { longitude, latitude } = await store.dispatch(
    'autoCompleteAddress/getAddressGeoCoding',
    selectedAddress.value || address.value,
  );

  loadingList.value = true;
  const response = await store.dispatch('restaurant/getRestaurantList', {
    latitude,
    longitude,
  });

  loadingList.value = false;
  store.commit(`order/${ORDER.SET_RESTAURANT_LIST}`, response);
};

// new address and fetch restaurant list
const getSelectedAddress = async addr => {
  address.value = addr;

  await store.dispatch('auth/setSelectedAddress', addr);
  await getAddressGeoCoding();
};

const handleSelectedTab = evt => {
  store.dispatch('order/setOrderData', {
    delivery_type: evt.target.value,
    delivery_date: new Date().toISOString().slice(0, 10),
    delivery_time: '',
  });
};

const getSelectedRestaurant = event => {
  const restaurant = JSON.parse(event.target.value);

  store.dispatch('restaurant/setSelectedRestaurant', restaurant);
  if (deliveryType.value !== 'catering') {
    store.dispatch('restaurant/getRestaurantTimesList', {
      id: restaurant.id,
      params: {
        date: deliveryDate.value,
        type: deliveryType.value,
      },
    });
  }
};

const checkSelectedRestaurant = (resList = []) => {
  const findRest = resList.find(cat => cat.id === selectedRestaurant.value.id);

  if (!findRest) {
    store.dispatch('restaurant/setSelectedRestaurant', defaultSelectedRestaurant);
  }

  return resList;
};

const deliveryList = computed(() => {
  const list = restaurantList.value.filter(d => d.delivery === 1);

  return checkSelectedRestaurant(list);
});
const takeawayList = computed(() => {
  const list = restaurantList.value.filter(d => d.takeaway === 1);

  return checkSelectedRestaurant(list);
});
const cateringList = computed(() => {
  const list = restaurantList.value.filter(d => d.catering === 1);

  return checkSelectedRestaurant(list);
});

provide('address', address);
provide('loadingList', loadingList);
provide('deliveryList', deliveryList);
provide('takeawayList', takeawayList);
provide('cateringList', cateringList);
provide('getSelectedAddress', getSelectedAddress);
provide('selectedRestaurant', selectedRestaurant);
provide('getSelectedRestaurant', getSelectedRestaurant);

onMounted(() => {
  if (selectedAddress.value) {
    getAddressGeoCoding();
  }
});

onUnmounted(() => {
  if (!props.isConfirm) {
    store.dispatch('auth/setSelectedAddress', selectedAddress.value);
    store.dispatch(
      'restaurant/setSelectedRestaurant',
      selectedRestaurant.value,
    );
  } else {
    store.dispatch('auth/setSelectedAddress', address.value);
  }
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-order {
  $self: &;

  width: 100%;
  height: 100%;

  &::v-deep(.-title) {
    margin-top: 1.5rem;
    margin-bottom: 0;

    letter-spacing: 0;
  }

  &::v-deep(.-subText) {
    @include mx.use-color("bodyColorLtLight");
    @include mx.font-size(12px);

    letter-spacing: 0.24px;

    opacity: 0.7;
  }

  &::v-deep(.c-addressBox__inputContainer) {
    width: 100%;

    .c-addressBox__icon {
      height: 16px;
    }
  }
}
</style>
