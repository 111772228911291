import { useToast } from 'vue-toastification';

const toastConfig = {
  options: {
    // toast options
    position: 'top-center',
    timeout: 3000,
    pauseOnHover: true,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    icon: true,
    draggable: false,
  },
  toast: useToast(),
};

export const { options, toast } = toastConfig;

export default toastConfig;
