import ApiRoutes from '@/api/routes';
import { SelectedRestaurant } from '@/interface/model';
import axios from '@/mixins/axios';
import { handleErrors } from '@/mixins/utils';
import { RESTAURANT as M } from '@/store/types';
import { toast } from '@/toast-notification';

export default {
  namespaced: true,
  state: {
    selectedRestaurant: SelectedRestaurant,
    restaurantsList: [],
    restaurantTimes: [],
  },
  mutations: {
    [M.SET_SELECTED_RESTAURANT](state, payload) {
      state.selectedRestaurant = payload;
    },
    [M.SET_RESTAURANT_LIST](state, payload) {
      state.restaurantsList = payload;
    },
    [M.SET_RESTAURANT_TIMES](state, payload) {
      // BE sometimes returns an invalid object data. This checks if payload is an array
      if (Array.isArray(payload)) state.restaurantTimes = payload;
    },
  },
  actions: {
    setSelectedRestaurant({ commit }, payload) {
      commit(M.SET_SELECTED_RESTAURANT, payload);
    },
    async getRestaurantList(_, params) {
      try {
        const { data: { data } = [] } = await axios.post(ApiRoutes.GET_RESTAURANT_LIST,
          params);

        return data;
      } catch (error) {
        handleErrors(error);
      }

      return [];
    },
    async getRestaurantTimesList({ commit }, params) {
      this.dispatch('loaders/setLoader', true);
      try {
        const { data: { data } } = await
        axios.post(`${ApiRoutes.GET_RESTAURANT_TIMES_LIST}/${params.id}`, params.params);

        commit(M.SET_RESTAURANT_TIMES, data.result);
        if (!data.result.length) toast.warning(data.message);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
};
