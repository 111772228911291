export default
{
  active: 0,
  address: '',
  catering: 0,
  city: '',
  coord: '',
  delivery: 0,
  distance: '',
  id: null,
  images: [],
  latitude: '',
  longitude: '',
  name: '',
  phone: '',
  province: '',
  slug: '',
  takeaway: 0,
  times: [],
  zipcode: '',
};

