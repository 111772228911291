/* eslint-disable camelcase */
export default {
  cart_id: null,
  credit: 0,
  credit2: 0,
  customerAddress: null,
  delivery_cost: 0,
  delivery_datetime: null,
  delivery_type: null,
  discounts: [],
  has_loyalty: false,
  products: [],
  service_cost: 0,
  shop_id: null,
  specialDiscount: {
    id: null,
    type: '',
    value: '',
    category: '',
    product_id: null,
    order_min: null,
    free_delivery: null,
    points: null,
    text: '',
    discount_amount: '',
  },
  subTotal: '',
  subTotalNoDiscount: '',
  suggested: [],
};
