<template>
  <Typography
    v-text="$t('widgets.thankYou.order.summary')"
    variant="body1"
    :class="summaryClass"
  />
  <div
    v-for="order in orderDetails.products"
    :key="order.id"
  >
    <div class="-flexBox">
      <Typography
        v-text="order.quantity > 1 ? `X ${order.quantity} ${order.name}` : order.name"
        variant="body2"
        class="-fontBold -flexBoxText"
      />
      <Typography
        v-text="convertTotal(order.price * order.quantity, locale)"
        variant="body2"
        class="-muted -fontBold"
      />
    </div>
    <Typography
      v-text="ingredients(order.variants)"
      variant="body2"
      class="-muted"
    />
  </div>
  <div class="divider h-mv-10" />
  <div class="-flexBox">
    <Typography
      v-text="$t('widgets.orderHistory.details.charges.deliveryText')"
      variant="body2"
      class="-muted -flexBoxText"
    />
    <Typography
      v-text="convertTotal(orderDetails.delivery_price, locale)"
      variant="body2"
      class="-muted -fontBold"
    />
  </div>
  <div class="-flexBox">
    <Typography
      v-text="$t('widgets.orderHistory.details.charges.servicesText')"
      variant="body2"
      class="-muted -flexBoxText"
    />
    <Typography
      v-text="convertTotal(orderDetails.service_cost, locale)"
      variant="body2"
      class="-muted -fontBold"
    />
  </div>
  <div
    class="h-mt-20"
    v-if="orderDetails.discounts && orderDetails.discounts.length"
  >
    <Typography
      v-text="$t('widgets.orderHistory.details.discounts')"
      variant="body1"
      class="-fontBold"
    />

    <div
      v-for="(discount, index) in orderDetails.discounts"
      :key="index"
      class="-flexBox h-mt-5"
    >
      <div class="-flexBoxText">
        <Typography
          v-text="discount.name"
          variant="body2"
          class="-muted -fontBold"
        />
        <Typography
          v-if="discount.type === 'percent'"
          v-text="`${Number(discount.value)}% (${convertTotal(discount.total_before, locale)})`"
          variant="body2"
          class="-muted"
        />
      </div>
      <Typography
        v-text="convertTotal(discount.price, locale)"
        variant="body2"
        class="-muted -fontBold"
      />
    </div>
  </div>
  <div class="divider h-mv-10" />
  <div class="-flexBox">
    <div class="-flexBoxText">
      <Typography
        v-text="$t('widgets.thankYou.order.total')"
        variant="body1"
        class="-fontBold h-uppercase"
      />
      <Typography
        v-text="props.orderDetails.payment_name"
        variant="body2"
        class="-muted"
      />
    </div>
    <Typography
      v-text="convertTotal(orderDetails.total, locale)"
      variant="subtitle1"
      class="-fontBold"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import { convertTotal } from '@/mixins/utils';

const { locale } = useI18n();
const props = defineProps({
  orderDetails: {
    type: Object,
    required: true,
  },
  summaryClass: {
    type: String,
    default: '-fontBold',
  },
});

const ingredients = arr => arr
  .reduce((a, b, index) => `${a}${index ? ', ' : ''}${Number(b.qty) > 1 ? `${Number(b.qty)}x ` : ''}${b.name}`, '');

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.-flexBox {
  @include mx.d-flex(space-between);

  &Text {
    @include mx.bp('tablet-wide') {
      width: 70%;
    }
  }
}

.divider {
  @include mx.use-bg-color("disabled");

  height: 1px;
}
</style>
