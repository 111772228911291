<template>
  <component
    :is="$attrs.href ? 'a' : $attrs.to ? 'router-link' : 'button'"
    :class="[mainClass, isFullWidth && '-block', {'-isIcon': isOnlyIcon},
             {'-hasIcon': hasIconClass}, {'-hasRightLabel': labelRight},
             {'-hasLoader' : displayLoader}]"
    v-bind="$attrs"
    :type="$attrs.href || $attrs.to ? null : $attrs.type || 'button'"
    :disabled="disabled"
  >
    <Icon
      :data="require(`@icons/${icon}.svg`)"
      v-if="icon"
      :color="iconColor"
      :original="original"
      :width="iconWidth"
      height="auto"
    />
    <slot
      v-if="$slots['html-label']"
      name="html-label"
    />
    <template v-else>
      <span
        v-if="btnLabel"
      >
        {{ btnLabel }}
      </span>
      <span
        v-if="labelRight"
      >
        {{ labelRight }}
      </span>
    </template>
    <Loader v-if="displayLoader" />
  </component>
</template>

<script setup>
import { computed } from 'vue';

import Loader from '@/components/common/Loader/Loader.vue';
import { BUTTONS } from '@/constants';

const props = defineProps({
  btnLabel: { type: String, default: '' },
  isFullWidth: { type: Boolean },
  labelRight: { type: String, default: '' },
  displayLoader: { type: Boolean },
  disabled: { type: Boolean },
  icon: { type: String, default: '' },
  iconWidth: { type: String, default: '20' },
  iconColor: {
    type: String,
    default: '',
    validator: value => BUTTONS.COLORS.includes(value) || !value,
  },
  variant: {
    type: String,
    default: 'default',
    validator: value => BUTTONS.VARIANT.includes(value),
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => BUTTONS.SIZES.includes(value),
  },
  alignStart: {
    type: Boolean,
  },
  color: {
    type: String,
    default: 'primary',
    validator: value => BUTTONS.COLORS.includes(value),
  },
  original: {
    type: Boolean,
    default: false,
  },
});

const isOnlyIcon = computed(() => !props.btnLabel && !props.labelRight);
const iconColor = computed(() => (props.original ? '' : `var(--st-${props.iconColor ?? props.color})`));
const hasIconClass = computed(() => props.icon && !isOnlyIcon.value);
const isCustom = computed(() => props.variant === 'custom');
const customClass = computed(() => (isCustom.value ? '' : ` -${props.color} -${props.size}`));
const mainClass = computed(() => `c-baseButton -${props.variant}${customClass.value}`);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

$buttons: (
  12px null default uppercase,
  12px 15px solid inherit,
  12px 15px outlined inherit,
  12px 15px custom inherit,
);
$colors: (
  'bodyColorDt': ('primary', 'secondary', 'tertiary', 'facebook', 'email', 'muted', 'lightColor'),
  'bodyColorLt': ('google', 'apple'),
);

@mixin colorScheme($bgColor, $color, $useBorder: null) {
  @include mx.use-bg-color($bgColor);
  @include mx.use-color($color);
  @if $useBorder {
    border-color:
      if(
        $bgColor == transparent,
        transparent,
        var(--#{ v.$variable-prefix}#{$bgColor})
      );
  }
}

.c-baseButton {
  @include mx.d-flex(center, center, false);

  width: max-content;
  padding: 10px 24px;

  cursor: pointer;
  letter-spacing: 0.3px;

  border: 2px solid;
  border-radius: v.$base-border-radius;

  font-weight: 500;

  &.-hasRightLabel {
    justify-content: space-between;

    width: 100%;
  }

  &.-small {
    padding: 7px 15px;
  }

  &.-large {
    padding: 14px 24px;
  }

  &.-isIcon {
    padding-right: 8px;
    padding-left: 8px;
  }

  &.-block {
    width: 100%;
  }

  &.-hasIcon {
    &:not(.-solid):not(.-outlined) {
      padding-right: 6px;
      padding-left: 0;
    }

    > svg {
      margin-right: 14px;
    }
  }

  @include mx.font-size(12px, 15px);

  &.-default {
    @include mx.font-size(12px);
  }

  // Custom Button
  &.-custom {
    @include colorScheme('lightColor', 'bodyColorLtLight');

    padding: 8px 22px;

    border-color: var(--#{ v.$variable-prefix}lightColor);

    &.-isActive {
      @include colorScheme('tertiary', 'bodyColorDt');

      border-color: var(--#{ v.$variable-prefix}tertiary);
    }

    &:disabled,
    .-hasLoader {
      @include colorScheme('grayWarm', 'bodyColorDt');

      cursor: not-allowed;

      border-color: var(--#{ v.$variable-prefix}grayWarm);
    }
  }
  // Handle Variants
  @each $smF, $lgF, $variant, $textCase in $buttons {
    &.-#{ $variant } {
      text-transform: $textCase;
      @if $variant != 'custom' {
        // Color Map
        @each $color, $map in $colors {
          @each $key in $map {
            &.-#{$key} {
              @if $variant == 'solid' {
                @include colorScheme($key, $color, true);

                &:disabled,
                &.-hasLoader {
                  @include colorScheme('grayWarm', 'bodyColorDt', true);

                  cursor: not-allowed;

                  border-color: var(--#{ v.$variable-prefix}grayWarm);
                }
              }
              @else if $variant == 'outlined' {
                // Tertiary Button
                @if $key == 'tertiary' {
                  @include colorScheme(transparent, 'bodyColorLtLight', 'tertiarySoft');
                  @include mx.font-size(12px);

                  text-transform: uppercase;

                  border-color: var(--#{ v.$variable-prefix}tertiarySoft);
                }
                @else {
                  @include colorScheme(transparent, $key);

                  border-color: var(--#{ v.$variable-prefix}#{$key});
                }

                &:disabled,
                &.-hasLoader {
                  @include colorScheme(transparent, 'grayWarm');

                  cursor: not-allowed;

                  border-color: var(--#{ v.$variable-prefix}grayWarm);
                }
              }
              @else {
                @include colorScheme(transparent, $key, true);

                &:disabled,
                &.-hasLoader {
                  @include colorScheme(transparent, 'grayWarm');

                  cursor: not-allowed;

                  border-color: var(--#{ v.$variable-prefix}grayWarm);
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
