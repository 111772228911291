import ApiRoutes from '@/api/routes';
import axios from '@/mixins/axios';
import { handleErrors } from '@/mixins/utils';
import { PAYMENT as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    paymentMethods: [],
  },
  mutations: {
    [M.SET_PAYMENT_METHODS](state, payload) {
      state.paymentMethods = payload;
    },
  },
  actions: {
    async getPaymentMethods({ commit }, type) {
      this.dispatch('loaders/setLoader', true);
      try {
        const { data: { data } = [] } = await
        axios.get(`${ApiRoutes.GET_PAYMENT_METHODS}?type=${type.toLowerCase() || ''}`);

        commit(M.SET_PAYMENT_METHODS, data);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
};
