<template>
  <div v-if="selectedRestaurantId">
    <Typography
      class="-fontBold -title"
      v-text="$t('widgets.orderModal.delivery.timeText')"
      variant="body1"
    />
    <div
      class="c-delivery__selectSection h-mt-10"
    >
      <input
        class="c-delivery__selectSectionDate"
        type="date"
        :value="deliveryDate"
        @change="handleDateChange"
        :min="today"
        :max="tomorrow"
      >
      <BaseSelect
        v-if="!!restaurantTimeOptions.length"
        :options="restaurantTimeOptions"
        :value="deliveryTime"
        name="deliveryTime"
        :placeholder-text="$t('common.select.timePlaceHolderText')"
        @change="handleTimeChange"
      />
      <Typography
        v-else
        v-text="$t('common.select.changeDate')"
        variant="body1"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import BaseSelect from '@/components/common/BaseSelect.vue';

const store = useStore();

const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);
const today = ref(new Date().toISOString().slice(0, 10));
const setTomorrow = new Date().setDate(new Date().getDate() + 30);
const tomorrow = ref(new Date(setTomorrow).toISOString().slice(0, 10));

const restaurantTimeOptions = computed(
  () => store.state.restaurant.restaurantTimes.map(d => ({
    label: d,
    value: d,
    selected: store.state.order.orderData.delivery_time === d,
  })),
);

const handleDateChange = evt => {
  store.dispatch('order/setOrderData', { delivery_date: evt.target.value, delivery_time: '' });
  store.dispatch('restaurant/getRestaurantTimesList',
    {
      id: selectedRestaurantId.value,
      params: {
        date: deliveryDate.value,
        type: deliveryType.value,
      },
    });
};

const handleTimeChange = evt => {
  store.dispatch('order/setOrderData', { delivery_time: evt.target.value });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-delivery {
  &__selectSection {
    @include mx.d-flex(flex-start, stretch, true);

    gap: 10px;

    @include mx.bp('tablet-wide') {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &::v-deep(.c-baseSelect) {
      flex: 0 0 49%;
    }

    &Date {
      @include mx.use-color('bodyColorLt');

      flex: 0 0 49%;

      background-color: transparentize(v.$body-color-light, 0.85);
    }
  }
}
</style>
