<template>
  <div class="c-forgotPassword">
    <FormContainer
      :form-title="$t('widgets.forgotPassword.formTitle')"
      :src="$t('widgets.login.logoUrl')"
    >
      <Accordion>
        <AccordionItem
          :title="$t('widgets.forgotPassword.accordionTitle')"
          icon="email-solid"
          keep-open
        >
          <template #accordion-content>
            <form
              @submit.prevent="store.dispatch('auth/recoverPassword', emailId)"
              @invalid.capture.prevent="$refs.inputEmailEl.validateForm"
            >
              <BaseTextBox
                :label="$t('widgets.forgotPassword.field.emailId.label')"
                id="email-id"
                name="email"
                type="email"
                v-model="emailId"
                ref="inputEmailEl"
                required
                :placeholder="$t('widgets.forgotPassword.field.emailId.placeholder')"
              />
              <BaseButton
                size="small"
                variant="solid"
                color="primary"
                type="submit"
                :disabled="!emailId"
                :btn-label="$t('widgets.forgotPassword.resetPasswordBtnText')"
              />
            </form>
            <div class="c-forgotPassword__link">
              <BaseButton
                :btn-label="$t('widgets.forgotPassword.backLinkText')"
                @click="store.dispatch('forms/setActiveForm', 'LoginForm')"
              />
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </FormContainer>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import FormContainer from '@/components/common/FormContainer.vue';

const emailId = ref('');
const store = useStore();

</script>

<style lang="scss" scoped>
  @use "src/assets/styles/abstracts/mixins" as mx;
  @use "src/assets/styles/abstracts/variables" as v;

  .c-forgotPassword {
    width: 100%;

    &__link {
      width: 100%;

      text-align: center;
    }
  }
</style>
