<template>
  <transition
    name="modal-fade"
  >
    <div
      class="c-modal"
      v-if="showModal"
    >
      <div
        class="c-modal__overlay"
        @click="dispatch('overlays/setModal', '')"
      />
      <div
        :class="['c-modal__content', `-${size}`]"
      >
        <div class="c-modal__header">
          <Icon
            :data="require('@icons/close.svg')"
            @click="dispatch('overlays/setModal', '')"
          />
        </div>
        <div
          class="c-modal__body"
          v-if="!!$slots.body"
        >
          <slot name="body" />
        </div>
        <div
          class="c-modal__footer"
          v-if="!!$slots.footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { watch, inject } from 'vue';
import { useStore } from 'vuex';

import { MODAL } from '@/constants';

const { LOCK_SCROLL } = inject('classes');
const { dispatch } = useStore();
const props = defineProps({
  size: {
    type: String,
    default: 'small',
    validator: value => MODAL.SIZES.includes(value),
  },
  showModal: { type: Boolean },
});

watch(() => props.showModal,
  currState => {
    document.body.classList.toggle(LOCK_SCROLL, currState);
  });

</script>

<style lang="scss">
  .-noScroll {
    overflow-y: hidden;

    height: 100vh;
  }
</style>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-modal {
  @include mx.d-flex(center, center, true);
  @include mx.use-bg-color('lightColor');
  @include mx.use-zeta-index('modal');

  position: fixed;
  top: 0;
  left: 0;

  overflow: auto;

  width: 100%;
  height: 100%;

  transition: opacity 0.3s ease;

  @include mx.bp('tablet') {
    background-color: transparentize(v.$body-color, 0.5);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__closeIcon {
    cursor: pointer;
  }

  &__content {
    @include mx.use-bg-color('lightColor');

    position: relative;

    overflow: auto;

    width: 100%;
    height: 100%;
    margin: 0 auto;

    transition: opacity 0.3s ease, max-width 0.3s ease;

    border-radius: 0;
    box-shadow: 0 3px 30px #0000001C;
    will-change: max-width;

    @include mx.bp('tablet-wide') {
      height: auto;

      border-radius: 6px;

      &.-small,
      &.-medium,
      &.-large {
        max-width: 550px;
      }
    }

    @include mx.bp('desktop') {
      &.-small {
        max-width: 550px;
      }

      &.-medium {
        max-width: 810px;
      }

      &.-large {
        max-width: 964px;
      }
    }
  }

  &__body {
    overflow-y: auto;

    box-sizing: border-box;
    padding: 18px 16px;

    @include mx.bp('tablet-wide') {
      max-height: 600px;
    }
  }

  &__header {
    padding: 10px;

    text-align: right;

    &::v-deep(.svg-icon) {
      padding: 4px;

      cursor: pointer;
    }
  }

  &__footer {
    overflow: hidden;

    padding: 15px 16px;

    border-radius: 0 0 6px 6px;
  }
}

.modal-fade {
  &-enter-active,
  &-leave-active {
    transition:
      opacity 0.3s cubic-bezier(1, 0.5, 0.8, 1),
      transform 0.3s ease-in-out;
  }

  &-enter-from,
  &-leave-to {
    transform: translateY(-30px);

    opacity: 0;
  }
}

</style>
