import { LOADERS as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    showLoader: false,
    showBtnLoader: false,
  },
  mutations: {
    [M.SET_LOADER](state, value) {
      state.showLoader = value;
    },
    [M.SET_BUTTON_LOADER](state, value) {
      state.showBtnLoader = value;
    },
  },
  actions: {
    setLoader({ commit }, payload) {
      commit(M.SET_LOADER, payload);
    },
    setBtnLoader({ commit }, payload) {
      commit(M.SET_BUTTON_LOADER, payload);
    },
  },
};
