<template>
  <div class="c-loginForm">
    <FormContainer
      :form-title="$t('widgets.login.formTitle')"
      :src="$t('widgets.login.logoUrl')"
    >
      <Accordion>
        <AccordionItem
          :title="$t('widgets.login.accordionTitle')"
          icon="user"
          keep-open
        >
          <template #accordion-content>
            <form
              @submit.prevent="handleSubmit"
              @invalid.capture.prevent="validate"
            >
              <BaseTextBox
                v-for="({label, id, name, type, required, placeholder}, index) in fields"
                :key="label"
                :label="$t(label)"
                :id="id"
                :name="name"
                :type="type"
                v-model="inputParams[index]"
                :ref="el => { inputEl[id] = el }"
                :required="required"
                :placeholder="$t(placeholder)"
              />
              <div class="c-loginForm__btnContainer">
                <BaseButton
                  class="c-loginForm__actionBtn"
                  variant="solid"
                  color="primary"
                  :btn-label="$t('widgets.login.navigateBackText')"
                  @click.prevent="store.dispatch('forms/setActiveForm', 'LoginPicker')"
                />
                <BaseButton
                  variant="outlined"
                  class="c-loginForm__actionBtn"
                  type="submit"
                  :display-loader="showBtnLoader"
                  :disabled="!inputParams[0] || !inputParams[1] || showBtnLoader"
                  :btn-label="$t('widgets.login.loginBtnText')"
                />
              </div>
            </form>
            <div class="c-loginForm__link">
              <a
                class="-navLink"
                @click="store.dispatch('forms/setActiveForm', 'ForgotPasswordForm')"
              >{{ $t('widgets.login.forgotPassText') }}</a>
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </FormContainer>
  </div>
</template>

<script setup >
import { ref, computed, markRaw } from 'vue';
import { useStore } from 'vuex';

import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import FormContainer from '@/components/common/FormContainer.vue';

const store = useStore();
const inputParams = ref([]);
const inputEl = ref([]);

const showBtnLoader = computed(() => store.state.loaders.showBtnLoader);
const fields = markRaw([
  {
    label: 'widgets.login.fields.emailId.label',
    name: 'email',
    id: 'emailid',
    placeholder: 'widgets.login.fields.emailId.placeholder',
    type: 'email',
    required: true,
  }, {
    label: 'widgets.login.fields.password.label',
    name: 'password',
    id: 'password',
    placeholder: 'widgets.login.fields.password.placeholder',
    type: 'password',
    required: true,
  },
]);

const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const handleSubmit = async () => {
  const data = {
    email: inputParams.value[0],
    password: inputParams.value[1],
  };

  store.dispatch('auth/login', data);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-loginForm {
  width: 100%;

  &__btnContainer {
    width: 100%;
    @include mx.d-flex(center, center, false);
  }

  &__link {
    width: 100%;

    text-align: center;
  }

  &::v-deep(.c-baseButton) {
    @include mx.bp('tablet') {
      width: 50%;
    }
  }
}
</style>
