<template>
  <Navigation
    :class="[{ '-hidePrev': hidePrev,
               '-hideNext': hideNext }]"
  >
    <template #next>
      <Icon
        :data="nextArrow"
        width="12"
        height="auto"
      />
    </template>
    <template #prev>
      <Icon
        :data="prevArrow"
        width="12"
        height="auto"
      />
    </template>
  </Navigation>
</template>

<script setup>
import { onMounted, computed, inject } from 'vue';
import { Navigation } from 'vue3-carousel';

import nextArrow from '@icons/next.svg';
import prevArrow from '@icons/prev.svg';

const carousel = inject('carousel');
const config = inject('config');
const slidesCount = inject('slidesCount');
const slidesToScroll = inject('slidesToScroll');

const hidePrev = computed(() => slidesToScroll.value === 0);

const hideNext = computed(() => {
  if (slidesCount.value < config.itemsToShow) {
    return true;
  }

  if (slidesToScroll.value === slidesCount.value - config.itemsToShow) {
    return true;
  }

  return false;
});

const updateCarouselConfig = () => {
  const root = document.querySelector(':root');
  const { COLORS, NAVIGATION } = carousel;

  root.style.setProperty('--carousel-color-primary', COLORS.PRIMARY);
  root.style.setProperty('--carousel-color-secondary', COLORS.SECONDARY);
  root.style.setProperty('--carousel-color-white', COLORS.WHITE);
  root.style.setProperty('--carousel-nav-width', NAVIGATION.WIDTH);
};

onMounted(updateCarouselConfig);
</script>

<style lang="scss">
.carousel {
  &__prev,
  &__next {
    box-shadow: 0 3px 20px #00000029;
  }

  &__prev {
    transform: translate(-30%, -50%);

    &.-hidePrev {
      display: none; // We need display none to avoid multiple clicks
    }
  }

  &__next {
    transform: translate(30%, -50%);

    &.-hideNext {
      display: none; // We need display none to avoid multiple clicks
    }
  }
}
</style>
