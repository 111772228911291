import ApiRoutes from '@/api/routes';
import axios from '@/mixins/axios';
import { handleErrors } from '@/mixins/utils';
import { AUTO_COMPLETE_ADDRESS as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    autoCompleteAddressList: [],
  },
  mutations: {
    [M.SET_ADDRESS_LIST](state, payload) {
      state.autoCompleteAddressList = payload;
    },
  },
  actions: {
    async getAutoCompleteAddressList({ commit }, address) {
      try {
        const { data: { addresses } } = await axios.post(ApiRoutes.GET_AUTO_COMPLETE_ADDRESS,
          { address });

        if (addresses) {
          commit(M.SET_ADDRESS_LIST, addresses);

          return addresses;
        }
      } catch (error) {
        handleErrors(error);
      }

      return false;
    },
    async getAddressGeoCoding(_, address) {
      try {
        const { data } = await axios.post(ApiRoutes.GET_ADDRESS_GEO_CODING,
          { address });

        if (data) {
          return data;
        }
      } catch (error) {
        handleErrors(error);
      }

      return false;
    },
    setAutoCompleteAddress({ commit }, payload) {
      commit(M.SET_ADDRESS_LIST, payload);
    },
  },
};
