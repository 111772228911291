<template>
  <div class="c-restaurantList">
    <BaseRadio
      v-for="radio in props.list"
      :key="radio.name"
      :text="radio.name"
      :value="JSON.stringify(radio)"
      :name="name"
      :id="`e35283b4-1d13-${radio.id}-${name}`"
      :content="content(radio)"
      :checked="selectedRestaurant.id === radio.id"
      :disabled="disabled(radio)"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import BaseRadio from '@/components/common/BaseRadio.vue';
import { numberFormat } from '@/mixins/utils';

const store = useStore();
const { locale } = useI18n();
const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  selectedRestaurant: {
    type: Object,
    default: () => {},
  },
  name: {
    type: String,
    default: '',
  },
});
const disabled = restaurant => (store.getters['order/isDeliveryOrder'] && !restaurant.deliverable) || false;
const content = restaurant => (restaurant.distanceKm ? `${restaurant.address} - ${numberFormat(restaurant.distanceKm, locale.value)}Km` : restaurant.address);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-restaurantList {
  @include mx.use-custom-scroll(170px);

  margin-top: 2rem;
  height: 100%;
}
</style>
