import _debounce from 'lodash.debounce';
import {
  onMounted, onUnmounted, computed, reactive,
} from 'vue';

export default function () {
  const windowSize = reactive({ w: window.innerWidth, h: window.innerHeight });
  const isMobile = computed(() => windowSize.w < 640);

  const resizeListener = _debounce(() => {
    ({ innerWidth: windowSize.w, innerHeight: windowSize.h } = window);
  }, 400);

  onMounted(() => window.addEventListener('resize', resizeListener));
  onUnmounted(() => window.removeEventListener('resize', resizeListener));
  // Call it once to set the correct size on load
  resizeListener();

  return { isMobile };
}

