/* eslint-disable import/no-cycle */
import ApiRoutes from '@/api/routes';
import axios from '@/mixins/axios';
import Trans from '@/mixins/translation';
import { handleErrors } from '@/mixins/utils';
import router from '@/router';
import { PRODUCTS as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    houseSpecialities: [],
    product: {},
    restaurantCategories: [],
    categoryData: [],
    searchText: '',
  },
  mutations: {
    [M.SET_HOUSE_SPECIALITIES_PRODUCTS](state, payload) {
      state.houseSpecialities = payload;
    },
    [M.SET_SINGLE_PRODUCT](state, payload) {
      state.product = payload;
    },
    [M.SET_RESTAURANT_CATEGORIES](state, { data, type }) {
      state.restaurantCategories = type !== 'all' ? data.filter(d => d.show) : data;
    },
    [M.SET_CATEGORY_DATA](state, payload) {
      state.categoryData = payload;
    },
    [M.SET_SEARCH_TEXT](state, payload) {
      state.searchText = payload;
    },
  },
  actions: {
    async fetchHouseSpecialitiesProducts({ commit }, shopId) {
      this.dispatch('loaders/setLoader', true);
      try {
        const { data: { data } } = await axios
          .get(ApiRoutes.GET_HOUSE_SPECIALITIES_PRODUCTS, { params: { shopId } });

        if (data) {
          commit(M.SET_HOUSE_SPECIALITIES_PRODUCTS, data);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async getSingleProduct({ commit }, url) {
      this.dispatch('loaders/setLoader', true);
      commit(M.SET_SINGLE_PRODUCT, null);

      try {
        const { data: { data } } = await axios.get(url);

        if (data) {
          commit(M.SET_SINGLE_PRODUCT, data);
        }
      } catch (error) {
        await router.push(Trans.i18nRoutePath('/'));
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async getSingleRestaurantCategories({ commit }, { id, ...payload }) {
      this.dispatch('loaders/setLoader', true);
      try {
        console.log('payload', payload);
        const { data: { data } } = await axios.get(`${ApiRoutes.GET_RESTAURANT_CATEGORIES}/${id}`);

        if (data) {
          commit(M.SET_RESTAURANT_CATEGORIES, { data, type: 'single' });
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async getDefaultRestaurantCategories({ commit }, payload) {
      this.dispatch('loaders/setLoader', true);
      try {
        console.log('home cate. payload', payload);
        const { data: { data } } = await axios.get(ApiRoutes.GET_RESTAURANT_CATEGORIES);

        if (data) {
          commit(M.SET_RESTAURANT_CATEGORIES, { data, type: 'all' });
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
  getters: {
    getFilteredProducts(state) {
      const searchText = state.searchText.toLowerCase().trim();

      if (!searchText) return state.categoryData;

      return state.categoryData.map(item => ({
        ...item,
        products: item.products
          .filter(d => d?.name.toLowerCase()?.includes(state.searchText)
                  || d?.description.toLowerCase()?.includes(state.searchText)),
      }));
    },
  },
};
