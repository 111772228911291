<template>
  <div class="c-fullScreenLoader">
    <div
      class="c-fullScreenLoader__wrapper"
    >
      <div class="c-fullScreenLoader__spinner" />
    </div>
  </div>
</template>

<script setup />

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-fullScreenLoader {
  @include mx.use-zeta-index('overlay');

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba($color: #000000, $alpha: 0.3);

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 200px;
    height: 200px;
    margin: auto;
  }

  &__spinner {
    position: absolute;
    top: 20px;
    left: 20px;

    display: block;

    width: 160px;
    height: 160px;

    animation: loader 1s linear infinite;

    border-radius: 80px;
    box-shadow: 0 6px 0 0 var(--#{ v.$variable-prefix}bodyColorDt);
  }
  @-webkit-keyframes loader {
    0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
