<template>
  <div
    class="c-radio"
    :class="{ disabled }"
  >
    <input
      type="radio"
      class="c-radio__input"
      :value="props.value"
      :id="$attrs.id"
      :disabled="disabled"
      v-bind="$attrs"
      @change="selectedOption"
    >
    <label
      class="c-radio__label"
      :for="$attrs.id"
    >
      <span class="c-radio__span">{{ props.text }}</span>
      <small
        class="c-radio__content"
        v-if="props.content"
      >{{ props.content }}</small>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
  text: { type: String, default: '' },
  content: { type: String, default: '' },
  modelValue: {
    type: [ String, Number, Array, Object ],
    default: '',
  },
  value: {
    type: [ String, Number, Object ],
    default: '',
  },
  fromPdp: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([ 'update:modelValue' ]);

const selectedOption = event => {
  if (props.disabled) {
    return;
  }
  emit('update:modelValue', props.fromPdp ? [ props.value ] : event.target.value);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-radio {
  position: relative;

  margin: 1rem 1rem 1rem 0;

  cursor: pointer;

  border-bottom: 1px solid var(--#{ v.$variable-prefix}grayWarm);

  &.disabled {
    cursor: not-allowed;
  }

  &__label {
    @include mx.d-flex('flex-start', false, true);

    margin-left: 2.5rem;
    padding: 0 0 1rem 0;
  }

  &__span {
    @include mx.use-color('bodyColorLt');

    letter-spacing: 0.3px;

    font-weight: bold;
  }

  &__content {
    @include mx.use-color('bodyColorLtLight');
    @include mx.font-size(12px);

    padding-top: 0.5em;

    letter-spacing: 0.24px;

    opacity: 0.7;
  }

  .c-radio__input {
    @include mx.hide-el-the-accessible-way();

    + .c-radio__label {
      cursor: pointer;

      &:before {
        position: absolute;
        top: 4px;
        bottom: 0;
        left: 12px;

        margin-bottom: auto;
        width: 12px;
        height: 12px;

        content: '';
        cursor: pointer;
        transition: all 250ms ease;

        border-radius: 50%;
        background: var(--#{ v.$variable-prefix}grayWarm);
      }
    }

    &:checked {
      + .c-radio__label {
        &:before {
          background-color: var(--#{ v.$variable-prefix}primary);
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      + .c-radio__label {
        &:before {
          cursor: not-allowed;

          background: var(--#{ v.$variable-prefix}disabled);
        }

        .c-radio__span,
        small {
          cursor: not-allowed;

          color: var(--#{ v.$variable-prefix}disabled);
        }
      }
    }
  }
}
</style>
