<template>
  <template v-if="dataHasBeenDownload">
    <RouterView name="header" />
    <AppSideMenu />
    <main class="l-appBody">
      <RouterView />
    </main>
    <RouterView name="footer" />
  </template>
  <FullScreenLoader v-if="showLoader" />
</template>

<script>
/* eslint-disable import/order */
import { authFactory } from '@/mixins/utils';
import router from '@/router';
import { NAMES } from '@/router/constants';

const token = localStorage.getItem('accessToken');

if (token && !authFactory(token)) {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('vuex');
  router.replace({ name: NAMES.HOME });
}
</script>

<script setup>
/* eslint-disable import/first */
import {
  provide, computed, ref, onMounted, onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';

import AppSideMenu from '@/components/common/AppSideMenu.vue';
import FullScreenLoader from '@/components/common/Loader/FullScreenLoader.vue';
import setClassProperty from '@/mixins/setClassProperty';
import useScreenSize from '@/mixins/useScreenSize';
import { apiServices } from '@/api/services';

const store = useStore();

const showLoader = computed(() => store.state.loaders.showLoader);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const dataHasBeenDownload = ref(false);
/**
 * TODO for meta tag use https://v3.vuejs.org/guide/teleport.html
 */

async function setSiteColours() {
  const { colors } = await apiServices.getSiteColor();

  setClassProperty(colors);
}

onBeforeMount(async () => {
  try {
    await setSiteColours();
    dataHasBeenDownload.value = true;
  } catch {
    dataHasBeenDownload.value = false;
    // TODO redirect to error page
  }
});

/* TODO: fix the loader state on page refresh */
onMounted(async () => {
  const user = isAuthenticated.value && await store.dispatch('auth/getUserInfo');
  const settings = await store.dispatch('reusable/fetchSettings');
  const tempCartId = sessionStorage?.getItem('temp_cart_id');

  let cart = {};

  if (tempCartId) {
    cart = !isAuthenticated.value && store.dispatch('cart/getTempCartData', tempCartId);
  }

  await Promise.all([ user, settings, cart ])
    .then(res => {
      if (res) {
        store.dispatch('loaders/setLoader', false);
      }
    }).catch(error => {
      store.dispatch('loaders/setLoader', false);
    });
});

const getMobileOperatingSystem = () => {
  const match = navigator.userAgent.match(
    /(Windows Phone|Android|iPad|iPhone|iPod)/,
  ) || [];

  return {
    'Windows Phone': 'Windows Phone',
    Android: 'android',
    iPad: 'iOS',
    iPhone: 'iOS',
    iPod: 'iOS',
  }[match[0]] || false;
};

const { isMobile } = useScreenSize();

provide('OS', getMobileOperatingSystem());
provide('isMobile', isMobile);

</script>

<style lang="scss">
@use "src/assets/styles/abstracts/mixins" as mx;

#app {
  @include mx.d-flex(false, false, true);

  min-height: 100vh;
}

.l-appBody {
  flex-grow: 1;
}

</style>
