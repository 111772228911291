<template>
  <div :class="['c-localeSwitcher', {'-isDark': props.isDark}]">
    <BaseButton
      class="h-ml-10"
      variant="solid"
      size="small"
      color="tertiary"
      :btn-label="locale"
      @click="toggleMenu"
    />
    <div class="c-localeSwitcher__itemsWrapper">
      <transition name="slideFromTop">
        <div
          class="c-localeSwitcher__items"
          v-show="showMenu"
        >
          <div
            class="c-localeSwitcher__item"
            v-for="item in filteredLocales"
            :key="item"
          >
            <input
              :id="item"
              v-model="locale"
              class="c-localeSwitcher__radio c-baseButton -solid -tertiary -medium"
              :value="item"
              type="radio"
              v-text="item"
              @click="switchLocale(item)"
            >
            <label
              class="c-localeSwitcher__label"
              :for="item"
              v-text="item"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import Trans from '@/mixins/translation';

const props = defineProps({
  isDark: {
    type: Boolean,
  },
});

const store = useStore();

const router = useRouter();
const route = useRoute();
const showMenu = ref(false);
const { locale } = useI18n();

const filteredLocales = computed(() => Trans.availableLanguage.filter(l => l !== locale.value));
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const switchLocale = loc => {
  if (locale !== loc) {
    Trans.loadLocaleAsync(loc).then(async () => {
      if (router.currentRoute.value.name === 'Home') {
        await store.dispatch('products/getDefaultRestaurantCategories');
      } else if (selectedRestaurantId.value) {
        await store.dispatch(
          'products/getSingleRestaurantCategories',
          { id: selectedRestaurantId.value },
        );
      }
      router.push({
        ...route,
        params: {
          ...route.params,
          locale: loc,
        },
      });
    });
  }

  toggleMenu();
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-localeSwitcher {
  $self: &;

  position: relative;

  &__itemsWrapper {
    position: absolute;
    top: 40px;
    left: 50%;

    overflow: hidden;

    transform: translateX(calc(-50% - 8px));

    box-shadow: v.$base-box-shadow;
  }

  &__items {
    @include mx.use-bg-color("lightColor");

    padding: 10px 0;

    transition: 0.5s background-color ease-in;

    border-radius: 5px;
    box-shadow: v.$base-box-shadow;
  }

  &__item {
    margin-bottom: 4px;
  }

  &__radio {
    @include mx.hide-el-the-accessible-way();
  }

  &__label {
    @include mx.font-size(12px);
    @include mx.use-color("primary");

    padding: 10px 24px;

    cursor: pointer;
    text-transform: uppercase;
  }

  &::v-deep(.c-baseButton) {
    text-transform: uppercase;
  }
}

/* Slide From Top
   ----------------------------- */
.slideFromTop {
  &-enter-active,
  &-leave-active {
    transition: transform 500ms, opacity 500ms;

    opacity: 1;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
