<template>
  <div class="c-baseSelect">
    <label
      :for="$attrs.id"
      :class="['c-baseSelect__label', {'srOnly': hideLabel}]"
      v-text="label"
    />
    <div class="c-baseSelect__wrapper">
      <select
        class="c-baseSelect__select"
        @change="selectedOption"
        v-bind="$attrs"
      >
        <option
          value=""
          selected
          hidden
          v-text="placeholderText || $t('common.select.defaultPlaceHolderText')"
        />
        <option
          v-for="opt in options"
          :key="opt.value"
          :value="opt.id ?? opt.value"
          :selected="opt.selected ||false"
        >
          {{ opt.label || 'No options' }}
        </option>
      </select>
      <Icon
        class="c-baseSelect__arrow"
        :data="require(`@icons/chevron-down.svg`)"
        width="10"
      />
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [ String, Number, Array ],
    default: '',
  },
  value: {
    type: [ String, Number, Object ],
    default: '',
  },
  fromPdp: {
    type: Boolean,
  },
  // TODO: this should be required for accessibility
  label: {
    type: String,
    default: '',
  },
  hideLabel: {
    type: Boolean,
  },
  placeholderText: {
    type: String,
    default: '',
  },
});
const emit = defineEmits([ 'update:modelValue' ]);

const selectedOption = event => {
  if (props.fromPdp) {
    emit('update:modelValue', [ JSON.parse(event.target.value) ]);
  } else {
    emit('update:modelValue', event.target.value);
  }
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-baseSelect {
  width: 100%;

  &__wrapper {
    position: relative;

    width: 100%;
    margin: 0.5em 0;
  }

  &__label {
    @include mx.font-size(12px);

    letter-spacing: 0;
    text-transform: uppercase;

    color: transparentize(v.$body-color-light, 0.5);
  }

  &__select {
    @include mx.use-color('bodyColorLt');

    width: 100%;
    min-height: 44px;
    padding: 14px 20px;

    letter-spacing: 0.3px;

    border: transparent;
    border-radius: 3px;
    outline: none;
    background-color: transparentize(v.$body-color-light, 0.85);
    appearance: none;

    @include mx.bp('tablet-wide') {
      min-width: 170px;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 20px;

    transform: translate(0, -50%);
    pointer-events: none;
  }
}
</style>
