<template>
  <div>
    <Typography
      class="-fontBold -title"
      v-text="$t('widgets.orderModal.catering.title')"
      variant="body1"
    />
    <AddressBox
      @selected="getSelectedAddress"
      label-color="secondary"
      has-border
      icon-left
      id="catering-address"
      placeholder="Search address"
      v-model="address"
    />
    <LoadRestaurant :list="cateringList" />
    <FieldLoader
      v-if="loadingList"
      class="h-mv-20"
    />
    <RestaurantList
      v-else
      :list="cateringList"
      @change="getSelectedRestaurant"
      :selected-restaurant="selectedRestaurant"
      name="catering-list"
    />
    <div v-if="!cateringList.length && address.length > 0">
      No Restaurants found!
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

import RestaurantList from '@/components/Order/RestaurantList.vue';
import AddressBox from '@/components/common/AddressBox.vue';
import FieldLoader from '@/components/common/Loader/FieldLoader.vue';

const address = inject('address');
const cateringList = inject('cateringList');
const selectedRestaurant = inject('selectedRestaurant');
const getSelectedAddress = inject('getSelectedAddress');
const getSelectedRestaurant = inject('getSelectedRestaurant');
const loadingList = inject('loadingList');

</script>
