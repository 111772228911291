<template>
  <Teleport to="head">
    <title>{{ pageTitle }}</title>
    <meta
      v-if="description"
      name="description"
      :content="description"
    >
    <meta
      v-if="author"
      name="author"
      :content="companyName"
    >
    <meta
      name="referrer"
      content="always"
    >
    <meta
      property="og:url"
      :content="pageUrl"
    >
    <meta
      property="og:description"
      :content="description"
    >
    <meta
      v-if="ogImage"
      property="og:image"
      :content="image"
    >
    <meta
      v-if="ogImage"
      property="og:title"
      :content="`${pageTitle}`"
    >
    <meta
      v-if="ogImage"
      property="og:site_name"
      :content="companyName"
    >
    <meta
      v-if="ogImage"
      property="og:type"
      content="website"
    >
    <meta
      v-if="ogImage"
      property="og:image:type"
      content="image/jpeg"
    >
    <meta
      property="og:locale"
      :content="locale"
    >
    <!-- TODO UNCOMMENT WHEN THE FAVICON URL IS AVAILABLE (logo scaled???) -->
    <!-- For all browsers -->
    <!-- <link rel="icon" type="image/png" sizes="32x32" href="favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="favicon-16x16.png"> -->

    <!-- For Google and Android -->
    <!-- <link rel="icon" type="image/png" sizes="48x48" href="favicon-48x48.png"> -->
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      :href="icon"
    >

    <!-- For iPad -->
    <!--
      <link rel="apple-touch-icon" type="image/png" sizes="167x167" href="favicon-167x167.png">
    -->
    <!-- For iPhone -->
    <!--
      <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="favicon-180x180.png">
    -->
  </Teleport>
  <slot />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: '',
  },
  author: {
    type: String,
    default: '',
  },
  ogImage: {
    type: String,
    default: null,
  },
});
const { locale, getLocaleMessage } = useI18n();
const pageUrl = window.location.href;
const { companyName } = getLocaleMessage(locale.value).footer;
const pageTitle = props.title === null ? companyName : `${props.title} | ${companyName}`;
// TODO change with appropriate icon
const icon = getLocaleMessage(locale.value).header.logoLightUrl;
const image = props.ogImage || getLocaleMessage(locale.value).header.background[0].url;

</script>
