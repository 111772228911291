const GLOBAL_CONST = {
  TYPOGRAPHY: {
    VARIANTS: [
      'hero',
      'h1',
      'h2',
      'h3',
      'subtitle1',
      'subtitle2',
      'subtitle3',
      'body1',
      'body2',
      'button' ],
    COMPONENTS: [ 'h1', 'h2', 'h3', 'small', 'span', 'p' ],
  },
  CLASSES: {
    DARK_THEME: '-darkTheme',
    ROUNDED: '-rounded',
    LOADING: '-loading',
    LOADED: '-loaded',
    LOCK_SCROLL: '-noScroll',
  },
  IMAGE_TYPES: {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
  },
  CAROUSEL: {
    COLORS: {
      PRIMARY: 'white',
      SECONDARY: '#D80646',
      WHITE: '#D80646',
    },
    NAVIGATION: {
      WIDTH: '40px',
    },
  },
  CARDS: {
    PROMO: {
      VARIANT: [ '01', '02' ],
    },
  },
  BUTTONS: {
    COLORS: [ 'primary', 'secondary', 'tertiary', 'facebook', 'apple', 'google', 'email', 'muted', 'bodyColorDt', 'bodyColorLtLight', 'lightColor' ],
    SIZES: [ 'small', 'medium', 'large' ],
    VARIANT: [ 'default', 'solid', 'outlined', 'custom' ],
  },
  MODAL: {
    SIZES: [ 'small', 'medium', 'large' ],
  },
  ALIGNMENTS: [
    'Left', 'Center', 'Right', 'LeftCenter', 'RightCenter', 'CenterLeft', 'CenterRight' ],
  KEYS: { feedbackMsg: 'feedbackMsg' },
  CONTAINER: {
    BG_COLOR: [ '01', '02', 'inherit' ],
    SIZE: [ 'sm', 'md', 'lg', 'full' ],
  },
  DISPLAY: [ 'block', 'flex' ],
};

export const MOCK_IMAGE = [
  {
    url: 'https://via.placeholder.com/800x400',
    altText: 'Qualche Immagine',
    format: GLOBAL_CONST.IMAGE_TYPES.MOBILE,
  },
  {
    url: 'https://via.placeholder.com/1800x800',
    format: GLOBAL_CONST.IMAGE_TYPES.DESKTOP,
  },
];

export const {
  TYPOGRAPHY, CARDS, CLASSES, IMAGE_TYPES, CAROUSEL,
  BUTTONS, MODAL, ALIGNMENTS, KEYS, CONTAINER, DISPLAY,
} = GLOBAL_CONST;
export default GLOBAL_CONST;
