const GLOBAL_ROUTES = {
  NAMES: {
    HOME: 'Home',
    DASHBOARD: 'Dashboard',
    PROFILE: 'Profile',
    PROFILE_OVERVIEW: 'ProfileOverview',
    PROFILE_ADDRESS: 'ProfileAddress',
    PROFILE_NOTIFICATION: 'ProfileNotification',
    PROFILE_LOYALTY: 'ProfileLoyalty',
    PROFILE_PREFERENCES: 'ProfilePreferences',
    PROFILE_INVITE_FRIEND: 'ProfileInviteFriend',
    RESET_PASSWORD: 'ResetPassword',
    CONFIRM_REGISTER: 'ConfirmRegister',
    ITEM_DETAILS: 'ItemDetails',
    CHECKOUT: 'Checkout',
    PAYMENT: 'Payment',
    DESIGN_SYSTEM: 'DesignSystem',
    ORDER_HISTORY: 'OrderHistory',
    THANK_YOU: 'ThankYou',
    TERMS_OF_SERVICE: 'TermsOfService',
    POLICY: 'Policy',
  },
  PATHS: {
    HOME: '/',
    DASHBOARD: 'dashboard/:restaurantId',
    PROFILE: 'profile',
    PROFILE_OVERVIEW: 'profile/overview',
    PROFILE_ADDRESS: 'profile/address',
    PROFILE_NOTIFICATION: 'profile/notification',
    PROFILE_LOYALTY: 'profile/loyalty',
    PROFILE_PREFERENCES: 'profile/preferences',
    PROFILE_INVITE_FRIEND: 'profile/invite-a-friend',
    ORDER_HISTORY: 'profile/order-history',
    RESET_PASSWORD: 'reset-password/:token',
    CONFIRM_REGISTER: 'confirm-register',
    ITEM_DETAILS: 'item-details/:shopId/:deliveryType/:slug/:productId',
    CHECKOUT: 'checkout',
    PAYMENT: 'payment',
    DESIGN_SYSTEM: 'design-system',
    THANK_YOU: 'order/thank-you/:id',
    TERMS_OF_SERVICE: 'tos',
    POLICY: 'privacy',
  },
};

export const { NAMES, PATHS } = GLOBAL_ROUTES;
