import { CAROUSEL } from '@/constants';

const setClassProperty = colors => {
  const root = document.querySelector(':root');
  const carouselColors = [ 'color-primary', 'color-secondary', 'color-white' ];

  Object.entries(colors).forEach(([ k, v ]) => {
    if (carouselColors.includes(k)) {
      CAROUSEL.COLORS[k.replace('color-', '').toUpperCase()] = v;
    } else {
      root.style.setProperty(`--st-${k}`, v);
    }
  });
};

export default setClassProperty;
