<template>
  <transition name="slideFromLeft">
    <div
      class="c-sideMenu"
      v-show="isSideMenuOpen"
    >
      <div
        class="c-sideMenu__header"
      >
        <BaseButton
          icon="close"
          color="muted"
          icon-width="15"
          @click="store.dispatch('overlays/setSideMenuVisibility', false)"
        />
        <router-link :to="$i18nRoute({ name: HOME })">
          <img
            class="c-sideMenu__logo"
            :src="$t('sideMenu.logoUrl')"
            alt="logo"
          >
        </router-link>

        <div v-if="isMobile">
          <div
            class="c-sideMenu__rightContent"
            v-if="formattedSubtotalDiscounted || subTotal"
          >
            <BaseButton
              icon="cart"
              class="h-mr-10"
              variant="solid"
              color="primary"
              size="small"
              :btn-label="formattedSubtotalDiscounted || subTotal"
              :to="$i18nRoute({ name: CHECKOUT })"
            />
          </div>
        </div>
      </div>
      <div class="c-sideMenu__links">
        <div
          v-for="({icon, link, textKey, visible}) in sideMenu"
          :class="['c-sideMenu__linkRow', {'-hasBorder': visible}]"
          :key="icon"
        >
          <BaseButton
            v-if="visible"
            class="c-sideMenu__anchor"
            :to="icon !== 'logout' && $i18nRoute(link)"
            :icon="icon"
            :icon-color="icon === 'logout' ? 'muted' : 'primary'"
            :color="icon === 'logout' ? 'muted' : 'secondary'"
            @click.prevent="icon === 'logout' && store.dispatch('auth/logout')"
          >
            <template #html-label>
              <div
                class="c-sideMenu__label"
              >
                {{ $t(textKey) }}
                <div
                  class="body1 -muted h-mv-0"
                  v-if="icon === 'user'"
                  v-text="userEmail"
                />
              </div>
            </template>
          </BaseButton>
        </div>
      </div>
    </div>
  </transition>
  <div
    class="c-sideMenu__backDrop"
    v-if="isSideMenuOpen"
    @click.self="store.dispatch('overlays/setSideMenuVisibility', false)"
  />
</template>

<script setup>
import { computed, inject, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';

const store = useStore();
const route = useRoute();
const userEmail = computed(() => store.state.auth.user.email);
const isSideMenuOpen = computed(() => store.state.overlays.isSideMenuOpen);
const fidelityActive = computed(() => store.getters['reusable/getSetting']('fidelity_active'));
const subTotal = computed(() => store.getters['cart/getFormattedSubTotal']);
const formattedSubtotalDiscounted = computed(() => store.getters['cart/getFormattedSubTotalDiscounted']);

const isMobile = inject('isMobile');
const {
  HOME, PROFILE_OVERVIEW, PROFILE_LOYALTY, ORDER_HISTORY, PROFILE_INVITE_FRIEND, CHECKOUT,
} = inject('routeNames');
const sideMenu = [
  {
    icon: 'home',
    textKey: 'sideMenu.menu.home',
    visible: true,
    link: { name: HOME },
  },
  {
    icon: 'user',
    textKey: 'sideMenu.menu.profile',
    visible: true,
    link: { name: PROFILE_OVERVIEW },
  },
  {
    icon: 'history',
    textKey: 'sideMenu.menu.history',
    visible: true,
    link: { name: ORDER_HISTORY },
  },
  {
    icon: 'loyalty',
    textKey: 'sideMenu.menu.loyalty',
    visible: fidelityActive.value,
    link: { name: PROFILE_LOYALTY },
  },
  {
    icon: 'user-friends',
    textKey: 'sideMenu.menu.inviteFriend',
    visible: fidelityActive.value,
    link: { name: PROFILE_INVITE_FRIEND },
  },
  {
    icon: 'logout',
    textKey: 'sideMenu.menu.logout',
    visible: true,
  },
];

watch(() => route.path, () => store.dispatch('overlays/setSideMenuVisibility', false));

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-sideMenu {
  @include mx.use-bg-color('bodyColorDt');
  @include mx.use-zeta-index('sidemenu');

  position: fixed;
  top: 0;
  left: 0;

  overflow-x: hidden;

  width: 100%;
  height: 100vh;
  @include mx.bp('tablet-wide') {
    width: 400px;
  }

  &__header {
    @include mx.d-flex(false, center);

    padding: 18px 14px;

    border-bottom: 1px solid lighten(v.$body-color-light, 50%);

    @include mx.bp('tablet-wide') {
      padding: 32px 46px;
    }
  }

  &__logo {
    @include mx.constrain-image(100%, 80px);

    box-sizing: border-box;
    padding-left: 20px;

    @include mx.bp('tablet-wide') {
      padding-left: 30px;
    }
  }

  &__rightContent {
    @include mx.d-flex(flex-end, false, false);

    width: 100%;
  }

  &__linkRow.-hasBorder {
    border-bottom: 1px solid lighten(v.$body-color-light, 50%);
  }

  &__links {
    display: grid;

    padding-top: 5rem;

    &::v-deep(.c-baseButton) {
      .svg-icon {
        margin: 30px 20px;

        @include mx.bp('tablet-wide') {
          margin-left: 50px;
        }
      }
    }
  }

  &__label {
    @include mx.font-size(14px, 16px);

    text-transform: initial;

    font-weight: 600;
  }
}

.c-sideMenu__backDrop {
  @include mx.use-zeta-index('backdrop');

  position: fixed;
  top: 0;

  width: 100vw;
  height: 100vh;

  opacity: 0.7;
  background: rgba(23, 22, 20, 1) 0 0 no-repeat padding-box;
}

/* Slide From Left
   ----------------------------- */
.slideFromLeft {
  &-enter-active,
  &-leave-active {
    transition: transform 500ms;
  }

  &-enter-from,
  &-leave-to {
    transform: translateX(-100%);
  }
}
</style>
