import { apiServices } from '@/api/services';
// eslint-disable-next-line import/no-cycle
import i18n, { defaultLanguage } from '@/i18n';
import { setAxiosAcceptLang } from '@/mixins/axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

let availableLanguage = [];

const loadedLanguages = [];

const Trans = {
  get defaultLocale() {
    return defaultLanguage;
  },
  get currentLocale() {
    return i18n.global.locale;
  },
  set currentLocale(locale) {
    i18n.global.locale = locale;
  },
  get availableLanguage() {
    return availableLanguage;
  },
  set availableLanguage(lang) {
    availableLanguage = lang;
  },
  get loadedLanguages() {
    return loadedLanguages;
  },
  set loadedLanguages(lang) {
    loadedLanguages.push(lang);
  },
  getUserSupportedLocale() {
    const userPreferredLocale = Trans.getUserLocale();

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoISO)) {
      return userPreferredLocale.localeNoISO;
    }

    return Trans.defaultlocale;
  },
  getUserLocale() {
    const locale = window.navigator.language
      || window.navigator.userLanguage
      || Trans.defaultLocale;

    return {
      locale,
      localeNoISO: locale.split('-')[0],
    };
  },
  setI18nLocaleInServices(locale) {
    Trans.currentLocale = locale;
    setAxiosAcceptLang(locale);
    document.querySelector('html').setAttribute('lang', locale);

    return locale;
  },
  loadLocaleAsync(locale) {
    if (!Trans.isLocaleSupported(locale)) {
      return Promise.reject(new Error('Locale not supported'));
    }

    if (Trans.loadedLanguages.length > 0 && Trans.currentLocale === locale) {
      return Promise.resolve(Trans.setI18nLocaleInServices(locale));
    }

    if (Trans.loadedLanguages.includes(locale)) {
      return Promise.resolve(Trans.setI18nLocaleInServices(locale));
    }

    return Trans.loadApiMessages(locale).then(msgs => {
      i18n.global.setLocaleMessage(locale, msgs);
      Trans.loadedLanguages = locale;

      return Trans.setI18nLocaleInServices(locale);
    });
  },
  async getSiteAvailableLanguages() {
    const lang = await apiServices.getSiteAvailableLanguages();

    if (Trans.availableLanguage.length === 0) {
      Trans.availableLanguage = lang;
    }

    return lang;
  },
  async loadApiMessages(lang) {
    setAxiosAcceptLang(lang);
    const {
      common,
      footer,
      header,
      sideMenu,
      widgets,
    } = await apiServices.getSiteData();
    const footerEmail = footer.email.replace('@', "{'@'}");

    const LOCALE_MESSAGES = {
      common,
      header,
      sideMenu,
      widgets,
      footer: { ...footer, email: footerEmail },
    };

    await store.dispatch('setI18n', LOCALE_MESSAGES);

    return LOCALE_MESSAGES;
  },
  isLocaleSupported(locale) {
    return Trans.availableLanguage.includes(locale);
  },
  routeMiddleware(to, from, next) {
    const { locale } = to.params;

    if (!Trans.isLocaleSupported(locale)) {
      return next(Trans.getUserSupportedLocale());
    }

    return Trans.loadLocaleAsync(locale).then(() => next());
  },
  i18nRoute(to) {
    return {
      ...to,
      params: {
        locale: this.currentLocale,
        ...to.params,
      },
    };
  },
  i18nRoutePath(path) {
    return `/${this.currentLocale}${path[0] !== '/' ? `/${path}` : path}`;
  },
};

export default Trans;
