/* eslint-disable import/no-cycle */
import _merge from 'lodash.merge';

import ApiRoutes from '@/api/routes';
import { OrderModel } from '@/interface/model';
import axios from '@/mixins/axios';
import Trans from '@/mixins/translation';
import { handleErrors } from '@/mixins/utils';
import router from '@/router';
import { PATHS } from '@/router/constants';
import { ORDER as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    orderHistory: [],
    singleOrder: {},
    restaurantList: [],
    orderData: OrderModel,
    orderDetails: {},
    hasOrderBeenSent: false,
    needsInvoice: false,
    billingIsDifferent: false,
    shippingIsValidForDelivery: false,
    // deliveryExtraCost: 0, // irreleavant but left incase of future need
  },
  mutations: {
    [M.SET_ORDER_HISTORY](state, payload) {
      const newOrders = payload.map(order => {
        const splitDate = order.delivery_date.split('/');
        const splitHour = order.delivery_time_start.split(':');

        return {
          ...order,
          utcDate: new Date(
            Date.UTC(
              Number(splitDate[2]),
              Number(splitDate[1]) - 1,
              Number(splitDate[0]),
              Number(splitHour[0]) - 1,
              Number(splitHour[1]),
            ),
          ),
        };
      });

      state.orderHistory = newOrders;
    },
    [M.SET_ORDER_DATA](state, payload) {
      state.orderData = _merge(state.orderData, payload);
    },
    /** *deliveryExtraCost* This code is currently irrelevant
     * but commented incase it's needed for future

     [M.SET_DELIVERY_EXTRA_COST](state, payload) {
       state.deliveryExtraCost = payload;
     },

    */
    [M.SET_SHIPPING_IS_VALID_FOR_DELIVERY](state, payload) {
      state.shippingIsValidForDelivery = payload;
    },
    [M.SET_NEEDS_INVOICE](state, payload) {
      state.needsInvoice = payload;
    },
    [M.SET_BILLING_IS_DIFFERENT](state, payload) {
      state.billingIsDifferent = payload;
    },
    [M.SET_ORDER_DETAILS](state, payload) {
      state.singleOrder = payload;
    },
    [M.SET_SAVE_ORDER_DETAILS](state, payload) {
      state.orderDetails = payload;
    },
    [M.SET_IF_ORDER_SENT](state, payload) {
      state.hasOrderBeenSent = payload;
    },
    [M.SET_RESTAURANT_LIST](state, payload) {
      state.restaurantList = payload;
    },
  },
  actions: {
    setShippingIsValidForDelivery({ commit }, payload) {
      commit(M.SET_SHIPPING_IS_VALID_FOR_DELIVERY, payload);
    },
    setOrderData({ commit }, payload) {
      commit(M.SET_ORDER_DATA, payload);
    },
    /** DeliveryCost is already added on cart item.
    * So this code is currently not needed but left incase for future need

     setDeliveryExtraCost({ commit }, payload) {
       commit(M.SET_DELIVERY_EXTRA_COST, payload);
     },
    */
    setNeedsInvoice({ commit }, payload) {
      commit(M.SET_NEEDS_INVOICE, payload);
    },
    setBillingIsDifferent({ commit }, payload) {
      commit(M.SET_BILLING_IS_DIFFERENT, payload);
    },
    hasOrderBeenSent({ commit }, payload) {
      commit(M.SET_IF_ORDER_SENT, payload);
    },
    async fetchOrderHistory({ commit }, { limit }) {
      this.dispatch('loaders/setLoader', true);
      try {
        const { data: { data } = [] } = await axios.get(
          `${ApiRoutes.GET_ORDER_HISTORY}?limit=${limit || ''}`,
        );

        commit(M.SET_ORDER_HISTORY, data);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async fetchOrder({ commit, rootState }, { id, userEmail }) {
      this.dispatch('loaders/setLoader', true);

      try {
        const path = !rootState.auth.isAuthenticated
          ? `guest/${id}?email=${userEmail}`
          : id;
        const {
          data: { data },
        } = await axios.get(`${ApiRoutes.GET_ORDER}/${path}`);

        commit(M.SET_ORDER_DETAILS, data);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async reorder(_, id) {
      this.dispatch('loaders/setLoader', true);

      try {
        const {
          data: { data },
        } = await axios.post(ApiRoutes.RECORD_ORDER, { order_id: id });

        await this.dispatch('cart/getCartData', data.cart_id);
        await router.push(Trans.i18nRoutePath(PATHS.CHECKOUT));
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async saveOrder({ commit }, payload) {
      this.dispatch('loaders/setLoader', true);

      try {
        const {
          data: { data },
        } = await axios.post(
          this.state.auth.isAuthenticated
            ? ApiRoutes.GET_ORDER_DATA
            : ApiRoutes.GET_TEMP_ORDER_DATA,
          payload,
        );

        console.log('saved data', data);

        sessionStorage.removeItem('temp_cart_id');
        commit(M.SET_SAVE_ORDER_DETAILS, data);

        const { payment_method } = payload;

        // BANCASELLA
        if (payment_method === '13') {
          console.log('Bancasella');
        }

        // BRAINTREE
        if (payment_method === '5') {
          console.log('BRAINTREE');
        }

        if ([ '1', '2', '4', '6', '7', '8', '10' ].includes(payment_method)) {
          await router.push(Trans.i18nRoutePath(`/order/thank-you/${data.id}`));
        }

        if (data.url) {
          window.open(data.url, '_self');
        }
      } catch (error) {
        console.log('saved error', error);
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
  getters: {
    getDeliveryCost(state, _, rootState) {
      const numDeliveryCost = Number(rootState.cart.cart_items.delivery_cost);
      // irreleavant but left incase of future need
      // const numDeliveryExtraCost = Number(state.deliveryExtraCost);

      // return (numDeliveryCost > 0) ? numDeliveryCost + numDeliveryExtraCost
      //   : numDeliveryCost;
      return numDeliveryCost;
    },
    isTakeawayOrder(state) {
      return state.orderData.delivery_type === 'takeaway';
    },
    isCateringOrder(state) {
      return state.orderData.delivery_type === 'catering';
    },
    isDeliveryOrder(state) {
      return state.orderData.delivery_type === 'delivery';
    },
    getSelectedAddressForDelivery(state, _, rootState) {
      let selected = rootState.auth.user.addresses.find(
        address => address.selectedForDelivery,
      );

      if (selected) {
        selected = rootState.auth.isAuthenticated ? selected.id : selected;
      }

      return selected;
    },
    getSelectedAddressForBilling(state, _, rootState) {
      const selected = rootState.auth.user.addresses.find(
        address => address.selectedForBilling,
      );

      return selected?.id || undefined;
    },
    isDeliveryAddressValid(state) {
      return (
        !!state.orderData.customer_address_id
        || !!state.orderData.customer_address.id
      );
    },
    isBillingAddressValid(state) {
      return !!state.orderData.customer_billing_address_id;
    },
    isPaymentMethodValid(state) {
      return state.orderData.payment_method !== 0;
    },
    isOrderNotesValid(state) {
      return !!state.orderData.order_notes;
    },
    isDeliveryTimeValid(state) {
      return !!state.orderData.delivery_time;
    },
    isCustomerDataValid(state) {
      const { customer } = state.orderData;

      return !!(
        customer.email
        && customer.mobile
        && customer.name
        && customer.privacy
        && customer.surname
        && customer.tos
      );
    },
    isCustomerAddressValid(state) {
      const { customer_address } = state.orderData;

      return !!(
        customer_address.address
        && customer_address.city
        && customer_address.zipcode
        && customer_address.province
      );
    },
    isCustomerGuestFormInvalid(_, getters) {
      if (getters.isCateringOrder) {
        return !getters.isOrderNotesValid || !getters.isCustomerDataValid;
      }

      return !getters.isCustomerDataValid;
    },
    isCustomerAuthFormInvalid(_, getters) {
      return getters.isCateringOrder && !getters.isOrderNotesValid;
    },
    isCateringOrderValid(state, getters, rootState) {
      // return if the order is not catering type
      if (!getters.isCateringOrder) return false;

      // if the user is not authenticated
      if (!rootState.auth.isAuthenticated) {
        return !!(
          rootState.cart.cart_items.cart_id
          && !getters.isCustomerGuestFormInvalid
          && getters.isPaymentMethodValid
        );
      }

      // if the user is authenticated and needs invoice
      if (state.needsInvoice) {
        return !!(
          rootState.cart.cart_items.cart_id
          && getters.isPaymentMethodValid
          && getters.isBillingAddressValid
          && !getters.isCustomerAuthFormInvalid
        );
      }

      // if the user is authenticated and doesn't need invoice
      return !!(
        rootState.cart.cart_items.cart_id
        && getters.isPaymentMethodValid
        && !getters.isCustomerAuthFormInvalid
      );
    },
    isTakeawayOrderValid(state, getters, rootState) {
      // return if the order is not takeaway
      if (!getters.isTakeawayOrder) return false;

      // if the user is not authenticated
      if (!rootState.auth.isAuthenticated) {
        return !!(
          rootState.cart.cart_items.cart_id
          && !getters.isCustomerGuestFormInvalid
          && getters.isPaymentMethodValid
          && state.orderData.delivery_date
          && state.orderData.delivery_time
        );
      }

      // if the user is authenticated and needs invoice
      if (state.needsInvoice) {
        return !!(
          rootState.cart.cart_items.cart_id
          && getters.isPaymentMethodValid
          && getters.isBillingAddressValid
          && state.orderData.delivery_date
          && state.orderData.delivery_time
          && !getters.isCustomerAuthFormInvalid
        );
      }

      // if the user is authenticated and doesn't need invoice
      return !!(
        rootState.cart.cart_items.cart_id
        && getters.isPaymentMethodValid
        && state.orderData.delivery_date
        && state.orderData.delivery_time
        && !getters.isCustomerAuthFormInvalid
      );
    },
    isDeliveryOrderValid(state, getters, rootState) {
      // return if the order is not delivery
      if (!getters.isDeliveryOrder) return false;

      // if the user is not authenticated
      if (!rootState.auth.isAuthenticated) {
        return !!(
          rootState.cart.cart_items.cart_id
          && getters.isCustomerAddressValid
          && !getters.isCustomerGuestFormInvalid
          && getters.isPaymentMethodValid
          && state.orderData.delivery_date
          && state.orderData.delivery_time
          && state.shippingIsValidForDelivery
        );
      }

      // if the user is authenticated and needs invoice,
      // the billing address is the same as shipping address
      if (state.needsInvoice && !state.billingIsDifferent) {
        return !!(
          rootState.cart.cart_items.cart_id
          && getters.isPaymentMethodValid
          && getters.isDeliveryAddressValid
          && getters.isBillingAddressValid
          && state.orderData.delivery_date
          && state.orderData.delivery_time
          && state.shippingIsValidForDelivery
          && state.orderData.customer_address_id
            === state.orderData.customer_billing_address_id
          && !getters.isCustomerAuthFormInvalid
        );
      }

      // if the user is authenticated and needs invoice,
      // the billing address is different from shipping address
      if (state.needsInvoice && state.billingIsDifferent) {
        return !!(
          rootState.cart.cart_items.cart_id
          && getters.isPaymentMethodValid
          && getters.isDeliveryAddressValid
          && getters.isBillingAddressValid
          && state.orderData.delivery_date
          && state.orderData.delivery_time
          && state.shippingIsValidForDelivery
          && state.orderData.customer_address_id
            !== state.orderData.customer_billing_address_id
          && !getters.isCustomerAuthFormInvalid
        );
      }

      return !!(
        rootState.cart.cart_items.cart_id
        && getters.isDeliveryAddressValid
        && getters.isPaymentMethodValid
        && state.orderData.delivery_date
        && state.orderData.delivery_time
        && state.shippingIsValidForDelivery
        && !getters.isCustomerAuthFormInvalid
      );
    },
  },
};
