<template>
  <section class="l-banner">
    <ResponsiveImage
      :style="'height: 100%'"
      class="l-banner__img"
      ignore-observer
      :images="[
        {
          url: $t('header.background[0].url'),
          format: $t('header.background[0].format'),
          altText: $t('header.background[0].altText'),
        },
        {
          url: $t('header.background[1].url'),
          format: $t('header.background[1].format'),
        },
      ]"
    />
    <div class="l-banner__block">
      <SectionContainer
        bg-color="inherit"
        size="md"
      >
        <Typography
          v-text="$t('header.mainText')"
          variant="hero"
          is-dark-theme
        />
        <div class="l-banner__orderForm">
          <div class="l-banner__formLayout">
            <div class="l-banner__addressBox">
              <AddressBox
                @selected="getSelectedAddress"
                :label="$t('header.banner.label')"
                id="address-box"
                :placeholder="$t('header.banner.addrPlaceHolder')"
                v-model="address"
              />
            </div>
            <Typography
              v-if="!isAuthenticated"
              class="-text"
              v-text="$t('header.banner.text')"
              variant="body1"
            />
            <BaseButton
              color="primary"
              variant="solid"
              size="large"
              class="l-banner__actionBtn"
              :disabled="!isAddressSelected"
              :btn-label="$t('header.orderButtonText')"
              @click="handleClick"
            />
          </div>
        </div>
      </SectionContainer>
    </div>
  </section>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import AddressBox from '@/components/common/AddressBox.vue';
import ResponsiveImage from '@/components/common/ResponsiveImage.vue';

const store = useStore();
const selectedAddress = computed(() => store.state.auth.selectedAddress);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

const address = ref(selectedAddress.value);
const isAddressSelected = ref(false || address.value);

// Update address when selected changes on modal
watch(() => selectedAddress.value, value => { address.value = value; });
const handleClick = () => {
  store.dispatch('overlays/setModal', 'Order');
};

const getSelectedAddress = addr => {
  address.value = addr;
  isAddressSelected.value = true;
  store.dispatch('auth/setSelectedAddress', addr);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-banner {
  position: relative;

  width: 100%;
  height: 100vh;

  @include mx.bp('tablet-wide') {
    height: 60vh;
  }

  &__img {
    position: relative;
  }

  &__block {
    position: absolute;
    bottom: 0;

    width: 100%;
    padding: 0 0 4.5rem 0;

    &::v-deep(.l-container__content) {
      @include mx.d-flex(center, center, true);

      @include mx.bp('tablet-wide') {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &::v-deep(.c-modal) {
      .c-modal__body {
        padding-right: 32px;
        padding-left: 32px;
      }

      .c-modal__footer {
        padding: 1.5rem 2rem;

        border-top: 1px solid var(--#{ v.$variable-prefix}grayWarm);
      }
    }

    &::v-deep(.hero) {
      text-align: center;
      letter-spacing: 0.9px;
      @include mx.bp('tablet-wide') {
        text-align: left;
      }
    }
  }

  &__orderForm {
    @include mx.d-flex(flex-start, center, true);

    width: 100%;

    @include mx.bp('tablet-wide') {
      flex-flow: row;
    }
  }

  &__formLayout {
    @include mx.d-flex(flex-start, center, true);

    width: 100%;

    @include mx.bp('tablet-wide') {
      position: relative;

      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__addressBox,
  &__timePicker {
    margin-right: 0;
    @include mx.bp('tablet-wide') {
      margin-right: 10px;
    }
  }

  &__addressBox,
  &__timePicker,
  &__actionBtn {
    width: 100%;

    @include mx.bp('tablet-wide') {
      width: auto;
    }
  }

  &__timePicker {
    padding-top: 0.5em;
    order: 0;

    @include mx.bp('tablet-wide') {
      padding-top: 0;
      order: unset;
    }
  }

  &__actionBtn {
    margin-top: 10px;
    order: 1;

    @include mx.bp('tablet-wide') {
      margin-top: 0;
      order: unset;
    }
  }

  .-text {
    text-align: center;

    order: 1;
    @include mx.use-color('bodyColorDt');

    @include mx.bp('tablet-wide') {
      position: absolute;
      bottom: -35px;

      text-align: left;
      order: unset;
    }
  }
}
</style>
