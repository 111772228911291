<template>
  <div :class="['l-container', bgColor !== 'inherit' && `bg-${bgColor}`]">
    <div :class="['l-container__content', `-${size}`]">
      <slot />
    </div>
  </div>
</template>

<script>
import { CONTAINER } from '@/constants';

export default {
  name: 'SectionContainer',
  props: {
    bgColor: {
      type: String,
      default: '01',
      validator: value => CONTAINER.BG_COLOR.includes(value),
    },
    size: {
      type: String,
      default: 'sm',
      validator: value => CONTAINER.SIZE.includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

$sizes: (
  'full' 0,
  'sm' 10px,
  'md' 20px,
  'lg' 30px
);

.l-container {
  @each $key, $color in map-get(v.$palettes, backgrounds) {
    &.#{ $key } {
      @include mx.use-bg-color(#{ $key });
    }
  }

  &__content {
    box-sizing: border-box;
    @each $key, $size in $sizes {
      &.-#{ $key } {
        @include mx.container-box(v.$max-width, $padding: $size, $padding-md: 0);
      }
    }
  }
}
</style>
