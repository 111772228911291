<template>
  <div class="c-registerForm">
    <FormContainer
      :form-title="$t('widgets.register.formTitle')"
      :src="$t('widgets.login.logoUrl')"
      v-if="showRegisterForm"
    >
      <Accordion>
        <AccordionItem
          :title="$t('widgets.register.accordionTitle')"
          icon="user"
          keep-open
        >
          <template #accordion-content>
            <form
              @submit.prevent="handleSubmit"
            >
              <BaseTextBox
                v-for="({label, id, name, type, required, placeholder}, index) in fields"
                :key="label"
                :label="$t(label)"
                :id="`register-${id}`"
                :name="`register-${name}`"
                :type="type"
                v-model="inputParams[index]"
                :ref="el => { inputEl[index] = el }"
                :required="required"
                :placeholder="$t(placeholder)"
                :disabled="type === 'email' && isLoading"
                :show-loader="type === 'email' && isLoading"
                @focusout.stop="type === 'email' && checkEmail()"
              />
              <div class="c-registerForm__termsAndPolicy">
                <div
                  v-for="([k, v]) in Object.entries(consents)"
                  :key="`register-${k}`"
                  @click.prevent="handleClick(k, v)"
                >
                  <Checkbox
                    name="consents"
                    :text="$t(`common.consentLabels.${k}`)"
                    :value="k"
                    :id="`register-${k}`"
                    :checked="v"
                  />
                </div>
              </div>
              <BaseButton
                :btn-label="$t('widgets.register.registerBtnText')"
                size="small"
                variant="solid"
                color="primary"
                type="submit"
                :display-loader="showBtnLoader"
                :disabled="!inputParams[0] || !inputParams[1] || !inputParams[2] || !inputParams[3]
                  || showBtnLoader || isLoading || hasError ||
                  !consents.privacy || !consents.tos"
              />
              <span class="c-registerForm__span">
                {{ $t('widgets.register.alternativeText') }} <a
                  class="-navLink"
                  @click="store.dispatch('forms/setActiveForm', 'LoginForm')"
                >{{ $t('widgets.register.signInText') }}</a>
              </span>
            </form>
          </template>
        </AccordionItem>
      </Accordion>
    </FormContainer>
    <AcceptTermsAndPolicy
      v-else
      @accept="handleAction"
      :data="termsAndPolicy"
    />
  </div>
</template>

<script setup>
import {
  ref, computed, provide, onMounted, markRaw,
} from 'vue';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import Checkbox from '@/components/common/Checkbox.vue';
import FormContainer from '@/components/common/FormContainer.vue';
import AcceptTermsAndPolicy from '@/components/user/modal/AcceptTermsAndPolicy.vue';

const store = useStore();
const inputParams = ref([]);
const showBtnLoader = computed(() => store.state.loaders.showBtnLoader);
const inputEl = ref([]);
const isLoading = ref(false);
const hasError = ref(false);
const consents = ref({ privacy: false, tos: false });
const termsAndPolicy = ref([]);
const option = ref({});
const showRegisterForm = ref(true);

provide('option', option);
provide('showRegisterForm', showRegisterForm);

const handleClick = (value, checked) => {
  option.value = value;
  if (checked) consents.value = { ...consents.value, [value]: false };
  showRegisterForm.value = checked;
};

const handleAction = ({ value, accept }) => {
  consents.value = { ...consents.value, [value]: accept };
  showRegisterForm.value = true;
};

const fields = markRaw([ {
  label: 'widgets.register.fields.firstName.label',
  name: 'firstName',
  id: 'firstName',
  placeholder: 'widgets.register.fields.firstName.placeholder',
  required: true,
}, {
  label: 'widgets.register.fields.lastName.label',
  name: 'lastName',
  id: 'lastName',
  placeholder: 'widgets.register.fields.lastName.placeholder',
  required: true,
},
{
  label: 'widgets.register.fields.emailId.label',
  name: 'email',
  id: 'email',
  placeholder: 'widgets.register.fields.emailId.placeholder',
  type: 'email',
  required: true,
}, {
  label: 'widgets.register.fields.password.label',
  name: 'password',
  id: 'password',
  placeholder: 'widgets.register.fields.password.placeholder',
  type: 'password',
  required: true,
} ]);

const handleSubmit = () => {
  const data = {
    name: inputParams.value[0],
    surname: inputParams.value[1],
    email: inputParams.value[2],
    password: inputParams.value[3],
  };

  store.dispatch('auth/signUp', data);
};

const checkEmail = async () => {
  if (inputParams.value[2]) {
    isLoading.value = true;
    const exists = await apiServices.checkEmail(inputParams.value[2]);

    isLoading.value = false;
    hasError.value = exists;
    inputEl.value[2].customValidationMsg(exists ? 'Email already exists!' : '');
  }
};

onMounted(() => {
  apiServices.getTermsAndPolicy().then(res => {
    termsAndPolicy.value = res;
  });
});
</script>

<style lang="scss" scoped>
  @use "src/assets/styles/abstracts/mixins" as mx;
  @use "src/assets/styles/abstracts/variables" as v;

  .c-registerForm {
    @include mx.d-flex(center, center, true);

    width: 100%;
    height: inherit;

    &::v-deep(.c-formContainer__container) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &::v-deep(.c-formContainer__link) {
      padding-bottom: 1.5rem;
    }

    &::v-deep(.c-baseTextbox) {
      margin-bottom: 0.5rem;
    }

    &__span {
      padding-bottom: 1rem;
    }
  }
</style>
