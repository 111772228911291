<template>
  <div class="c-appBanner">
    <ResponsiveImage
      :style="'height: 100%'"
      class="c-appBanner__background"
      :images="[
        {
          url: $t('widgets.pickChoice.banner.background[0].url'),
          format: $t('widgets.pickChoice.banner.background[0].format'),
          altText: $t('widgets.pickChoice.banner.background[0].altText'),
        },
        {
          url: $t('widgets.pickChoice.banner.background[1].url'),
          format: $t('widgets.pickChoice.banner.background[1].format'),
        },
      ]"
    />
    <div class="c-appBanner__content">
      <div class="c-appBanner__contentLeft">
        <div class="c-appBanner__contentLeftImg">
          <ResponsiveImage
            :style="'height: 100%'"
            :images="[
              {
                url: $t('widgets.pickChoice.banner.overlay.colLeftImages[0].url'),
                format: $t('widgets.pickChoice.banner.overlay.colLeftImages[0].format'),
                altText: $t('widgets.pickChoice.banner.overlay.colLeftImages[0].altText'),
              },
              {
                url: $t('widgets.pickChoice.banner.overlay.colLeftImages[1].url'),
                format: $t('widgets.pickChoice.banner.overlay.colLeftImages[1].format'),
              },
            ]"
          />
        </div>
      </div>
      <div class="c-appBanner__contentRight">
        <img
          class="c-appBanner__contentLogo"
          :src="$t('widgets.pickChoice.banner.overlay.colRight.logo')"
          alt="logo"
        >
        <Typography
          class="-fontBold"
          v-text="$t('widgets.pickChoice.banner.overlay.colRight.text.body1')"
          variant="subtitle2"
          is-dark-theme
        />
        <Typography
          v-if="!isMobile"
          v-text="$t('widgets.pickChoice.banner.overlay.colRight.text.body2')"
          variant="subtitle3"
          is-dark-theme
        />
        <a
          v-if="isMobile"
          class="c-appBanner__mobileBtn"
          v-text="$t('widgets.pickChoice.banner.overlay.colRight.text.body2')"
          :href="$t(`widgets.pickChoice.banner.overlay.colRight.${OS === 'android' ?
            'playStoreButton' : 'appleStoreButton'}`)"
          target="_blank"
        />
        <div
          class="c-appBanner__desktopBtn"
          v-else
        >
          <a
            v-if="$t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton')"
            :href="$t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton')"
          >
            <img
              class="-isResponsive"
              :src="require('@/assets/app-store-logo.png')"
              alt="apple store logo"
            >
          </a>
          <a
            v-if="$t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')"
            :href="$t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')"
          >
            <img
              class="-isResponsive"
              :src="require('@/assets/google-play-logo.jpeg')"
              alt="play store logo"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

import ResponsiveImage from '@/components/common/ResponsiveImage.vue';

const isMobile = inject('isMobile');
const OS = inject('OS');

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-appBanner {
  @include mx.container-box(v.$max-width, $padding: 0);

  position: relative;

  height: 400px;
  max-height: 100%;
  margin: 30px auto 50px;

  &__content {
    @include mx.d-flex(false, false, true);

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba($color: #000000, $alpha: 0.3);

    @include mx.bp('tablet') {
      flex-direction: row;
    }

    &Left,
    &colRight {
      width: 100%;

      @include mx.bp('tablet') {
        width: 50%;
      }
    }

    &Left {
      @include mx.d-flex('center');

      position: relative;
      order: 2;

      @include mx.bp('tablet') {
        order: 1;
      }

      &Img {
        position: absolute;
        top: 0;

        height: 250px;

        @include mx.bp('tablet') {
          top: 50px;

          height: 400px;
        }
      }
    }

    &Right {
      @include mx.d-flex('center', 'center', true);

      position: relative;

      padding: 10px 0;

      text-align: center;
      order: 1;

      @include mx.bp('tablet') {
        align-items: flex-start;

        max-width: 25%;

        text-align: left;
        order: 2;
      }
    }
  }

  &__desktopBtn {
    @include mx.d-flex('space-between', 'center');

    width: 100%;

    gap: 10px;

    > a {
      flex: 1;
    }
  }

  &__mobileBtn {
    @include mx.d-flex(center, center, false);
    @include mx.use-bg-color(primary);
    @include mx.use-color(bodyColorDt);

    width: max-content;
    padding: 10px 24px;

    cursor: pointer;
    text-decoration: none;
    letter-spacing: 0.3px;

    border: 2px solid;
    border-color: var(--#{ v.$variable-prefix}primary);
    border-radius: v.$base-border-radius;

    font-weight: 500;
  }

  &__contentLogo {
    max-width: 100px;
    height: 50px;
    padding: 10px 0;
  }
}
</style>
