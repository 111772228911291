<template>
  <div
    class="c-login"
  >
    <img
      class="c-login__logo"
      :src="$t('widgets.login.logoUrl')"
      alt="logo"
    >
    <Typography
      v-text="$t('widgets.login.text')"
      class="c-login__text -fontMedium -centerText"
      variant="subtitle3"
    />
    <span
      v-text="$t('widgets.login.subText')"
      class="c-login__subText -centerText"
    />
    <div
      class="c-login__container"
      v-for="({icon, variant, method, key}, i) in loginMethods"
      :key="key"
    >
      <BaseButton
        v-if="methods.includes(key)"
        :icon="icon"
        :variant="variant"
        :color="method"
        :original="icon === 'google-original'"
        @click="handleClick(method)"
        :label-right="$t(`widgets.login.loginMethods[${i}].text`)"
      />
    </div>
    <span
      class="c-login__registerText"
    > <a
      class="-link"
      @click="store.dispatch('forms/setActiveForm', 'RegisterForm')"
    >{{ $t('widgets.login.registerText') }}</a>.</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';

const { locale, getLocaleMessage } = useI18n();
const { loginMethods } = getLocaleMessage(locale.value).widgets.login;

const store = useStore();
const methods = computed(() => store.getters['reusable/getLoginMethods']);

const handleClick = loginMethod => ({
  facebook: () => console.log('handle log in with fb'),
  apple: () => console.log('handle log in with apple'),
  google: () => console.log('handle log in with google'),
  email: store.dispatch('forms/setActiveForm', 'LoginForm'),
}[loginMethod]);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-login {
  @include mx.d-flex(center, center, true);

  width: 100%;
  padding-bottom: 20px;

  &__logo {
    @include mx.constrain-image(220px, 120px);

    margin-bottom: 2.5rem;
  }

  &__text {
    margin: 0;

    letter-spacing: 0.46px;
  }

  .-centerText {
    text-align: center;
  }

  &__subText {
    padding: 1rem 0;

    letter-spacing: 0.32px;
  }

  &__container {
    @include mx.d-flex(center, center, true);

    flex-wrap: wrap;

    width: 100%;

    @include mx.bp('tablet') {
      flex-direction: row;

      width: 60%;
    }
  }

  &::v-deep(.c-baseButton) {
    justify-content: flex-start;

    width: 100%;
    margin: 10px auto;

    @include mx.bp('desktop') {
      width: 48%;
    }
  }

  &__registerText {
    padding-top: 1rem;

    letter-spacing: 0;

    .-link {
      cursor: pointer;

      font-weight: bold;
      @include mx.use-color('primary');
    }
  }
}
</style>
