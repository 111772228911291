<template>
  <div
    v-if="props.name.toLowerCase() === selectedTab"
  >
    <slot />
  </div>
</template>

<script setup>
import { inject, onMounted } from 'vue';

const props = defineProps({
  name: { type: String, required: true },
  hasContent: { type: Boolean },
  showSubText: { type: Boolean },
});

const tabs = inject('tabs');
const selectedTab = inject('selectedTab');

onMounted(() => {
  tabs.value.push({
    name: props.name,
    hasContent: props.hasContent,
    showSubText: props.showSubText,
  });
});
</script>
