<template>
  <SectionContainer
    size="lg"
    class="l-houseSpecialities"
    v-if="houseSpecialities.length"
  >
    <Typography
      class="h-textCenterLeft"
      variant="subtitle2"
      v-text="$t('widgets.houseSpecialities.title')"
    />
    <div class="l-houseSpecialities__images">
      <Card
        v-for="{slug, images, id, price, name} in houseSpecialities"
        :key="id"
        text-align="Center"
        class="l-houseSpecialities__item"
        :title="name || ''"
        :images="images"
        :subtitle="price > 0 ? convertTotal(price, locale) : ''"
        :image-max-height="isMobile ? '191px' : '134px'"
        @click="handleClick(slug, id)"
      />
    </div>
  </SectionContainer>
</template>

<script setup>
import {
  computed, inject, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Card from '@/components/common/Card/Card.vue';
import { setAxiosAcceptLang } from '@/mixins/axios';
import Trans from '@/mixins/translation';
import { convertTotal } from '@/mixins/utils';

const { locale } = useI18n();
const router = useRouter();
const isMobile = inject('isMobile');
const store = useStore();
const houseSpecialities = computed(() => store.state.products.houseSpecialities);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);

store.dispatch('products/fetchHouseSpecialitiesProducts', selectedRestaurantId.value);

const handleClick = (slug, id) => {
  if (selectedRestaurantId.value) {
    const path = Trans.i18nRoutePath(`/item-details/${selectedRestaurantId.value}/${deliveryType.value}/${slug}/${id}`);

    router.push(path);
  } else {
    store.dispatch('overlays/setModal', 'Order');
    store.dispatch('setHouseSpeciality', { slug, id });
  }
};

watch(() => locale.value, lang => {
  setAxiosAcceptLang(lang);
  store.dispatch('products/fetchHouseSpecialitiesProducts', selectedRestaurantId.value);
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-houseSpecialities {
  padding: 80px 0 50px 0;

  &__images {
    display: grid;

    border-radius: 4px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 16px;
  }

  &__item {
    cursor: pointer;
  }
}
</style>
