import { CLASSES } from '@/constants';

export default {
  beforeMount(el, binding) {
    function loadImage() {
      const imageElement = el.querySelector('.js-lazyImage');
      const { previousElementSibling } = imageElement;

      if (imageElement) {
        imageElement.addEventListener('load', function handler() {
          setTimeout(() => {
            el.classList.remove(CLASSES.LOADING);
            el.classList.add(CLASSES.LOADED);
            this.removeEventListener('load', handler);
          }, 100);
        });
        imageElement.addEventListener('error', () => console.error('error: unable to load the image'));
        imageElement.src = imageElement.dataset.src;
        previousElementSibling.srcset = imageElement.dataset.srcSet;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      };
      const observer = new IntersectionObserver(handleIntersect, options);

      observer.observe(el);
    }

    if (window.IntersectionObserver && !binding.value.ignoreObserver) {
      createObserver();
    } else {
      loadImage();
    }
  },
};
