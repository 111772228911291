<template>
  <div class="l-tabs">
    <div
      class="c-tab"
      v-for="tab in tabs"
      :key="tab.name"
    >
      <input
        v-model="selectedTab"
        type="radio"
        :id="tab.name"
        :name="groupName"
        :value="tab.name.toLowerCase()"
        class="c-tab__input"
        v-bind="$attrs"
      >
      <label
        class="c-tab__label"
        :for="tab.name"
        v-text="tab.name"
      />
    </div>
  </div>

  <div class="l-tabsContent">
    <slot />
  </div>
</template>

<script>
import { ref, provide } from 'vue';

export default {
  inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
  groupName: { type: String, required: true },
  initialTab: { type: String, required: true },
});

const tabs = ref([]);
const selectedTab = ref(props.initialTab);

provide('tabs', tabs);
provide('selectedTab', selectedTab);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-tabs {
  @include mx.d-flex();

  width: 100%;
}

.l-tabsContent {
  width: 100%;
}

.c-tab {
  $self: &;

  flex: 1;

  &.-dFlex {
    @include mx.bp('tablet-wide') {
      display: flex;
      flex: none;

      width: 30%;
    }
  }

  &__input {
    @include mx.hide-el-the-accessible-way();
    &:checked + #{$self}__label {
      @include mx.use-bg-color('primary');
      @include mx.use-color('bodyColorDt');
    }
  }

  &__label {
    @include mx.d-flex(center, center);
    @include mx.use-color('primary');
    @include mx.font-size(12px, 16px);

    width: calc(100% - 4px);
    padding: 0.7rem 0;

    cursor: pointer;
    letter-spacing: 0;
    text-transform: capitalize;

    border: 2px solid var(--#{ v.$variable-prefix}primary);
    background: transparent;
  }

  &__content {
    display: none;

    width: 100%;
    padding-top: 0.2rem;

    .-subText {
      letter-spacing: 0.34px;
      @include mx.font-size(14px);
    }

    @include mx.bp('tablet-wide') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &:first-child {
    #{$self}__label {
      border-radius: 3px 0 0 3px;
    }
  }

  &:last-child {
    #{$self}__label {
      border-radius: 0 3px 3px 0;
    }
  }
}
</style>
