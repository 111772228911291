<template>
  <Page>
    <div
      class="home"
      :data-test="$t(text.playStoreButton)"
    >
      <div
        v-if="
          isMobile && OS === 'iOS' && $t(text.appleStoreButton) ||
            isMobile && OS === 'android' && $t(text.playStoreButton) ||
            !isMobile && ($t(text.appleStoreButton) || $t(text.playStoreButton))"
        class="c-appBanner"
      >
        <SectionContainer
          size="md"
          bg-color="02"
        >
          <div :class="['c-appBanner__wrapper', {'-isDesktop': !isMobile}]">
            <img
              class="c-appBanner__img -isResponsive"
              width="50"
              height="50"
              alt="logo"
              :src="$t('widgets.pickChoice.banner.overlay.colRight.logo')"
            >
            <div>
              <Typography
                class="h-mv-0 h-uppercase -muted -fontBold"
                v-text="$t('widgets.pickChoice.banner.overlay.colRight.text.body2')"
                variant="body2"
              />
              <Typography
                v-if="!isMobile"
                class="-fontBold h-mv-0"
                v-text="$t('widgets.pickChoice.banner.overlay.colRight.text.body1')"
                variant="subtitle3"
              />
            </div>
            <a
              v-if="isMobile"
              :href="$t(`widgets.pickChoice.banner.overlay.colRight.${OS === 'android' ?
                'playStoreButton' : 'appleStoreButton'}`)"
              target="_blank"
            >
              <img
                width="100"
                height="50"
                class="-isResponsive"
                :src="require(`@/assets/${OS === 'android' ?
                  'google-play-logo.jpeg' : 'app-store-logo.png'}`)"
                alt="store logo"
              >
            </a>
            <div class="c-appBanner__logos">
              <a
                v-if="!isMobile &&
                  $t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton')"
                :href="$t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton')"
              >
                <img
                  width="100"
                  height="50"
                  class="-isResponsive"
                  :src="require('@/assets/app-store-logo.png')"
                  alt="apple store logo"
                >
              </a>
              <a
                v-if="!isMobile && $t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')"
                :href="$t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')"
              >
                <img
                  width="100"
                  height="50"
                  class="-isResponsive"
                  :src="require('@/assets/google-play-logo.jpeg')"
                  alt="play store logo"
                >
              </a>
            </div>
          </div>
        </SectionContainer>
      </div>
      <BannerHero />
      <SectionContainer size="md">
        <RecentOrders
          @update:selectedOrder="e=>selectedOrder=e"
          home-page
        />
      </SectionContainer>
      <!-- <DiscoverSpecialities v-if="!isAuthenticated" /> -->
      <OurRestaurants />
      <HouseSpecialities />
      <PickChoice />
    </div>
    <RecentOrderModal
      v-if="selectedOrder"
      :selected-order="selectedOrder"
    />
    <PickRestaurantModal :show-modal="showModal" />
  </Page>
</template>

<script setup>
import {
  computed, inject, markRaw, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';

import Page from '@/components/Page.vue';
import BannerHero from '@/components/banner/BannerHero.vue';
import PickRestaurantModal from '@/components/common/PickRestaurantModal.vue';
import RecentOrderModal from '@/components/common/RecentOrderModal.vue';
import RecentOrders from '@/components/common/RecentOrders.vue';
// import DiscoverSpecialities from '@/widgets/DiscoverSpecialities.vue';
import HouseSpecialities from '@/widgets/HouseSpecialities.vue';
import OurRestaurants from '@/widgets/OurRestaurants.vue';
import PickChoice from '@/widgets/PickChoice.vue';

const isMobile = inject('isMobile');
const OS = inject('OS');
const store = useStore();
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const showModal = computed(() => store.state.overlays.openModal === 'Order');
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const selectedOrder = ref(null);
const text = markRaw({
  appleStoreButton: 'widgets.pickChoice.banner.overlay.colRight.appleStoreButton',
  playStoreButton: 'widgets.pickChoice.banner.overlay.colRight.playStoreButton',
});

const deliveryDateTime = computed(() => {
  if (!deliveryTime.value) {
    return null;
  }

  return `${deliveryDate.value} ${deliveryTime.value.slice(0, deliveryTime.value.indexOf('-'))}`;
});

onMounted(async () => {
  const payload = deliveryTime.value ? { delivery_datetime: deliveryDateTime.value } : {};
  const categories = store.dispatch('products/getDefaultRestaurantCategories', { delivery_type: deliveryType.value, ...payload });

  if (isAuthenticated.value) await store.dispatch('order/fetchOrderHistory', { limit: 3 });

  await store.dispatch('loaders/setLoader', true);
  await Promise.all([ categories ])
    .then(res => {
      if (res) {
        store.dispatch('loaders/setLoader', false);
      }
    }).catch(error => {
      console.error({ error });
      store.dispatch('loaders/setLoader', false);
    });
});

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-appBanner {
  @include mx.use-zeta-index('header');

  position: fixed;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  max-height: 60px;

  &__wrapper {
    display: grid;
    align-items: center;

    height: 60px;
    grid-template-columns: 60px auto auto;
    grid-gap: 10px;

    &.-isDesktop {
      grid-template-columns: 60px auto 200px;
    }
  }

  &__logos {
    @include mx.d-flex('flex-end', 'center');

    gap: 10px;

    a {
      max-width: 49%;
    }
  }
}
</style>
