<template>
  <BaseContainer
    class="l-category"
    bg-color="02"
  >
    <SectionContainer bg-color="02">
      <Typography
        v-text="$t('widgets.pickChoice.title')"
        variant="subtitle2"
      />
      <div
        class="l-category__choice"
        v-if="categories.length"
      >
        <BaseButton
          v-for="{name, slug, id} in categories"
          :key="`${generateKey(slug || '')}-${id}`"
          :btn-label="name"
          variant="custom"
          @click="handleClick(slug || '')"
        />
      </div>
    </SectionContainer>
    <DownloadAppBanner
      v-if="$t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton') ||
        $t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')"
    />
  </BaseContainer>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import DownloadAppBanner from '@/components/common/DownloadAppBanner.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import Trans from '@/mixins/translation';
import { generateKey } from '@/mixins/utils';

const router = useRouter();
const store = useStore();
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const categories = computed(() => store.state.products.restaurantCategories);

const handleClick = keyItem => {
  const key = `#${generateKey(keyItem)}`;

  if (!selectedRestaurantId.value) {
    store.dispatch('overlays/setModal', 'Order');
    store.dispatch('setHash', key);
  } else {
    const path = Trans.i18nRoutePath(`/dashboard/${selectedRestaurantId.value}`);

    router.push({ path, hash: key });
  }
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.l-category {
  padding: 40px 0;

  &__choice {
    @include mx.d-flex("center");

    flex-wrap: wrap;
    gap: 10px;

    @include mx.bp("tablet") {
      justify-content: flex-start;
    }
  }
}
</style>
