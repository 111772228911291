<template>
  <section class="order-modal">
    <BaseModal
      size="small"
      :show-modal="props.showModal"
    >
      <template #body>
        <OrderModal :is-confirm="isConfirm" />
      </template>
      <template #footer>
        <BaseButton
          variant="solid"
          color="primary"
          is-full-width
          :btn-label="$t('widgets.orderModal.confirmBtnText')"
          @click="handleConfirm"
          :disabled="disabled"
        />
      </template>
    </BaseModal>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import OrderModal from '@/components/Order/OrderModal.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import Trans from '@/mixins/translation';

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
});

const store = useStore();
const router = useRouter();
const hash = computed(() => store.state.hash);
const houseSpeciality = computed(() => store.state.houseSpeciality);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const isConfirm = ref(false);

const emit = defineEmits([ 'confirmed' ]);

const disabled = computed(() => {
  if (deliveryType.value === 'catering') return !selectedRestaurantId.value;

  return !selectedRestaurantId.value || !deliveryDate.value || !deliveryTime.value;
});

const handleConfirm = () => {
  store.dispatch('overlays/setModal', '');
  isConfirm.value = true;
  // Todo: find some other way without timeout
  setTimeout(() => {
    emit('confirmed');
    if (cartId.value) {
      const tempCartId = sessionStorage.getItem('temp_cart_id');

      const params = {
        delivery_type: deliveryType.value,
        shop_id: selectedRestaurantId.value?.id,
        ...((cartId.value || tempCartId) && { cart_id: cartId.value || tempCartId }),
      };

      store.dispatch(`cart/${isAuthenticated.value ? 'updateCartItems' : 'updateTempCartItems'}`, params);
    }

    if (houseSpeciality.value.id) {
      const path = Trans.i18nRoutePath(`/item-details/${selectedRestaurantId.value}/${deliveryType.value}/${houseSpeciality.value.slug}/${houseSpeciality.value.id}`);

      router.push(path);
    } else {
      const path = Trans.i18nRoutePath(`/dashboard/${selectedRestaurantId.value}`);

      router.push({ path, hash: hash.value });
      store.dispatch('setIsPerfectMatch', false);
      store.dispatch('setHash', '');
      store.dispatch('setHouseSpeciality', {
        id: null,
        slug: null,
      });
    }
  });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/variables" as v;

.order-modal {
  &::v-deep(.c-modal) {
    .c-modal__body {
      padding-right: 32px;
      padding-left: 32px;
    }

    .c-modal__footer {
      padding: 1.5rem 2rem;

      border-top: 1px solid var(--#{ v.$variable-prefix}grayWarm);
    }
  }
}
</style>
