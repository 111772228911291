<template>
  <BaseModal
    size="large"
    :show-modal="showModal"
  >
    <template #body>
      <OrderDetails :selected-order="props.selectedOrder" />
    </template>
  </BaseModal>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import OrderDetails from '@/components/OrderDetails.vue';
import BaseModal from '@/components/common/BaseModal.vue';

const store = useStore();
const showModal = computed(() => store.state.overlays.openModal === 'OrderDetailsModal');

const props = defineProps({
  selectedOrder: {
    type: Object,
    default: () => {},
  },
});

</script>
