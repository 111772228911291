<template>
  <div class="c-acceptTermsAndPolicy">
    <BaseButton
      v-if="!isRecipient"
      icon="prev"
      icon-width="14"
      btn-label="back"
      color="muted"
      class="c-acceptTermsAndPolicy__backButton"
      @click="showRegisterForm = true"
    />
    <Typography
      class="-fontBold"
      v-text="termsAndPolicy?.title"
      variant="h3"
    />
    <div
      v-html="termsAndPolicy?.text"
    />
    <div class="c-acceptTermsAndPolicy__actions">
      <BaseButton
        class="h-mr-20"
        variant="solid"
        color="primary"
        :btn-label="$t('common.ctaLabels.accept')"
        @click="handleAccept(true)"
      />
      <BaseButton
        variant="outlined"
        color="muted"
        :btn-label="$t('common.ctaLabels.decline')"
        @click="handleAccept(false)"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue';

const props = defineProps({
  data: { type: Array, default: () => [] },
  isRecipient: { type: Boolean },
});

const emit = defineEmits([ 'accept' ]);
const option = inject('option');

const showRegisterForm = !props.isRecipient && inject('showRegisterForm');

const handleAccept = accept => {
  emit('accept', { value: option.value, accept });
};

const termsAndPolicy = ref({});

onMounted(() => {
  termsAndPolicy.value = props.data.find(d => option.value === d.slug) || {};
});
</script>

<style lang="scss" scoped>
  @use "src/assets/styles/abstracts/mixins" as mx;
  @use "src/assets/styles/abstracts/variables" as v;

  .c-acceptTermsAndPolicy {
    width: 100%;
    height: inherit;

    &__backButton {
      text-transform: uppercase;
    }

    &__actions {
      @include mx.d-flex(flex-start, false, false);

      padding: 20px 0;
    }
  }
</style>
