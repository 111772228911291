<template>
  <ConditionalWrapper
    :wrapper="useFilter ? 'div' : '' "
    :rounded="rounded"
  >
    <picture
      v-lazy-load-img="{ignoreObserver}"
      :style="$attrs.style"
      :class="['c-lazyImage', LOADING,
               rounded && ROUNDED, $attrs.class]"
    >
      <source
        media="(min-width: 768px)"
        srcset=""
      >
      <img
        class="c-lazyImage__img js-lazyImage"
        :data-src-set="desktopImg.url || mobileImg.url || noImageUrl"
        :data-src="mobileImg.url || noImageUrl"
        :style="{ maxHeight: maxHeight, height: maxHeight, minWidth: minWidth }"
        :alt="mobileImg.altText"
      >
    </picture>
  </ConditionalWrapper>
</template>

<script setup>
import { inject } from 'vue';

import ConditionalWrapper from '@/components/grid/ConditionalWrapper.vue';

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  minWidth: {
    type: String,
    default: null,
  },
  maxHeight: {
    type: String,
    default: null,
  },
  rounded: {
    type: Boolean,
  },
  ignoreObserver: {
    type: Boolean,
  },
  useFilter: {
    type: Boolean,
  },
});

const { ROUNDED, LOADING } = inject('classes');
const { MOBILE, DESKTOP } = inject('img_types');
const mobileImg = props.images.find(img => img.format === MOBILE);
const desktopImg = props.images.find(img => img.format === DESKTOP);
const noImageUrl = `https://via.placeholder.com/200x${props.maxHeight}.png?text=No+Image`;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.c-lazyImage {
  $self: &;

  position: relative;

  display: flex;
  overflow: hidden;

  width: 100%;
  height: auto;

  background: rgba(0, 0, 0, 0.22);

  &__img {
    width: 100%;
    height: 100%;

    transition: filter 300ms ease, opacity 300ms ease-in;

    opacity: 0;
    filter: blur(10px);
    object-fit: cover;
  }

  &.-loading:after {
    @include mx.use-zeta-index('lowest');

    position: absolute;

    height: 100%;

    content: "";
    transform: translateX(-100%);
    animation: loading 1.5s infinite;

    background:
      linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0.4),
        hsla(0, 0%, 100%, 0)
      );
    inset: 0 0 0 0;
  }

  &.-loaded {
    #{ $self }__img {
      opacity: 1;
      filter: blur(0);
    }
  }

  &.-rounded {
    border-radius: v.$base-border-radius;
  }
}

/* TODO: we still need to handle this effect on images without border-radius */
.c-conditionalWrapper {
  position: relative;

  overflow: hidden;

  border-radius: v.$base-border-radius;

  &:after {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';

    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
  }
}
</style>
