<template>
  <div :class="['l-baseContainer', `bg-${bgColor}`]">
    <slot />
  </div>
</template>

<script>
import { CONTAINER } from '@/constants';

export default {
  name: 'BaseContainer',
  props: {
    bgColor: {
      type: String,
      default: '01',
      validator: value => CONTAINER.BG_COLOR.includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-baseContainer {
  box-sizing: border-box;
  @each $key, $color in map-get(v.$palettes, backgrounds) {
    &.#{ $key } {
      @include mx.use-bg-color(#{ $key });
    }
  }
}
</style>
