import { apiServices } from '@/api/services';
import { handleErrors } from '@/mixins/utils';
import { REUSABLE as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    settings: [],
  },
  mutations: {
    [M.USER_SETTINGS](state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    async fetchSettings({ commit }) {
      try {
        const data = await apiServices.getUserSettings();

        commit(M.USER_SETTINGS, data);
      } catch (error) {
        handleErrors(error);
      }
    },
  },
  getters: {
    getLoginMethods(state) {
      const availableMethods = [ 'facebook_login' ];

      const data = state.settings.filter(k => availableMethods.includes(k.name) && k.value === '1').map(i => i.name);

      return [ ...data, 'email_login' ];
    },
    getSetting(state) {
      return name => {
        const setting = state.settings.find(d => d.name === name);

        if (Number(setting?.value) <= 1) {
          return Number(setting.value) === 1;
        }

        return setting?.value;
      };
    },
  },
};
