<template>
  <div class="c-takeAway">
    <Typography
      class="-fontBold -title"
      v-text="$t('widgets.orderModal.takeAway.title')"
      variant="body1"
    />
    <AddressBox
      @selected="getSelectedAddress"
      label-color="secondary"
      has-border
      icon-left
      id="takeaway-address"
      placeholder="Search address"
      v-model="address"
    />
    <FieldLoader
      v-if="loadingList"
      class="h-mv-20"
    />
    <RestaurantList
      v-else
      :list="takeawayList"
      @change="getSelectedRestaurant"
      :selected-restaurant="selectedRestaurant"
      name="takeaway-list"
    />
    <div v-if="!takeawayList.length && address.length > 0">
      No Restaurants found!
    </div>
    <TimeAndDatePicker />
  </div>
</template>

<script setup>
import { inject } from 'vue';

import RestaurantList from '@/components/Order/RestaurantList.vue';
import TimeAndDatePicker from '@/components/Order/TimeAndDatePicker.vue';
import AddressBox from '@/components/common/AddressBox.vue';
import FieldLoader from '@/components/common/Loader/FieldLoader.vue';

const address = inject('address');
const takeawayList = inject('takeawayList');
const selectedRestaurant = inject('selectedRestaurant');
const getSelectedAddress = inject('getSelectedAddress');
const getSelectedRestaurant = inject('getSelectedRestaurant');
const loadingList = inject('loadingList');

</script>
