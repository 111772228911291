const MUTATIONS = {
  SET_I18N: 'SET_I18N',
  SET_HASH: 'SET_HASH',
  SET_HOUSE_SPECIALITY: 'SET_HOUSE_SPECIALITY',
  SET_IS_PERFECT_MATCH: 'SET_IS_PERFECT_MATCH',
  AUTH: {
    AUTHORISED_ACCESS: 'AUTHORISED_ACCESS',
    AUTH_USER: 'AUTH_USER',
    SET_SELECTED_ADDRESS: 'SET_SELECTED_ADDRESS',
    SET_SELECTED_ADDRESS_FOR_DELIVERY: 'SET_SELECTED_ADDRESS_FOR_DELIVERY',
    SET_SELECTED_ADDRESS_FOR_BILLING: 'SET_SELECTED_ADDRESS_FOR_BILLING',
    SET_SELECTED_ADDRESS_FOR_EDIT: 'SET_SELECTED_ADDRESS_FOR_EDIT',
    CREATE_CUSTOMER_ADDRESS: 'CREATE_CUSTOMER_ADDRESS',
    UPDATE_CUSTOMER_ADDRESS: 'UPDATE_CUSTOMER_ADDRESS',
    DELETE_CUSTOMER_ADDRESS: 'DELETE_CUSTOMER_ADDRESS',
    UPDATE_TEMP_CUSTOMER_ADDRESS: 'UPDATE_TEMP_CUSTOMER_ADDRESS',
  },
  FORMS: {
    SET_ACTIVE_FORM: 'SET_ACTIVE_FORM',
  },
  LOADERS: {
    SET_LOADER: 'SET_LOADER', // for full screen
    SET_BUTTON_LOADER: 'SET_BUTTON_LOADER',
  },
  OVERLAYS: {
    SET_MODAL: 'SET_MODAL',
    SET_SIDE_MENU_VISIBILITY: 'SET_SIDE_MENU_VISIBILITY',
  },
  RESTAURANT: {
    SET_SELECTED_RESTAURANT: 'SET_SELECTED_RESTAURANT',
    SET_RESTAURANT_LIST: 'SET_RESTAURANT_LIST',
    SET_RESTAURANT_TIMES: 'SET_RESTAURANT_TIMES',
  },
  REUSABLE: {
    HOME_DATA: 'HOME_DATA',
    USER_SETTINGS: 'USER_SETTINGS',
  },
  PRODUCTS: {
    SET_HOUSE_SPECIALITIES_PRODUCTS: 'SET_HOUSE_SPECIALITIES_PRODUCTS',
    SET_SINGLE_PRODUCT: 'SET_SINGLE_PRODUCT',
    SET_RESTAURANT_CATEGORIES: 'SET_RESTAURANT_CATEGORIES',
    SET_CATEGORY_DATA: 'SET_CATEGORY_DATA',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  },
  ORDER: {
    SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
    SET_ORDER_DETAILS: 'SET_ORDER_DETAILS',
    SET_ORDER_DATA: 'SET_ORDER_DATA',
    SET_SHIPPING_IS_VALID_FOR_DELIVERY: 'SET_SHIPPING_IS_VALID_FOR_DELIVERY',
    SET_NEEDS_INVOICE: 'SET_NEEDS_INVOICE',
    SET_BILLING_IS_DIFFERENT: 'SET_BILLING_IS_DIFFERENT',
    SET_SAVE_ORDER_DETAILS: 'SET_SAVE_ORDER_DETAILS',
    SET_IF_ORDER_SENT: 'SET_IF_ORDER_SENT',
    SET_RESTAURANT_LIST: 'RESTAURANT_LIST',
    SET_DELIVERY_EXTRA_COST: 'SET_DELIVERY_EXTRA_COST',
  },
  CART: {
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    SET_PRODUCT_TO_CART: 'SET_PRODUCT_TO_CART',
    SET_CART_SUB_TOTAL: 'SET_CART_SUB_TOTAL',
  },
  AUTO_COMPLETE_ADDRESS: {
    SET_ADDRESS_LIST: 'SET_ADDRESS_LIST',
  },
  PAYMENT: {
    SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  },
};

export const {
  AUTH, FORMS, AUTO_COMPLETE_ADDRESS, OVERLAYS, LOADERS, RESTAURANT, REUSABLE, ORDER,
  PRODUCTS, CART, PAYMENT,
} = MUTATIONS;
export default MUTATIONS;
