<template>
  <div
    :class="['c-card h-p-10', isHistoryPage || homePage && '-secBg']"
  >
    <div class="c-card__subSection">
      <Typography
        class="-fontMedium -muted"
        v-text="dateFormat(date)"
        variant="body2"
      />
      <Typography
        v-if="isMobile && !isHistoryPage"
        class="-fontMedium -muted"
        v-text="price"
        variant="body2"
      />
    </div>
    <Typography
      class="-fontMedium c-card__title"
      v-text="_truncate(title, { length })"
      variant="body2"
    />
    <div :class="['c-card__subSection', isHistoryPage && '-blockBtn']">
      <Typography
        v-if="!isMobile || isHistoryPage"
        class="-fontMedium -muted"
        v-text="price"
        variant="body2"
      />
      <span class="c-card__subSectionBtn">
        <BaseButton
          :btn-label="$t('common.ctaLabels.details')"
          color="muted"
          @click="handleDetails"
        />
        <BaseButton
          :btn-label="$t(isHistoryPage ?
            'widgets.orderHistory.contentBody.cardButtonText.right'
            : 'widgets.dashboard.banner.cardButtonText.right')"
          color="primary"
          @click="handleReorder"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import _truncate from 'lodash.truncate';
import { inject } from 'vue';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import { dateFormat } from '@/mixins/utils';

const isMobile = inject('isMobile');
const store = useStore();

const props = defineProps({
  isHistoryPage: { type: Boolean },
  homePage: { type: Boolean },
  date: { type: Date, default: new Date() },
  price: { type: String, default: '' },
  products: { type: Array, default: () => [] },
  id: { type: Number, required: true },
});

const emit = defineEmits([ 'showDetails' ]);

const handleDetails = () => {
  emit('showDetails');
};

const title = props.products.reduce((a, b, index) => `${a}${index ? ', ' : ''}${`${b.quantity} x ${b.name}`}`, '');
const length = props.isHistoryPage ? 120 : 60;

const handleReorder = () => {
  store.dispatch('order/reorder', props.id);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-card {
  border-radius: v.$base-border-radius;

  &.-secBg {
    @include mx.use-bg-color("bg-02");
  }

  &__title {
    width: 100%;

    @include mx.bp('tablet-wide') {
      width: 80%;
    }
  }

  &__subSection {
    padding-top: 5px;

    &Btn {
      width: 100%;
      gap: 10px;

      &::v-deep(.c-baseButton) {
        padding: 0 5px;
      }

      @include mx.d-flex("space-between", center);

      @include mx.bp('tablet-wide') {
        width: inherit;
      }
    }

    @include mx.d-flex("space-between", center);

    @include mx.bp('tablet-wide', $direction: max) {
      &.-blockBtn {
        display: block;
      }
    }
  }

  @include mx.use-bg-color('lightColor');
}
</style>
