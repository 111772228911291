import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import MUTATIONS from '@/store/types';

// eslint-disable-next-line import/no-cycle
import auth from './modules/auth';
import autoCompleteAddress from './modules/autoCompleteAddress';
import cart from './modules/cart';
import forms from './modules/forms';
import loaders from './modules/loaders';
// eslint-disable-next-line import/no-cycle
import order from './modules/order';
import overlays from './modules/overlays';
import payment from './modules/payment';
// eslint-disable-next-line import/no-cycle
import products from './modules/products';
import restaurant from './modules/restaurant';
import reusable from './modules/reusable';

const store = createStore({
  plugins: [ createPersistedState() ],
  state: {
    i18n: undefined,
    hash: '',
    houseSpeciality: {
      id: null,
      slug: null,
    },
    isPerfectMatch: false,
  },
  mutations: {
    [MUTATIONS.SET_I18N](state, value) {
      state.i18n = value;
    },
    [MUTATIONS.SET_HASH](state, value) {
      state.hash = value;
    },
    [MUTATIONS.SET_HOUSE_SPECIALITY](state, value) {
      state.houseSpeciality = value;
    },
    [MUTATIONS.SET_IS_PERFECT_MATCH](state, value) {
      state.isPerfectMatch = value;
    },
  },
  actions: {
    setI18n({ commit }, payload) {
      commit(MUTATIONS.SET_I18N, payload);
    },
    setHash({ commit }, payload) {
      commit(MUTATIONS.SET_HASH, payload);
    },
    setHouseSpeciality({ commit }, payload) {
      commit(MUTATIONS.SET_HOUSE_SPECIALITY, payload);
    },
    setIsPerfectMatch({ commit }, payload) {
      commit(MUTATIONS.SET_IS_PERFECT_MATCH, payload);
    },
  },
  getters: {
  },
  modules: {
    auth,
    forms,
    loaders,
    overlays,
    restaurant,
    reusable,
    products,
    cart,
    autoCompleteAddress,
    order,
    payment,
  },
});

export default store;
