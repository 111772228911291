import { FORMS as M } from '@/store/types';

export default {
  namespaced: true,
  state: {
    activeForm: 'LoginPicker',
  },
  mutations: {
    [M.SET_ACTIVE_FORM](state, value) {
      state.activeForm = value;
    },
  },
  actions: {
    setActiveForm({ commit }, payload) {
      commit(M.SET_ACTIVE_FORM, payload);
    },
  },
};
