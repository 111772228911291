<template>
  <header
    :class="['c-header', {'-light' : showLightHeader},
             {'-hasAppBanner': hasAppBanner &&
               ((OS === 'iOS' &&
                 $t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton')) ||
                 (OS === 'android' &&
                   $t('widgets.pickChoice.banner.overlay.colRight.playStoreButton')) ||
                 (!OS && ($t('widgets.pickChoice.banner.overlay.colRight.appleStoreButton') ||
                   $t('widgets.pickChoice.banner.overlay.colRight.playStoreButton'))))}]"
  >
    <SectionContainer
      bg-color="inherit"
      size="lg"
    >
      <div class="c-header__items">
        <Icon
          v-if="isAuthenticated"
          :data="require('@icons/hamburger.svg')"
          class="c-header__hamburger"
          :color="showLightHeader ? 'black' : 'white'"
          width="25"
          @click="store.dispatch('overlays/setSideMenuVisibility', true)"
        />
        <router-link
          :to="$i18nRoute({ name: HOME })"
          class="c-header__anchor"
        >
          <img
            class="c-header__logo"
            :src="`${$t(`header.${showLightHeader ? 'logoLightUrl' : 'logoDarkUrl'}`)}`"
            alt="logo"
          >
        </router-link>
        <div
          class="c-header__buttons"
          v-if="!isMobile"
        >
          <div
            class="c-header__address"
            v-if="showAddress"
          >
            <BaseButton
              v-if="selectedAddress"
              class="h-mr-20"
              icon="location"
              :btn-label="selectedAddress"
              :color="buttonsColors"
              @click="store.dispatch('overlays/setModal', 'Order')"
            />
          </div>
          <div
            class="c-header__rightContent"
            v-if="formattedSubtotalDiscounted || subTotal"
          >
            <BaseButton
              icon="cart"
              class="h-mr-10"
              variant="solid"
              color="primary"
              size="small"
              :btn-label="formattedSubtotalDiscounted || subTotal"
              :to="$i18nRoute({ name: CHECKOUT })"
            />
          </div>
        </div>

        <BaseButton
          :btn-label="$t('header.loginButtonText')"
          size="small"
          variant="solid"
          color="primary"
          class="-loginBtn"
          v-if="!isAuthenticated"
          @click="setModalContent"
        />
        <LocaleSwitcher
          v-if="Trans.availableLanguage.length > 1"
          :is-dark="!showLightHeader"
        />
      </div>
      <BaseModal
        :show-modal="showModal"
        :size="modalSize"
      >
        <template #body>
          <ActiveAuthForm />
        </template>
      </BaseModal>
    </SectionContainer>
  </header>
</template>

<script setup>
import _throttle from 'lodash.throttle';
import {
  computed, inject,
  onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import ActiveAuthForm from '@/components/user/ActiveAuthForm.vue';
// eslint-disable-next-line import/no-cycle
import Trans from '@/mixins/translation';

const isMobile = inject('isMobile');
const { HOME, CHECKOUT } = inject('routeNames');
const OS = inject('OS');

const props = defineProps({
  lightOnMount: {
    type: Boolean,
  },
  hasAppBanner: {
    type: Boolean,
  },
});

const store = useStore();
const showLightHeader = ref(props.lightOnMount);
const showModal = computed(() => store.state.overlays.openModal === 'Login');
const modalSize = computed(() => (store.state.forms.activeForm === 'LoginPicker' ? 'large' : 'small'));
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const selectedAddress = computed(() => store.state.auth.selectedAddress);
const showAddress = computed(() => store.getters['auth/showAddress']);
const subTotal = computed(() => store.getters['cart/getFormattedSubTotal']);
const formattedSubtotalDiscounted = computed(() => store.getters['cart/getFormattedSubTotalDiscounted']);
const buttonsColors = computed(() => (showLightHeader.value ? 'secondary' : 'lightColor'));
const setModalContent = () => {
  store.dispatch('overlays/setModal', 'Login');
  store.dispatch('forms/setActiveForm', 'LoginPicker');
};

const onScroll = _throttle(e => {
  showLightHeader.value = props.lightOnMount || e.target.documentElement.scrollTop > 0;
}, 500);

onMounted(() => window.addEventListener('scroll', onScroll));
onUnmounted(() => window.removeEventListener('scroll', onScroll));

watch(
  () => props.lightOnMount,
  val => {
    showLightHeader.value = val;
  },
);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-header {
  @include mx.use-zeta-index("header");

  position: fixed;
  top: 0;

  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;

  transition: 0.5s background-color ease-in;

  &__items {
    @include mx.d-flex('space-between', $align-items: "center");

    height: 80px;
  }

  &__hamburger {
    margin-right: 20px;

    cursor: pointer;
  }

  &__anchor {
    display: grid;

    margin-right: auto;
  }

  &__logo {
    max-width: 150px;
    max-height: 65px;

    @include mx.bp("desktop") {
      width: auto;
    }
  }

  &__buttons {
    @include mx.d-flex("space-between", "center");

    width: 100%;
  }

  &__rightContent {
    @include mx.d-flex(flex-end, false, false);

    width: 100%;
  }

  &__address {
    display: flex;

    margin-left: 75px;
  }

  .-loginBtn {
    margin-left: auto;

    white-space: nowrap;
  }

  &.-light {
    @include mx.use-bg-color("lightColor");

    box-shadow: v.$base-box-shadow;
  }

  &.-hasAppBanner {
    padding-top: 60px;
  }
}
</style>
