<template>
  <component
    v-if="props.wrapper"
    :is="props.wrapper"
    class="c-conditionalWrapper"
  >
    <slot />
  </component>
  <slot v-else />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
  wrapper: {
    type: String,
    default: '',
  },
  rounded: {
    type: Boolean,
  },
});

</script>
