<template>
  <div class="c-formContainer">
    <div class="c-formContainer__container">
      <div class="c-formContainer__header">
        <img
          class="c-formContainer__logo"
          :src="src"
          alt="logo"
        >
        <Typography
          class="-fontBold"
          v-text="formTitle"
          variant="subtitle3"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FormContainer',
  props: {
    formTitle: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('forms', [ 'setActiveForm' ]),
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-formContainer {
  @include mx.d-flex(center, center, true);

  width: 100%;
  height: 100%;

  @include mx.bp('desktop') {
    align-items: unset;
  }

  &__container {
    width: 100%;
    height: 100%;

    @include mx.bp('desktop') {
      width: auto;
    }
  }

  &__header {
    @include mx.d-flex(center, center, true);

    width: 100%;
  }

  &__logo {
    @include mx.constrain-image(220px, 80px);

    margin-bottom: 0.5rem;
  }

  &__link {
    padding-bottom: 3rem;
  }

  &::v-deep(.c-baseButton) {
    width: 100%;
    margin: 1rem 1rem 1rem 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
