/* eslint-disable import/no-cycle */
import ApiRoutes from '@/api/routes';
import { apiServices } from '@/api/services';
import { UserAddressModel, CartModel, OrderModel } from '@/interface/model';
import axios from '@/mixins/axios';
import Trans from '@/mixins/translation';
import { handleErrors, authFactory } from '@/mixins/utils';
import router from '@/router';
import { NAMES } from '@/router/constants';
import { AUTH as MUTATION, CART, ORDER } from '@/store/types';
import { toast } from '@/toast-notification';

const setSession = accessToken => {
  const method = accessToken ? 'setItem' : 'removeItem';

  axios.defaults.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
  localStorage[method]('accessToken', accessToken);
};

const initialUser = {
  addresses: [],
  allergens: [],
  credit: '',
  credit2: '',
  cart: CartModel,
  email: '',
  fidelity_points: 0,
  mobile: '',
  surname: '',
  name: 'guest',
};

const accessToken = localStorage.getItem('accessToken');

export default {
  namespaced: true,
  state: {
    isAuthenticated: authFactory(accessToken),
    selectedAddress: '',
    user: initialUser,
    selectedAddressForEdit: UserAddressModel,
  },
  mutations: {
    [MUTATION.SET_SELECTED_ADDRESS_FOR_EDIT](state, payload) {
      state.selectedAddressForEdit = payload;
    },
    [MUTATION.SET_SELECTED_ADDRESS](state, payload) {
      state.selectedAddress = payload;
    },
    [MUTATION.AUTHORISED_ACCESS](state, value) {
      state.isAuthenticated = value;
    },
    [MUTATION.AUTH_USER](state, value) {
      state.user = value;
      state.user.addresses = state.user.addresses.map(addr => ({
        ...addr,
        selectedForDelivery: !!addr.default_delivery,
        selectedForBilling: !!addr.default_billing,
      }));
    },
    [MUTATION.CREATE_CUSTOMER_ADDRESS](state, payload) {
      const { default_billing, default_delivery, id } = payload;

      state.user.addresses = [
        ...state.user.addresses,
        {
          ...payload,
          id: payload.id || Math.floor(performance.now()),
          selectedForDelivery: !!payload.default_delivery,
          selectedForBilling: !!payload.default_billing,
        },
      ];

      if (default_billing || default_delivery) {
        state.user.addresses = state.user.addresses.map(addr => ({
          ...addr,
          ...(default_billing && {
            default_billing: addr.id === id ? 1 : 0,
            selectedForBilling: addr.id === id,
          }),
          ...(default_delivery && {
            default_delivery: addr.id === id ? 1 : 0,
            selectedForDelivery: addr.id === id,
          }),
        }));
      }
    },
    [MUTATION.SET_SELECTED_ADDRESS_FOR_DELIVERY](state, value) {
      state.user.addresses = state.user.addresses.map(addr => ({
        ...addr,
        selectedForDelivery: value === addr.id,
      }));
    },
    [MUTATION.SET_SELECTED_ADDRESS_FOR_BILLING](state, value) {
      console.log(value);
      state.user.addresses = state.user.addresses.map(addr => ({
        ...addr,
        selectedForBilling: value === addr.id,
      }));
    },
    [MUTATION.UPDATE_CUSTOMER_ADDRESS](state, value) {
      const { default_billing, default_delivery, id } = value;

      if (default_billing || default_delivery) {
        state.user.addresses = state.user.addresses.map(addr => ({
          ...addr,
          ...(default_billing && {
            default_billing: 0,
            selectedForBilling: addr.id === id,
          }),
          ...(default_delivery && {
            default_delivery: 0,
            selectedForDelivery: addr.id === id,
          }),
        }));
      }
      const index = state.user.addresses.findIndex(addr => addr.id === id);

      const NEW_ADDRESS = {
        ...value,
        selectedForDelivery: state.user.addresses[index].selectedForDelivery,
        selectedForBilling: state.user.addresses[index].selectedForBilling,
      };

      state.user.addresses.splice(index, 1, NEW_ADDRESS);
    },
    [MUTATION.DELETE_CUSTOMER_ADDRESS](state, value) {
      state.user.addresses = state.user.addresses.filter(
        addr => addr.id !== value,
      );
    },
  },
  actions: {
    setSelectedAddress({ commit }, payload) {
      commit(MUTATION.SET_SELECTED_ADDRESS, payload);
    },
    setSelectedAddressForEdit({ commit }, payload) {
      commit(MUTATION.SET_SELECTED_ADDRESS_FOR_EDIT, payload);
    },
    async updateTempCustomerAddresses(
      { commit, dispatch, rootState },
      { data },
    ) {
      try {
        await apiServices.getAddressGeoCoding(`${data.address}`).then(res => {
          // eslint-disable-next-line no-param-reassign
          data = {
            ...data,
            coord: `${res.latitude}, ${res.longitude}`,
          };
        });
        commit(MUTATION.UPDATE_CUSTOMER_ADDRESS, data);
        toast.success(rootState.i18n.common.feedback.OK.updateAddress);
        dispatch('overlays/setModal', false, { root: true });
      } catch (error) {
        if (error.message) toast.error(error.message);
      }
    },
    async login({ commit, dispatch }, params) {
      dispatch('loaders/setBtnLoader', true, { root: true });
      try {
        const {
          data: { data },
        } = await axios.post(ApiRoutes.LOGIN, params);

        const { token, message } = data;

        if (token !== '') {
          setSession(token);

          commit(MUTATION.AUTHORISED_ACCESS, true);
          dispatch('getUserInfo');
          toast.success(message);
          this.dispatch('order/fetchOrderHistory', { limit: 3 });
          dispatch('overlays/setModal', false, { root: true });
          sessionStorage.removeItem('temp_cart_id');
          await router.push(Trans.i18nRoute({ name: NAMES.HOME }));
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        // NOTE: we can use this.dispatch or { root: true } to access the global store
        this.dispatch('loaders/setBtnLoader', false);
      }
    },
    async signUp({ rootState, dispatch }, data) {
      this.dispatch('loaders/setBtnLoader', true);
      try {
        const response = await axios.post(ApiRoutes.SIGNUP, data);

        if (response.data) {
          toast.success(rootState.i18n.widgets.register.userRegisterSuccessMsg);
          dispatch('overlays/setModal', false, { root: true });
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setBtnLoader', false);
      }
    },
    async getUserInfo({ commit }) {
      this.dispatch('loaders/setLoader', true);
      try {
        const { data: { data } = {} } = await axios.get(ApiRoutes.GET_USER);

        const updatedData = {
          ...data,
          cart: data.cart ?? CartModel,
        };

        commit(MUTATION.AUTH_USER, updatedData);
        this.commit(`cart/${CART.SET_CART_ITEMS}`, updatedData.cart);
        if (data.cart?.delivery_type) {
          this.commit('order/SET_ORDER_DATA', {
            delivery_type: data.cart.delivery_type,
          });
        }
      } catch (error) {
        const {
          errors: [ { status, title } ],
        } = error;

        if (status === 401 && title === 'Expired token') {
          this.dispatch('auth/logout');
        }
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async updateProfile({ commit, dispatch }, data) {
      try {
        dispatch('loaders/setLoader', true, { root: true });
        const response = await axios.post(ApiRoutes.UPDATE_USER, data);

        commit(MUTATION.AUTH_USER, response.data.data);
        toast.success(response.data.data.message);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },

    logout({ commit }) {
      axios
        .get(ApiRoutes.LOGOUT)
        .then(() => {
          setSession();
          this.dispatch('overlays/setSideMenuVisibility', false);
          commit(MUTATION.AUTHORISED_ACCESS, false);
          commit(MUTATION.AUTH_USER, initialUser);

          this.commit(`cart/${CART.SET_CART_ITEMS}`, CartModel);
          this.commit(`order/${ORDER.SET_ORDER_HISTORY}`, []);
          this.commit(`order/${ORDER.SET_ORDER_DATA}`, OrderModel);
          router.push(Trans.i18nRoute({ name: NAMES.HOME }));
        })
        .catch(error => {
          handleErrors(error);
        });
    },
    async setNewPassword({ commit, dispatch }, payload) {
      try {
        this.dispatch('loaders/setLoader', true);
        const {
          data: { data },
        } = await axios.post(ApiRoutes.RECOVER_PASSWORD_CHANGE, payload);

        const { token } = data;

        setSession(token);

        commit(MUTATION.AUTHORISED_ACCESS, true);
        dispatch('getUserInfo');
        sessionStorage.removeItem('temp_cart_id');
        await router.push(Trans.i18nRoute({ name: NAMES.HOME }));
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async recoverPassword(_, email) {
      try {
        this.dispatch('loaders/setLoader', true);
        const {
          data: {
            data: { message },
          },
        } = await axios.get(ApiRoutes.RECOVER_PASSWORD, { params: { email } });

        toast.success(message || '');
        this.dispatch('forms/setActiveForm', 'LoginForm');
        this.dispatch('overlays/setModal', false);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    // Address
    async addCustomerAddress({
      state, commit, dispatch, rootState,
    }, address) {
      try {
        dispatch('loaders/setLoader', true, { root: true });
        let { data } = state.isAuthenticated
          && (await axios.post(ApiRoutes.CUSTOMER_ADDRESS, address));

        if (!data) data = { data: address };

        if (!state.isAuthenticated) {
          const geoAddress = `${data.data.address}, ${data.data.city} ${data.data.province} ${data.data.zipcode}`;

          await apiServices.getAddressGeoCoding(geoAddress).then(res => {
            data.data = {
              ...data.data,
              coord: `${res.latitude}, ${res.longitude}`,
            };
          });
        }

        commit(MUTATION.CREATE_CUSTOMER_ADDRESS, data.data);
        toast.success(rootState.i18n.common.feedback.OK.addAddress);
        dispatch('overlays/setModal', false, { root: true });
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async updateCustomerAddress({ commit, dispatch, rootState }, { data, id }) {
      console.log(data, '...', id);
      try {
        dispatch('loaders/setLoader', true, { root: true });

        const response = await axios.put(
          `${ApiRoutes.CUSTOMER_ADDRESS}/${id}`,
          data,
        );

        commit(MUTATION.UPDATE_CUSTOMER_ADDRESS, response.data.data);
        toast.success(rootState.i18n.common.feedback.OK.updateAddress);
        dispatch('overlays/setModal', false, { root: true });
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async deleteCustomerAddress({ commit, dispatch, rootState }, id) {
      try {
        dispatch('loaders/setLoader', true, { root: true });
        await axios.delete(`${ApiRoutes.CUSTOMER_ADDRESS}/${id}`);

        commit(MUTATION.DELETE_CUSTOMER_ADDRESS, id);
        toast.success(rootState.i18n.common.feedback.OK.deleteAddress);
      } catch (error) {
        handleErrors(error);
      } finally {
        toast.dismiss('deletePrompt');
        this.dispatch('loaders/setLoader', false);
      }
    },
    async deleteCustomerAccount({ dispatch, rootState }) {
      try {
        this.dispatch('loaders/setLoader', true);
        await axios.delete(ApiRoutes.GET_USER);

        dispatch('logout');
        toast.success(rootState.i18n.common.feedback.OK.deleteAccount);
      } catch (error) {
        handleErrors(error);
      } finally {
        toast.dismiss('deletePrompt');
        this.dispatch('loaders/setLoader', false);
      }
    },
    async updateCustomerNotifications(_, notificationIds) {
      try {
        this.dispatch('loaders/setLoader', true);
        const response = await axios.post(ApiRoutes.CUSTOMER_NOTIFICATIONS, {
          notificationIds,
        });

        const { message } = response.data;

        toast.success(message);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
  getters: {
    showAddress(state, _, rootState) {
      return state.selectedAddress || rootState.order.orderData.delivery_time;
    },
    getDefaultDeliveryAddress(state) {
      return state.user.addresses.find(addr => addr.default_delivery === 1);
    },
    getDefaultBillingAddress(state) {
      return state.user.addresses.find(addr => addr.default_billing === 1);
    },
  },
};
