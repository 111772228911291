import ApiRoutes from '@/api/routes';
import { CartModel } from '@/interface/model';
import axios from '@/mixins/axios';
import { handleErrors } from '@/mixins/utils';
import { CART as M } from '@/store/types';
import { toast } from '@/toast-notification';

export default {
  namespaced: true,
  state: {
    cart_items: CartModel,
    addToCartError: false,
  },
  mutations: {
    [M.SET_CART_ITEMS](state, payload) {
      state.cart_items = payload;
    },
  },
  actions: {
    async addToTempCart({ commit, state }, params) {
      this.dispatch('loaders/setLoader', true);
      state.addToCartError = false;
      try {
        const {
          data: { data },
        } = await axios.post(ApiRoutes.ADD_TO_TEMP_CART, params);

        if (data) {
          commit(M.SET_CART_ITEMS, data);
          sessionStorage.setItem('temp_cart_id', data?.cart_id);
        }
      } catch (error) {
        state.addToCartError = true;
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async addToCart({ commit, state }, params) {
      this.dispatch('loaders/setLoader', true);
      state.addToCartError = false;
      try {
        const {
          data: { data },
        } = await axios.post(ApiRoutes.ADD_TO_CART, params);

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        state.addToCartError = true;
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async getCartData({ commit }, id) {
      this.dispatch('loaders/setLoader', true);
      try {
        const {
          data: { data },
        } = await axios.get(`${ApiRoutes.GET_CART_DATA}/${id}`);

        if (data) {
          this.commit('order/SET_ORDER_DATA', {
            delivery_type: data.delivery_type,
          });
          commit(M.SET_CART_ITEMS, data);
        }

        if (data && data.shop_id) {
          const response = await axios.get(
            `${ApiRoutes.GET_DEFAULT_RESTAURANT_LIST}?id=${data.shop_id}`,
          );

          this.commit('restaurant/SET_SELECTED_RESTAURANT', response.data.data);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async getTempCartData({ commit }, id) {
      this.dispatch('loaders/setLoader', true);
      try {
        const {
          data: { data },
        } = await axios.get(`${ApiRoutes.GET_TEMP_CART_DATA}/${id}`);

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async updateCartItems({ state, commit, rootState }, params) {
      try {
        const cartId = state.cart_items.cart_id || rootState.auth.user.cart.cart_id;
        const {
          data: { data },
        } = await axios.put(`${ApiRoutes.UPDATE_CART_DATA}/${cartId}`, params);

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        handleErrors(error);
      }
    },
    async updateTempCartItems({ state, commit }, params) {
      try {
        const id = state.cart_items.cart_id;

        if (!id) {
          console.error('no id');

          return;
        }
        const {
          data: { data },
        } = await axios.put(`${ApiRoutes.UPDATE_TEMP_CART_DATA}/${id}`, params);

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        handleErrors(error);
      }
    },
    async removeCartItems({ state, commit, rootState }, params) {
      try {
        const cartId = state.cart_items.cart_id || rootState.auth.user.cart.cart_id;
        const {
          data: { data },
        } = await axios.delete(`${ApiRoutes.REMOVE_CART_DATA}/${cartId}`, {
          data: params,
        });

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        handleErrors(error);
      }
    },
    async removeTempCartItems({ state, commit }, params) {
      try {
        const id = state.cart_items.cart_id;

        if (!id) {
          console.error('no id');

          return;
        }

        const {
          data: { data },
        } = await axios.delete(`${ApiRoutes.REMOVE_TEMP_CART_DATA}/${id}`, {
          data: params,
        });

        if (data) {
          commit(M.SET_CART_ITEMS, data);
        }
      } catch (error) {
        handleErrors(error);
      }
    },
    async addCoupon({ commit }, params) {
      this.dispatch('loaders/setLoader', true);

      try {
        const {
          data: { data = {} },
        } = await axios.post(
          this.state.auth.isAuthenticated
            ? ApiRoutes.ADD_COUPON
            : ApiRoutes.ADD_GUEST_COUPON,
          params,
        );

        commit(M.SET_CART_ITEMS, data.cart);
        toast.success(data.message);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async removeCoupon({ commit }, params) {
      this.dispatch('loaders/setLoader', true);
      try {
        const {
          data: { data = {} },
        } = await axios.post(
          this.state.auth.isAuthenticated
            ? ApiRoutes.REMOVE_COUPON
            : ApiRoutes.REMOVE_GUEST_COUPON,
          params,
        );

        commit(M.SET_CART_ITEMS, data.cart);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
    async applyCredit({ commit }, { id, params }) {
      this.dispatch('loaders/setLoader', true);

      try {
        const {
          data: { data = {} },
        } = await axios.post(`${ApiRoutes.APPLY_CREDIT}/${id}`, params);

        commit(M.SET_CART_ITEMS, data.cart);
        toast.success(data.message);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.dispatch('loaders/setLoader', false);
      }
    },
  },
  getters: {
    getFormattedSubTotal(state) {
      return state.cart_items.subTotal > 0
        ? `${Number(state.cart_items.subTotal || 0).toFixed(2)} €`
        : null;
    },
    getFormattedSubTotalNoDiscount(state) {
      return `${state.cart_items.subTotalNoDiscount} €`;
    },
    getFormattedSubTotalDiscounted(state) {
      const subtotalNoDiscount = Number(state.cart_items.subTotalNoDiscount || 0);
      const subtotal = Number(state.cart_items.subTotal || 0);

      return subtotal < subtotalNoDiscount ? `${subtotal} €` : null;
    },
    getCartProducts(state) {
      return state.cart_items.products;
    },
    getSuggestedProducts(state) {
      if (state.cart_items?.suggested?.length > 4) {
        // randomly returns 4 items
        const n = 4;

        return state.cart_items.suggested
          .map(x => ({ x, r: Math.random() }))
          .sort((a, b) => a.r - b.r)
          .map(a => a.x)
          .slice(0, n);
      }

      return state.cart_items.suggested;
    },
  },
};
