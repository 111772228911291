import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon';
import { createApp } from 'vue';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import '@/assets/styles/main.scss';
import 'vue3-carousel/dist/carousel.css';
// register global components
import Toast from 'vue-toastification';

import BaseButton from '@/components/common/BaseButton.vue';
import SectionContainer from '@/components/common/SectionContainer.vue';
import Typography from '@/components/common/Typography.vue';
// register directives
import LazyLoadImage from '@/directives/LazyLoadImage';
import clickOutside from '@/directives/clickOutside';
import validate from '@/directives/validate';
import Trans from '@/mixins/translation';

import App from './App.vue';
import {
  CAROUSEL, CLASSES, IMAGE_TYPES, TYPOGRAPHY,
} from './constants';
import i18n from './i18n';
import router from './router';
import { NAMES, PATHS } from './router/constants';
import store from './store';
import { options } from './toast-notification';

import '@yzfe/svgicon/lib/svgicon.css';

Trans.getSiteAvailableLanguages().then(() => {
  const app = createApp(App);

  app.config.globalProperties.$i18nRoute = Trans.i18nRoute.bind(Trans);

  app
    .use(VueSvgIconPlugin, { tagName: 'Icon' })
    .use(router)
    .use(store)
    .use(i18n)
    .use(Toast, options)
    .use(Donut)
    .provide('typography', TYPOGRAPHY)
    .provide('classes', CLASSES)
    .provide('img_types', IMAGE_TYPES)
    .provide('carousel', CAROUSEL)
    .provide('routeNames', NAMES)
    .provide('routePaths', PATHS)
    .component('BaseButton', BaseButton)
    .component('Typography', Typography)
    .component('SectionContainer', SectionContainer)
    .directive('lazy-load-img', LazyLoadImage)
    .directive('click-outside', clickOutside)
    .directive('validate', validate)
    .mount('#app');
});
