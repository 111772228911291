import ApiRoutes from '@/api/routes';
import axios, { client } from '@/mixins/axios';
import { handleErrors } from '@/mixins/utils';

class ApiServices {
  constructor() {
    this.axios = new Promise(resolve => {
      client.setSharedKey()
        .then(() => resolve(axios));
    });
  }

  async getUserSettings() {
    const http = await this.axios;

    return http.get(ApiRoutes.USER_SETTINGS)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getDefaultRestaurantsList() {
    const http = await this.axios;

    return http.get(ApiRoutes.GET_DEFAULT_RESTAURANT_LIST)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getAddressGeoCoding(address) {
    const http = await this.axios;

    return http.post(ApiRoutes.GET_ADDRESS_GEO_CODING, { address })
      .then(response => (response.data ?? {}))
      .catch(error => handleErrors(error));
  }

  async getAutoCompleteAddressList(address) {
    const http = await this.axios;

    return http.post(ApiRoutes.GET_AUTO_COMPLETE_ADDRESS, { address })
      .then(response => (response.data.addresses.length ? response.data.addresses : 'No records found'))
      .catch(error => handleErrors(error));
  }

  async getNearestRestaurants(params) {
    const http = await this.axios;

    return http.post(ApiRoutes.GET_RESTAURANT_LIST, params)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getTermsAndPolicy() {
    const http = await this.axios;

    return http.get(ApiRoutes.GET_TERMS_AND_POLICY)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getSiteData() {
    const http = await this.axios;

    return http.get(ApiRoutes.HOME)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getSiteColor() {
    const http = await this.axios;

    return http.get(ApiRoutes.COLORS)
      .then(response => (response.data.data ?? {}))
      .catch(error => handleErrors(error));
  }

  async getSiteAvailableLanguages() {
    const http = await this.axios;

    return http.get(ApiRoutes.LANGUAGES)
      .then(response => (response.data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getCountries() {
    const http = await this.axios;

    return http.get(ApiRoutes.COUNTRIES)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async checkEmail(email) {
    const http = await this.axios;

    return http.post(ApiRoutes.CHECK_EMAIL, { email })
      .then(({ data }) => !!data.exists)
      .catch(error => handleErrors(error));
  }

  async getCustomerDiscounts() {
    const http = await this.axios;

    return http.get(ApiRoutes.CUSTOMER_DISCOUNTS)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getCustomerAvailableDiscounts() {
    const http = await this.axios;

    return http.get(ApiRoutes.CUSTOMER_AVAILABLE_DISCOUNTS)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async checkShippingAddressForDelivery({
    shop_id, customer_address_id, city, coord,
  }) {
    const http = await this.axios;

    const params = {
      shop_id,
      ...{ customer_address_id } ?? false,
      ...{ city } ?? false,
      ...{ coord } ?? false,
    };

    return http.post(ApiRoutes.CHECK_ADDRESS, params)
      .then(({ data }) => (data.data))
      .catch(error => handleErrors(error));
  }

  async getCustomerInvitationsList() {
    const http = await this.axios;

    return http.get(ApiRoutes.CUSTOMER_INVITATIONS_LIST)
      .then(({ data }) => (data.data.list ?? []))
      .catch(error => handleErrors(error));
  }

  async inviteFriend(email) {
    const http = await this.axios;

    return http.get(`/customer/invite?email=${email}`)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getBrands() {
    const http = await this.axios;

    return http.get('/brand/')
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getBrandsByShopId(shopId) {
    const http = await this.axios;

    return http.get(`/shop/brands/${shopId}`)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }

  async getBrandCarousel(shopId, brandId) {
    const http = await this.axios;

    return http.post(`/brand/carousel/${shopId}/${brandId}`)
      .then(({ data }) => (data.data ?? []))
      .catch(error => handleErrors(error));
  }
}
export const apiServices = new ApiServices();

export default {};
