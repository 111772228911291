<template>
  <div class="c-orderDetails">
    <div class="h-pt-15 h-ph-20">
      <div class="c-orderDetails__gridBox">
        <div>
          <Typography
            v-text="$t('widgets.orderHistory.details.date')"
            variant="body2"
            class="-muted h-uppercase"
          />
          <Typography
            v-text="dateFormat(date, true, locale)"
            variant="body1"
            class="-fontBold"
          />
          <Typography
            v-text="`#${props.selectedOrder.id}`"
            variant="body2"
            class="-muted"
          />
        </div>
        <div>
          <Typography
            v-text="$t('widgets.orderHistory.details.user.address')"
            variant="body2"
            class="-muted h-uppercase"
          />
          <Typography
            v-text="props.selectedOrder.name"
            variant="body1"
            class="-fontBold"
          />
          <Typography
            v-if="isDeliveryOrder && address"
            v-text="`${address.address}, ${address.city}`"
            variant="body1"
            class="-muted"
          />
          <Typography
            v-text="props.selectedOrder.status"
            variant="body2"
            class="-primary h-uppercase"
          />
        </div>
      </div>
      <div class="divider h-mv-20" />
      <div class="c-orderDetails__gridBox">
        <div>
          <OrderSummary :order-details="selectedOrder" />
        </div>
        <div class="divider h-mv-20 h-showMd" />
        <div>
          <Typography
            v-text="$t('widgets.orderHistory.details.reorder.title')"
            variant="body1"
            class="-fontBold h-mb-10"
          />
          <div class="-flexBox">
            <div class="-flexBoxText">
              <Typography
                v-text="$t('widgets.orderHistory.details.reorder.singleOrderText')"
                variant="body2"
                class="-fontBold"
              />

              <Typography
                v-text="$t('widgets.orderHistory.details.reorder.proceedOrderText')"
                variant="body2"
                class="h-mv-10"
              />
            </div>
            <Typography
              v-text="convertTotal(selectedOrder.total, locale)"
              variant="body2"
              class="-fontBold h-hideMd"
            />
          </div>
          <BaseButton
            variant="solid"
            :is-full-width="isMobile"
            :btn-label="$t('widgets.orderHistory.details.reorder.proceedOrderBtn')"
            @click="handleReorder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import OrderSummary from '@/components/OrderSummary.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import { convertTotal, dateFormat } from '@/mixins/utils';

const store = useStore();
const { locale } = useI18n();
const isMobile = inject('isMobile');
const props = defineProps({
  selectedOrder: {
    type: Object,
    default: () => {
    },
  },
});

const date = computed(() => new Date(props.selectedOrder.utcDate));
const address = computed(() => props.selectedOrder.customerAddress);
const isDeliveryOrder = computed(() => store.getters['order/isDeliveryOrder']);
const handleReorder = async () => {
  await store.dispatch('order/reorder', props.selectedOrder.id);
  await store.dispatch('overlays/setModal', ''); // remove modal overlay.
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.c-orderDetails {
  width: 100%;

  &__gridBox {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @include mx.bp('tablet-wide') {
      grid-template-columns: repeat(2, 1fr);
      gap: 100px;
    }

    .-flexBox {
      @include mx.d-flex(space-between);

      &Text {
        @include mx.bp('tablet-wide') {
          width: 70%;
        }
      }
    }
  }

  .divider {
    @include mx.use-bg-color("disabled");

    height: 1px;
  }
}
</style>
