<template>
  <transition
    name="component-fade"
    mode="out-in"
  >
    <component
      :is="lookup[activeForm]"
    />
  </transition>
</template>

<script setup>
import { computed, markRaw } from 'vue';
import { useStore } from 'vuex';

import ForgotPasswordForm from '@/components/user/forms/ForgotPasswordForm.vue';
import LoginForm from '@/components/user/forms/LoginForm.vue';
import RegisterForm from '@/components/user/forms/RegisterForm.vue';
import LoginPicker from '@/components/user/login/LoginPicker.vue';

const store = useStore();
const activeForm = computed(() => store.state.forms.activeForm);

const lookup = markRaw({
  LoginPicker,
  LoginForm,
  ForgotPasswordForm,
  RegisterForm,
});

</script>

<style lang="scss" scoped>
.component-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.4s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
