<template>
  <span class="c-loader" />
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-loader {
  @include mx.font-size(10px);

  position: relative;

  width: 1em;
  height: 1em;
  margin: 0 0 0 1em;

  animation: spin 1.1s infinite linear;

  border-top: 0.3em solid var(--#{ v.$variable-prefix}grayWarm);
  border-right: 0.3em solid var(--#{ v.$variable-prefix}grayWarm);
  border-bottom: 0.3em solid var(--#{ v.$variable-prefix}grayWarm);
  border-left: 0.3em solid var(--#{ v.$variable-prefix}bodyColorDt);
  border-radius: 50%;

  font-size: 10px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
