<template>
  <div
    :class="['c-addressBox', props.iconLeft && '-position']"
  >
    <div
      class="c-addressBox__block"
      ref="block"
    >
      <label
        :class="[ 'c-addressBox__label', `-${labelColor}` ]"
        :for="$attrs.id"
      >{{ label }}</label>
      <form
        :class="['c-addressBox__inputContainer', hasBorder && '-hasBorder']"
        @submit.prevent="handleChange"
      >
        <Icon
          class="c-addressBox__icon"
          :data="require('@icons/location.svg')"
        />
        <input
          class="c-addressBox__input"
          type="text"
          :value="modelValue"
          @focus="onFocus"
          @focusout="onFocusOut"
          @input="updateInput"
          autocomplete="off"
          v-bind="$attrs"
        >
        <button
          class="c-addressBox__searchBtn"
          aria-label="Search"
          type="submit"
        >
          <span>
            <FieldLoader
              class="c-addressBox__loader"
              v-if="isLoading"
            />
            <Icon
              :data="require('@icons/search.svg')"
              v-else
            />
          </span>
        </button>
      </form>
      <div
        v-if="addressList?.length && openSuggestionList"
        :class="['c-addressBox__listSection -lg']"
      >
        <template v-if="Array.isArray(addressList)">
          <button
            v-for="address in addressList"
            :key="address.replaceAll(' ', '-')"
            type="button"
            class="c-addressBox__listItem"
            @mousedown="selectedValue(address)"
          >
            {{ address }}
          </button>
        </template>
        <span
          class="c-addressBox__listItem"
          v-else
          v-text="addressList"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
/* eslint-disable import/first */
import { ref } from 'vue';

import { apiServices } from '@/api/services';
import FieldLoader from '@/components/common/Loader/FieldLoader.vue';
import { BUTTONS } from '@/constants';
/* eslint-enable import/first */
const props = defineProps({
  modelValue: {
    type: [ String, Number ],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  labelColor: {
    type: String,
    default: 'bodyColorDt',
    validator: value => BUTTONS.COLORS.includes(value),
  },
  hasBorder: {
    type: Boolean,
  },
  iconLeft: {
    type: Boolean,
  },
});
const emit = defineEmits([ 'selected', 'update:modelValue' ]);
const addressList = ref([]);
const isLoading = ref(false);
const openSuggestionList = ref(false);
const top = ref('84px');
const block = ref(null);

const handleChange = () => {
  if (props.modelValue.length >= 3) {
    isLoading.value = true;
    apiServices.getAutoCompleteAddressList(props.modelValue)
      .then(res => {
        addressList.value = res;
        isLoading.value = false;
        openSuggestionList.value = true;
      }).catch(err => {
        console.log(err);
        isLoading.value = false;
      });
  }
};
const onFocus = () => {
  top.value = `${block.value.offsetHeight}px`;

  if (addressList.value?.length) {
    openSuggestionList.value = true;
  }
};

const onFocusOut = () => {
  openSuggestionList.value = false;
};

const updateInput = event => {
  emit('update:modelValue', event.target.value);
};
const selectedValue = val => {
  emit('selected', val);
  openSuggestionList.value = false;
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

$colors: 'primary', 'secondary', 'bodyColorDt';
$br: v.$base-border-radius;

.c-addressBox {
  &__block {
    position: relative;
    @include mx.d-flex(center, center, true);

    @include mx.bp('tablet-wide') {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__label {
    margin: 0.5em 0;

    letter-spacing: 0.26px;
    text-transform: uppercase;
    order: 0;

    @each $color in $colors {
      &.-#{$color} {
        @include mx.use-color($color);
      }
    }
  }

  &__inputContainer {
    @include mx.d-flex(flex-start, center);

    box-sizing: border-box;
    width: 100%;
    padding: 0;

    @include mx.bp('tablet-wide') {
      width: 395px;
    }

    @include mx.bp('desktop') {
      width: 486px;
    }

    &.-hasBorder {
      border: 2px solid var(--#{ v.$variable-prefix}grayWarm);
      border-radius: 3px;
    }
  }

  &__loader {
    width: 40px;
    margin: auto;
  }

  &__icon {
    width: 23px;
    height: 30.2px;
    padding: 10px;

    border-radius: $br 0 0 $br;
    background-color: transparentize(v.$light-color, 0.1);

    &.-iconLeft {
      position: absolute;
    }
  }

  &__input {
    overflow: hidden;

    width: 100%;
    padding: 14.5px 0;

    white-space: nowrap;
    text-overflow: ellipsis;

    border-radius: 0;
    background-color: transparentize(v.$light-color, 0.1);

    &::placeholder {
      text-align: center;

      @include mx.bp('tablet-wide') {
        text-align: left;
      }
    }
  }

  &__searchBtn {
    width: 70px;
    height: 50.3px;
    margin: 0;
    padding: 0;

    border: none;
    border-left: 1.2px solid var(--#{ v.$variable-prefix}bodyColorLtLight);
    border-radius: 0 $br $br 0;
    background-color: darken(v.$google-color, 8%);

    span {
      position: relative;
      z-index: 1;

      display: block;
    }

    span:after {
      position: absolute;
      z-index: -1;
      top: calc(50% - 15px);
      left: calc(50% - 15px);

      width: 30px;
      height: 30px;

      content: '';
      transform: scale(0);

      border-radius: 100%;
      background: transparentize(v.$muted-color, 0.5);
    }

    &:focus:not(:active) span:after {
      animation: circleGrow 0.4s linear;
    }
  }

  &__listSection {
    @include mx.use-bg-color("grayLight");
    @include mx.use-zeta-index('lowest');
    @include mx.use-custom-scroll();

    position: absolute;
    top: v-bind(top);

    display: block;

    width: 100%;
    height: fit-content;

    border-radius: 0 0 $br $br;
    box-shadow: v.$base-box-shadow;
  }

  &__listItem {
    @include mx.use-color('bodyColorLt');

    box-sizing: border-box;
    width: 100%;
    padding: 14px;

    text-align: left;
    letter-spacing: 0;

    border: none;
    border-bottom: 1px solid transparentize(v.$muted-color, 0.9);

    -webkit-appearance: button;

    &:hover {
      cursor: pointer;
    }
  }

  &.-position {
    position: relative;
  }
  @keyframes circleGrow {
    0% {
      transform: scale(0);

      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    100% {
      transform: scale(2);

      opacity: 0;
    }
  }
}
</style>
